/*
** @name: Meu Clínicas - confirmarValidacaoModal
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Fevereiro 2023
** @description: Componente para renderizar modal para solicitar data de nascimento como confirmação da validação da receita
*/
import React, { useState } from "react";

import utils from "../../core/utils";
import { ThemeImage } from "../../core/appThemeUtils";

import AppModal from '../../components/general/appModal/appModal.js';
import { InputField } from '../../components/fields/formsBuilderCustoms/';


const DATE_MASK = [/\d/, /\d/, '/', /\d/, /\d/, '/',/\d/, /\d/, /\d/, /\d/];

const ConfirmarValidacaoModal = (props) => {
    const { onCancel, onConfirm } = props;
    const [ dataNascimento, setDataNascimento ] = useState();
    const isDataOk = dataNascimento && utils.isValidDate(dataNascimento) ? true : false;

    const _handleChange = (e, { name, value }) => {
        setDataNascimento(value);
    }

    return(
        <div id="dataNascimentoModalId" className="data-nascimento-modal-wrapper">
            <AppModal
                closeOnEsc={true}
                closeOnClickOutside={false}
                onClose={() => onCancel()}
                headerContent={
                    <div className="modal-header-wrapper">
                        Confirmar validação
                        <div className="close-button" onClick={() => onCancel()}>
                            <ThemeImage module="appCard" imageId="normal-icon_close" />
                        </div>
                    </div>
                }
            >

                <form action="#" name="frmDataNascimento" className="ui form data-nascimento-form" onSubmit={e => e.preventDefault()}>
                    <div>Por favor, informe data de nascimento do paciente:</div>
                    <div className="data-nascimento-wrapper">
                        <div className="field-wrapper">
                            <InputField
                                id="dataNascimentoId"
                                name="dataNascimento"
                                label={null}
                                floatingPlaceHolder={false}
                                maxSize={10}
                                autoComplete="off"
                                value={dataNascimento || ""}
                                masked={{
                                    mask: DATE_MASK,
                                    placeholder: "dd/mm/aaaa",
                                    keepCharPositions: false
                                }}
                                onChange={_handleChange} />
                        </div>
                        <button
                                id="confirmButtonId"
                                className="app-form-button confirmation-ok"
                                disabled={!isDataOk}
                                onClick={() => onConfirm(dataNascimento)}>OK</button>
                    </div>
                </form>

            </AppModal>
        </div>
    )
}

export default ConfirmarValidacaoModal;
