/*
** @name: Meu Clínicas - newsEditModal
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2022
** @description: Módulo para gerar modal de edição de itens de notícias
** 
*/

import React, { useRef, useState, useEffect } from 'react';

import utils from '../../core/utils.js';
import { ThemeImage } from '../../core/appThemeUtils.js';

import AppExternalServiceInfoMessage from '../../components/general/appExternalServiceInfoMessage/appExternalServiceInfoMessage.js';
import AppModal from '../../components/general/appModal/appModal.js';
import ToggleButton from '../../components/fields/toggleButton/toggleButton.js';

import NewsImageItem from './newsImageItem.js';
import ListDropdown from './listDropdown.js';
import { buildExhibitLocationOptionList } from './exhibitLocation.js';


// Import module styles
import './scss/newsEditModal.scss';


const NewsEditModal = (props) => {
    const imageElementName = "newsEditImageElement";
    const fileSelectRef = useRef(null);
    const endOfItemsRef = useRef(null);
    const [prevListSize, setPrevListSize] = useState(null);
    const [shouldScroll, setShouldScroll] = useState(false);
    const [selectToIndex, setSelectToIndex] = useState(null);

    const { editItem, checkItemChanged, imageAccept, onEditClose, onEditOk, onToggleVisibility, 
            onImageSwapOrder, onFieldChange, onImageAdd, onImageChange, onImageDelete, onLocationChange } = props || {};
    const listImages = utils.isObject(editItem) && utils.isArray(editItem.data.imagens) ? editItem.data.imagens : [];

    useEffect(() => {
        setPrevListSize(editItem ? listImages.length : null);
        setShouldScroll(editItem && prevListSize!==null && listImages.length>prevListSize ? true : false);
    }, [prevListSize, editItem, listImages.length]);
    
    if(!editItem || !editItem.data) {
        return null;
    }

    const formError = editItem.control.erroEdicao;
    const isInsertMode = !editItem.data.id;
    const isFormChanged = checkItemChanged(editItem.data, ["imagens", "visivel", "exhibitLocation"], (isInsertMode ? { visivel: true } : null));

    const _getImageElements = () => {
        return document.getElementsByName(imageElementName);
    }

    const _handleFileChange = (e) => {
        const file = e.target.files[0];
        if(file) {
            const fr = new FileReader();
            fr.onload = ev => {
                const imgBase64 = utils.base64Encode(ev.target.result);
                onImageChange(selectToIndex, file, imgBase64);
            }
            fr.readAsArrayBuffer(file);
            e.target.value = "";
        }
    }

    const _handleSelectImageFile = (index) => {
        if(!listImages[index].imageBase64 || !listImages[index].imageBase64.trim()) {
            setSelectToIndex(index);
            if(fileSelectRef.current && fileSelectRef.current.click) {
                fileSelectRef.current.click();
            }
        }
    }

    const _scrollToEndOfList = () => {
        // Not usin optional chaining (endOfItemsRef.current?.scrollIntoView) due to some browsers incompatibility
        if(endOfItemsRef.current && endOfItemsRef.current.scrollIntoView) {
            endOfItemsRef.current.scrollIntoView({ block: "end", behavior: "smooth" });
        }
    }

    if(shouldScroll){
        setShouldScroll(false);
        _scrollToEndOfList();
    }

    return(
        <div id="newsEditModalId">
            <div className="file-select-input">
                <input
                    ref={fileSelectRef}
                    id="fileUploadId"
                    name="file_upload"
                    type="file"
                    accept={imageAccept}
                    onChange={e => _handleFileChange(e)}
                />
            </div>

            <AppModal
                closeOnEsc={true}
                closeOnClickOutside={false}
                onClose={() => onEditClose(isFormChanged)}
                headerContent={
                    <div className="modal-header-wrapper">
                        <div className="modal-header-title">{isInsertMode ? "Inserção" : "Edição"} de item de Notícias</div>
                        <div className="modal-close-button" onClick={() => onEditClose(isFormChanged)}>
                            <ThemeImage module="appCard" imageId="forms-icon_back" />
                        </div>
                    </div>
                }
            >
                <div className="news-editor-wrapper">
                    <div className="news-form-wrapper">
                        <div className="news-data no-select">

                            <div className="form-row form-instructions">
                                <div><i className="field-required" /> Campo obrigatório</div>
                            </div>
                            
                            { isInsertMode &&
                            <div className="form-row">
                                <div className="new-item-order">Novo item será inserido na ordem: <span>{editItem.data.ordem}</span></div>
                            </div>
                            }

                            <div className="form-row">
                                <div className="field-group input-field item-visibility">
                                    <span className="caption">Visível:</span>
                                    <div className="visibility-toggle">
                                        <ToggleButton
                                            id={`editSwitchId`}
                                            value={editItem.data.visivel}
                                            onToggle={() => onToggleVisibility()}
                                        /> 
                                    </div>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="field-group input-field item-exhibit-location">
                                    <div className="field-name-block">
                                        <label>Onde Exibir:</label>
                                        <i className="field-required" />
                                        <i className="field-information" title="Selecione onde a notícia deve ser exibida" />
                                    </div>
                                    <div className="location-list-wrapper">
                                        <ListDropdown
                                            id={`exhibitLocationId`}
                                            name="exhibitLocation"
                                            fieldData={{ 
                                                value: editItem.data.exhibitLocation, 
                                                errorMessage: editItem.control.fields.exhibitLocation.errorMessage
                                            }}
                                            className="fld-open-modulo"
                                            title={null}
                                            placeHolder="Selecione onde exibir a notícia"
                                            info={null}
                                            options={buildExhibitLocationOptionList()}
                                            onChange={elem => onLocationChange(elem.value)}
                                            disabled={false}
                                            required={false}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="field-group input-field">
                                    <label>Imagens:</label>
                                    <i className="field-required" />
                                    <i className="field-information" title="Inclua uma ou mais imagens para notícia" />
                                    <i className="image-add" title="Adicionar imagem" onClick={onImageAdd} />

                                    <div className="image-selection-wrapper">
                                        <div className="image-selection-box">

                                            { listImages.map((imgData, indx) => {
                                                const onOrderUp = indx > 0 ? () => onImageSwapOrder(indx, indx-1) : null;
                                                const onOrderDown = indx < (listImages.length-1) ? () => onImageSwapOrder(indx, indx+1) : null;
                                                const isItemChanged = checkItemChanged(editItem.data, ["imagens"], null, indx);
                                                return(
                                                    <NewsImageItem
                                                        key={`newsImageItemKey_${indx}`}
                                                        imageElementName={imageElementName}
                                                        changed={isItemChanged}
                                                        data={imgData}
                                                        fieldsControl={editItem.control.fields}
                                                        index={indx}
                                                        onSelectImageFile={_handleSelectImageFile}
                                                        onOrderUp={onOrderUp}
                                                        onOrderDown={onOrderDown}
                                                        onFieldChange={onFieldChange}
                                                        onImageDelete={onImageDelete}
                                                    />
                                                );
                                            })}
                                            
                                            <div ref={endOfItemsRef} className="end-of-items"></div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            { formError && 
                            <AppExternalServiceInfoMessage id="msgEditErrorID" className="info-error">
                                {formError}
                            </AppExternalServiceInfoMessage>
                            }

                            <div className="form-action">
                                <button type="button" disabled={!isFormChanged} className="btn-ok" onClick={() => onEditOk(_getImageElements())}>Ok</button>
                            </div>

                        </div>

                    </div>
                </div>
            </AppModal>
        </div>
    );
}

export default NewsEditModal;