/*
** @name: Meu Clínicas - stringUtils
** @author: 
** @date: 
** @description: Rotinas para manipulação de string
*/

import React, { Fragment } from 'react';
import utils from './utils';


const _lowerFirstLetter = function(value) {
    return value ? (value.slice(0,1).toLowerCase() + value.slice(1, value.length)) : "";
}
const _upperFirstLetter = function(value) {
    return value ? (value.slice(0,1).toUpperCase() + value.slice(1, value.length)) : "";
}

const startCase = (value) => {
    if(!value) {
        return "";
    }

    let lowerCase = value.toLowerCase();
    return (_upperFirstLetter(lowerCase));
}

const camelCase = (value) => {
    return _lowerFirstLetter(pascalCase(value));
}

const emptyToNull = (val) => (val===null || val===undefined || (val.trim && val.trim()==="")) ? null : val;

const pascalCase = (value) => {
    if(!value)
        return "";
        
    const re_trailing = /(^.*[^\s]|)(\s+)$/;
    const tailingSpaces = value.match(re_trailing) ? value.replace(re_trailing, "$2") : "";
    const words = _upperFirstLetter(value.toLowerCase()).split(" ");
    const text = words.reduce(function(concat, val) {
        if(val.trim() === "") {
            return concat;
        }
        return (concat + (concat.length>0 ? " " : "") + _upperFirstLetter(val));
    }, []);

    return (text + tailingSpaces);
}

const lowerCase = (value) => {
    return value ? value.toLowerCase() : "";
}

const upperCase = (value) => {
    return value ? value.toUpperCase() : "";
}

const maskCNPJ = (value) => {
    let masked = value
        .replace(/\D/g, '')
        .replace(/^(\d{2})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1/$2")
        .replace(/(\d{4})(\d)/, "$1-$2")
        .replace(/(-\d{2})\d+?$/, "$1");
    return masked;
}

const maskDate = (value, slashEnd) => {
    if(slashEnd) { // adiciona a primeira '/' com 2 díditos e '/' 4 dígitos
        return value
            .replace(/\D/g, '')
            .replace(/^(\d{2})(\d*)/, "$1/$2")
            .replace(/(\/\d{2})(\d*)/, "$1/$2")
            .replace(/(\/\d{4})\d+?$/, "$1");
    } else { // adiciona a primeira '/' com 3 díditos e '/' 5 dígitos
        return value
            .replace(/\D/g, '')
            .replace(/^(\d{2})(\d)/, "$1/$2")
            .replace(/(\d{2})(\d)/, "$1/$2")
            .replace(/(\d{4})\d+?$/, "$1");
    }
}

const prepareMessage = (template, parameters, missingParameterValue) => { 
    /* Função realiza a substituição dos parametros e tags no template de mensagem

       [br] => força uma quebra de linha
       [p]  => força um novo parágrafo
       {0}, {1}, {2}, ... => substitui parametro correspondente em 'parameters' ou, se não existir, por 'missingParameterValue'
    */
    if(!template || !utils.isString(template)) {
        return null;
    }

    const result = [];
    const useParagrahWrapper = template.match(/\[[pP]]/) ? true : false;
    const arrParagraphs =  template.split(/\[[pP]]/);
    arrParagraphs.forEach((paragraph, indxParagraph) => {
        const paragraphStyle = indxParagraph < (arrParagraphs.length-1) ? { marginBottom: "1em" } : {}
        const arrLines = paragraph.split(/\[[bB][rR]]/);
        const lines = arrLines.map((line, indxLine) => {
            const arrChunks = line.split(/\{\d+}/);
            const arrParamList = line.match(/\{(\d+)}/g);
            const addBreakLine = indxLine < (arrLines.length-1);
            const arrParamIdexes = !arrParamList ? null : arrParamList.map(currVal => {
                return parseInt(currVal.replace(/\{(\d+)}/, "$1"));
            });

            if((arrParamIdexes ? arrParamIdexes.length : 0) !== (arrChunks.length-1)) {
                throw new Error("[prepareMessage] Error parsing message template parameters.");
            }

            const lineContent = arrChunks.map((chunk, indxPart) => {
                const shouldAddParam = indxPart < (arrChunks.length-1);
                if(shouldAddParam) {
                    const pIndx = arrParamIdexes[indxPart];
                    const paramValue = parameters && utils.isInteger(pIndx) ? parameters[pIndx] : null;
                    if((typeof paramValue==='undefined' || paramValue===null) && (typeof missingParameterValue==='undefined')) {
                        throw new Error(`[prepareMessage] Missing parameter {${pIndx}}.`);
                    }

                    return <Fragment key={"chunk_" + indxPart}>{chunk}{paramValue || missingParameterValue}</Fragment>;
                } else {
                    return <Fragment key={"chunk_" + indxPart}>{chunk}</Fragment>;
                }
            });

            return(
                <Fragment key={`paragraph_${indxParagraph}_line_${indxLine}`}>
                    { lineContent }{ addBreakLine && <br /> }
                </Fragment>
            )
        })

        if(useParagrahWrapper) {
            // Paragrafos não usam tag <p> pois essa não aceita alguns tipos de elementos dentro
            result.push(
                <div key={`paragraph_${indxParagraph}`} className="paragraph-wrapper" style={paragraphStyle}>
                    {lines}
                </div>
            );
        } else {
            result.push(
                <Fragment key={`paragraph_${indxParagraph}`}>
                    {lines}
                </Fragment>
            );
        }
    });
    
    return result;
}

export {
    startCase,
    camelCase,
    emptyToNull,
    pascalCase,
    lowerCase,
    upperCase,
    maskCNPJ,
    maskDate,
    prepareMessage
}