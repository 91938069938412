/*
** @name: Coletor REP-P - apiUrl
** @author: Daniel S. Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: 26/junho/2023
** @description: Rotinas para chamadas gerar url da api conforme ambiente atual (DESENV, HOMOLOG, PROD)
*/

const apiUrl = (servicePort, serviceName, apiVersion, resourceName) => {
    const apiRoot = `${process.env.REACT_APP_API_ROOT}`.replace(/\/*$/, '');
    const svcName = `${serviceName}`.replace(/^\/*/, '').replace(/\/*$/, '');
    const svcPort = `${servicePort}`.replace(/\D/g, '');
    const version = apiVersion ? `${apiVersion}`.replace(/\//g, '') : '';
    const entryPoint = resourceName ? `${resourceName}`.replace(/^\/*/, '') : '';
    if (`${process.env.REACT_APP_ENVIRONMENT}` === 'DEV') {
        return `${apiRoot}:${svcPort}${version ? `/${version}` : ''}${entryPoint ? `/${entryPoint}` : ''}`
    } else {
        return `${apiRoot}/${svcName}${version ? `/${version}` : ''}${entryPoint ? `/${entryPoint}` : ''}`
    }
}

export {
    apiUrl
}
