/*
** @name: Meu Clínicas - login
** @author: 
** @date:
** @description: Módulo para login do usuario.
** 
** @update: Julho 2020 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Atualizado para suportar opção de login por localizador do paciente
**
** @update: Março 2021 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Totalmente remodelado e adaptado para novo layout da aplicação e funcionalidades relacionadas
**
** @update: Abril 2022 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Refatoração e ajusto para novo toggle
**
** @update: Agosto 2022 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Implementado integração de módulo automática no logon
**
** @update: Abril 2023 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Implementando integração para registro do 'push device token'
*/
/*
    Modulos extras:
        CADASTRO: opcional
        CONFIRMAR_TELEFONE: opcional
        EDITAR_DADOS: opcional
        FAQ: opcional
        INFORMAR_LOCALIZADOR: opcional
        RECUPERAR_SENHA: opcional
        TERMO_CONSENTIMENTO: opcional
        VINCULAR_CADASTRO: opcional
*/

import React, { Component } from 'react';
import $ from 'jquery';

import integrationToken from '../../core/integrationToken.js';
import sessionStorageManager from '../../core/sessionStorageManager.js';
import specialAccessManager from '../../core/specialAccessManager.js';
import validatorLocalizador from '../../core/validatorLocalizador.js';
import utils from '../../core/utils.js';
import notificationManager from '../../core/notificationManager.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';
import { useAuthContext } from '../../core/authContext.js';
import { useAppThemeContext } from '../../core/appThemeContext.js';
import { getAppMobileConfig, getAppGeneralConfigPropertyByName } from '../../core/appThemeConfig.js';
import { isServiceEnable, ThemeImage } from '../../core/appThemeUtils.js';
import { getAppServiceIdByConfigName, APP_SERVICE_LIST } from '../../core/appServiceList.js';

import { hasServicePermission, hideService } from '../../components/general/appNavigationControls/appNavigationControls.js';

import { isRespostaConsentimentoVigente } from '../termoConsentimento/termoConsentimento.js';

import PendenteAtivacao from './pendenteAtivacao.js';
import QuestionDateField from './questionDateField.js';
import QuestionTextField from './questionTextField.js';
import StepEntrada from './stepEntrada.js';
import StepPerguntas from './stepPerguntas.js';
import StepSenha from './stepSenha.js';
import VersionInfo from './versionInfo.js';

import loginClient from '../../apiClients/login/loginClient.js';
import usuarioClient from '../../apiClients/login/usuarioClient.js';

// Import module styles
import './scss/login.scss'; 


const STEP_LOGIN_ENTRADA = 'login-entrada';
const STEP_LOGIN_SENHA = 'login-senha';
const STEP_LOGIN_PERGUNTAS = 'login-perguntas';
const STEP_PENDENTE_ATIVACAO = 'pendente-ativacao';

const PARAM_AUTO_OPEN_INTEGRATION = 'aoi';
const PARAM_MANTER_CONECTADO_TOGGLE = 'mc';

let intervalAppIntegration = null;

const clearAppIntegrationInterval = () => { if(intervalAppIntegration) window.clearInterval(intervalAppIntegration); }

const Login = (props) => {
    const appControllerContext = useAppControllerContext();
    const authContext = useAuthContext();
    const themeConfig = useAppThemeContext().getConfig();
    return(
        <LoginImplem 
            appControllerContext={appControllerContext}
            authContext={authContext}
            themeConfig={themeConfig}
            {...props}
        />
    )
}

class LoginImplem extends Component {

    easterEggDblClickCounter = 0;
    constructor(props) {
        super(props);

        this.state = {
            currentStep: STEP_LOGIN_ENTRADA,
            isAppRunning: window.rnIntegration && window.rnIntegration.isAppRunning() ? true : false,
            manterConectado: false,
            fieldToFocus: 'cpfEmailLocalizador',
            form: {
                cpfEmailLocalizador: {
                    value: null,
                    valid: false
                },
                senha: ""
            },
            questions: {
                current: null,
                in_use: [],
                available: ["dtNascimento", "naturalidade", "dtNascimento_2"],
                dtNascimento: {
                    value: "",
                    valid: false,
                    regex: /^\d{2}\/\d{2}\/\d{4}$/,
                    fieldRender: (value, name) => <QuestionDateField
                        id={`input-${name}`}
                        name={name}
                        className="field-question"
                        message="Informe sua data de nascimento"
                        value={value}
                        onChange={this._handleQuestionChange}
                    />
                },
                naturalidade: {
                    value: "",
                    valid: false,
                    regex: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
                    fieldRender: (value, name) => <QuestionTextField
                        id={`input-${name}`}
                        name={name}
                        className="field-question"
                        message="Informe cidade de nascimento"
                        value={value}
                        onChange={this._handleQuestionChange}
                    />
                }
            },
            errors: {
                entradaStepError: null,
                passwordStepError: null,
                perguntasStepError: null
            },
            envioEmail: null
        };
    }

    _appSetBackAction() {
        const { currentStep } = this.state;
        const rnIntegration = window.rnIntegration;
        if(rnIntegration && rnIntegration.isAppRunning()) {
            if(currentStep!==STEP_LOGIN_ENTRADA) {
                rnIntegration.backAction.push(() => { this._handleVoltar() });
            }
        }
    }

    _changeErrorState = (name, values) => {
        let updateErrors = this.state.errors;
        updateErrors[name] = values;
        this.setState({ errors: updateErrors });
    }

    _getAutoOpenIntegrationToken = () => {
        let token = this.props.autoOpenIntegrationToken;
        if(!token) {
            const queryString = window.location.search;
            const sp = new URLSearchParams(queryString);
            token = sp.get(PARAM_AUTO_OPEN_INTEGRATION);
        }
        return token ? token : null;
    }

    _finalizarLogin = (response, fingerprint, isAppAutoLogin) => {
        const { authContext, themeConfig } = this.props;
        const { manterConectado, isAppRunning } = this.state;
        const token = response.cadastroAtivado ? response.token : null;
        const issuedAt = utils.jwtGetIssueTime(token);
        const user = {
            cpf: response.cpf,
            email: response.email,                        
            nome: response.nome,
            nomeMae: response.nomeMae,
            naturalidade: response.naturalidade,
            rg: response.rg,
            celular: response.celular,
            prontuario: response.prontuario,
            numeroCns: response.numeroCns,
            dtNascimento: response.dtNascimento,
            pacCodigo: response.pacCodigo,
            dthrConfirmaTelefone: response.dthrConfirmaTelefone,
            localizador: response.localizador,
            permissoes: {},
            dthrAceiteTermos: response.dthrAceiteTermos,
            emailValidado: response.cadastroAtivado ? true : false,
            criadoEm: response.criadoEm,
            loginEm: response.loginEm,
            specificUseDataUpdateToken: response.specificUseDataUpdateToken,
            userId: response.userId,
            autoOpenToken: this._getAutoOpenIntegrationToken()
        };
        user.aceiteTermosSessao = (specialAccessManager.user.isRegistered(user) && response.dthrAceiteTermos!=null) ? true : false;
        user.localClockDifference = Date.now() - (issuedAt ? issuedAt : response.loginEm);
        user.appLoginTokenStored = isAppAutoLogin || (manterConectado && isAppRunning) ? true : false;
			
		sessionStorageManager.auth.store(token, fingerprint, user);
		        
        authContext.methods.refresh();

        if (!response.cadastroAtivado) { // usuário não terá token (não autenticado)
            this.setState({ currentStep: STEP_PENDENTE_ATIVACAO });
            return;
        }
		
        /* 
         * App Integrattion (assíncrono)
         */
        if(isAppRunning) {
            // Obter token de login para o App e salvar
            if(manterConectado) {
                if(!isAppAutoLogin) { // atualiza token quando não foi autologin do app
                    utils.asyncTokenRefresh(user.pacCodigo, true)
                        .then(res => {
                            const tokenApp = res.data;
                            window.rnIntegration.saveAppLogin(tokenApp, fingerprint, user.pacCodigo);
                        })
                        .catch(err => {}); // Ignore error
                }
            }

            // Register push notification device
            notificationManager.registerPushDeviceAsync(user)
                .then(res => console.info('Push notification device registered'))
                .catch(err => {
                    if(err && err.response && err.response.status === 409) { // existe outro usuário para o mesmo dispositivo
                        const { data } = err.response;
                        if(utils.isArray(data.patientsSameDevice)) {
                            console.info(`This device is registered to different user(s): ${JSON.stringify(data)}`);
                            notificationManager.notifications.setPushUserConflict(authContext, data);
                        }
                    } else {
                        console.error(`Unexpected error registering push device token: ${err ? err.message : ""}`);
                    }
                });
        }

        // Realiza integração de abertura automatica de modulo no login
        this.props.appControllerContext.methods.doClearCardModule();
        this._moduleAutoOpenIntegration(user, themeConfig);

        // Redirecionar conforme situacao do cadastro
        let startModule = null;
        let startModuleParameters = null;
        if(!startModule && isServiceEnable(themeConfig, APP_SERVICE_LIST.CONFIRMAR_TELEFONE)) {
            if(response.dthrConfirmaTelefone === null && response.celular !== null) {
                startModule = APP_SERVICE_LIST.CONFIRMAR_TELEFONE;
            }
        }

        if(!startModule && isServiceEnable(themeConfig, APP_SERVICE_LIST.INFORMAR_LOCALIZADOR)) {
            if(!response.pacCodigo) {
                startModule = APP_SERVICE_LIST.INFORMAR_LOCALIZADOR;
            }
        }

        if(!startModule && isServiceEnable(themeConfig, APP_SERVICE_LIST.VINCULAR_CADASTRO)) {
            if(utils.isObject(response.linkableUser)) {
                startModule = APP_SERVICE_LIST.VINCULAR_CADASTRO;
                startModuleParameters = { linkableUser: response.linkableUser };
            }
        }

        if(startModule) {
            setTimeout(() => {
                this.props.appControllerContext.methods.doAddCardModule(startModule, startModuleParameters, false);
            }, 100);
        }

        // Obter permissões especiais do usuário (assíncrono)
        if(token && user.pacCodigo) { // Usuário já ativado (link e-mail) e possui pacCodigo
            specialAccessManager.asyncUserPermissions(user.pacCodigo)
                .then(res => {
                    // Update user in session
                    const userData = specialAccessManager.permissions.setData(authContext, res.data);

                    // Verificar necessidade de responder termo de consentimento (LGPD)
                    if(isServiceEnable(themeConfig, APP_SERVICE_LIST.TERMO_CONSENTIMENTO)) {
                        if(isRespostaConsentimentoVigente(userData) === false) {
                            this.props.appControllerContext.methods.doAddCardModule(APP_SERVICE_LIST.TERMO_CONSENTIMENTO, null, false);
                        }
                    }
                })
                .catch(err => { // Ignorar erro => fica vazia as permissões especiais
                    console.error('Erro obtendo permissões do paciente: ' + err.message);
                });
        }
    }

    _focusFormField = () => {
        const { isAppRunning, fieldToFocus } = this.state;
        const isAutoFocusEnabled = getAppGeneralConfigPropertyByName(this.props.themeConfig, "allowNonAppAutoFocusOnLogin");
        if(!isAutoFocusEnabled || isAppRunning || !fieldToFocus) { // If it is inside the App don't focus any field
            return;
        }

        let elements = document.getElementsByName(fieldToFocus);
        if(elements.length > 0) {
            elements[0].focus();
        }
        this.setState({
            fieldToFocus: false
        });
    }

    _handleAlterarDados = () => {
        this.props.appControllerContext.methods.doSetCardModule(APP_SERVICE_LIST.EDITAR_DADOS, null);
    }

    _handleAppIntegration = () => {
        this._appSetBackAction();

        if(!this.state.isAppRunning) {
            clearAppIntegrationInterval();
            return;
        }

        const rnIntegration = window.rnIntegration;
        const versionInfo = rnIntegration.getAppVersionInfo();
        if(versionInfo) {
            const mobileCfg = getAppMobileConfig(this.props.themeConfig);
            const result = utils.checkAppVersion(versionInfo.version, mobileCfg.appRequiredVersion);
            if(result===false) {
                clearAppIntegrationInterval();
                rnIntegration.triggerAppUpdateRequired();
                return;
            } else {
                if(result===null) { // Invalid current and/or required versions
                    console.error('Invalid current and/or required versions');
                }
            }
        }

        const credentials = rnIntegration.getCredentials();
        if(credentials) {
            clearAppIntegrationInterval();
            this._processaAppAutoLogin(credentials);
            return;
        }

        if(credentials!==false) {
            clearAppIntegrationInterval();
            this.setState({});
        }
    }

    _handleCadastro = () => {
        this.props.appControllerContext.methods.doSetCardModule(APP_SERVICE_LIST.CADASTRO, null);
    }

    _handleEmailCPFLocalizadorChange = (value, valid) => {
        let updatedForm = this.state.form;
        updatedForm.cpfEmailLocalizador = {
            value,
            valid
        };

        this.setState({ form: updatedForm });
    }

    _handlePasswordChange = (value) => {
        let updatedForm = this.state.form;
        updatedForm.senha = value;
        this.setState({ form: updatedForm });
    }

    _handlePrecisaAjuda = () => {
        this.props.appControllerContext.methods.doSetCardModule(APP_SERVICE_LIST.FAQ, null);
    }

    _handleQuestionChange = ({ name, value }) => {
        if(!name) {
            return;
        }

        let updateQuestions = this.state.questions;
        let fieldRegex = updateQuestions[name].regex;
        if (fieldRegex && fieldRegex.test(value)) {
            updateQuestions[name].valid = true;
        } else{
            updateQuestions[name].valid = false;
        }
        updateQuestions[name].value = value;
        this.setState({ questions: updateQuestions });
    }

    _handleRecuperarSenha = () => {
        this.props.appControllerContext.methods.doSetCardModule(APP_SERVICE_LIST.RECUPERAR_SENHA, null);
    }

    _handleVoltar = () => {
        const { authContext } = this.props;
        let updateQuestions = this.state.questions;
        updateQuestions.current = null;
        updateQuestions.in_use = [];
        updateQuestions.available.forEach(function(item) {
            if(updateQuestions[item]) {
                updateQuestions[item].value = "";
                updateQuestions[item].valid = false;
            }
        });

        this.setState({ 
            currentStep: STEP_LOGIN_ENTRADA,
            fieldToFocus: 'cpfEmailLocalizador',
            form: {
                cpfEmailLocalizador: {
                    value: null,
                    valid: false
                },
                senha: ""
            },
            questions: updateQuestions,
            errors: {
                passwordStepError: null,
                entradaStepError: null,
                perguntasStepError: null
            },
            envioEmail: null
        });

        // Clear session
        sessionStorageManager.clear();
        authContext.methods.refresh();
        this.props.appControllerContext.methods.doTriggerRender();
    }

    _isEnableAlterarDados = () => {
        const user = sessionStorageManager.auth.getUserData();
        const fingerprint = sessionStorageManager.auth.getFingerprint();
        return fingerprint && user && user.userId && user.specificUseDataUpdateToken;
    }

    _isKeepConnectedVisible = () => {
        const { isAppRunning } = this.state;
        const queryString = window.location.search;
        const sp = new URLSearchParams(queryString); // used only for test propouses (toggle is uneffective if App not runnig)
        return (isAppRunning || (sp.get(PARAM_MANTER_CONECTADO_TOGGLE) && sp.get(PARAM_MANTER_CONECTADO_TOGGLE)!=='0')) ? true : false;
    }

    _initializeQuestions = (localizadorInfo) => {
        if(this.state.questions.current !== null || 
           this.state.errors.perguntasStepError !== null) {
            return;
        }
        let updateQuestions = this.state.questions;

        // Montas perguntas em uso conforme lista de perguntas (available) e lista de campos preenchidos no localizador
        updateQuestions.in_use = [];
        let fields = localizadorInfo.fields;
        Object.keys(fields).map(key => {
            let value = fields[key];
            if(value && updateQuestions.available.includes(key)) {
                updateQuestions.in_use.push(key);
            }
            return true;
        });

        // ATENCAO: Forçar uso apenas, e sempre, de dtNascimento (Solicitação da PO para ter uma só pergunta)
        updateQuestions.in_use = ['dtNascimento'];

        // Verifica se existem questões disponíveis
        if(updateQuestions.in_use.length > 0) { // Posiciona na primeira
            updateQuestions.current = 0;
            this.setState({
                questions: updateQuestions
            });
        } else {
            this._changeErrorState('perguntasStepError', {
                header: 'Ops!',
                message: 'Não foi possível obter perguntas para verificação'
            });
        }
    }

    _moduleAutoOpenIntegration = (user, themeConfig) => {
        try {
            const token = this._getAutoOpenIntegrationToken();
            const decodedToken = integrationToken.decode(token, user.pacCodigo);
            if(decodedToken) {
                const tokenParts = decodedToken.split(/;(.*)$/s, 2);
                let startModule = getAppServiceIdByConfigName(tokenParts[0]) ? tokenParts[0] : null;
                let startModuleParameters = null;
                if(startModule && tokenParts[1]) {
                    try {
                        startModuleParameters = JSON.parse(tokenParts[1]);
                    } catch(e) { // invalid JSON parameter will be considered as empty
                        console.error('Error parsing auto open module parameters');
                    }
                }
                const serviceHidden = hideService(user, themeConfig, startModule);
                const hasPermision = hasServicePermission(user, startModule);
                if(!serviceHidden && hasPermision) {
                    setTimeout(() => {
                        console.log("MAOI-ADD MODULE:", startModule, startModuleParameters);
                        this.props.appControllerContext.methods.doAddCardModule(startModule, startModuleParameters, false);
                    }, 50);
                }
            }
        } catch(e) {
            // ignore any unexpected error
        }
    }

    _processaAppAutoLogin = (credentials) => {
        const { token, fingerprint, pacCodigo } = credentials ? credentials : {};
        if(!token || !fingerprint || !pacCodigo)  {
            return;
        }

        this._setLoading(true);
        loginClient.loginToken(
            token,
            fingerprint,
            pacCodigo,
            (res => {              
                const response = res.data;
                this._setLoading(false);
                this._finalizarLogin(response, fingerprint, true);
            }),
            (err => { 
                // Ignore autologin erros and let user to login normally
                this._setLoading(false);
            })
        );
    }

    _processaEntrada = (e) => {
        e.preventDefault(); 

        if(this._validateLoginEntrada()) {
            this._changeErrorState('entradaStepError', null);

            let localizadorHCPA = this.state.form.cpfEmailLocalizador.value;
            if(validatorLocalizador.validate(localizadorHCPA)) {
                // Buscar localizador
                this._setLoading(true);
                loginClient.localizadorhcpa(
                    localizadorHCPA,
                    (res => {              
                        let response = res.data;

                        this._setLoading(false);
                        if(response.exists) {
                            this._initializeQuestions(response);
                            this.setState({ currentStep: STEP_LOGIN_PERGUNTAS, fieldToFocus: 'dtNascimento' });
                        } else {
                            // Ao invés de informar que não é válido deixar preencher 1 pergunta (evitar que malandro saiba se acertou)
                            response.fields.dtNascimento = true;
                            this._initializeQuestions(response);
                            this.setState({ currentStep: STEP_LOGIN_PERGUNTAS, fieldToFocus: 'dtNascimento' });
/*
                            this._changeErrorState('entradaStepError', {
                                header: 'Atenção',
                                message: 'Localizador informado não encontrado'
                            });
*/
                        }
                    }),
                    (err => {
                        this._setLoading(false);
                        this._changeErrorState('entradaStepError', {
                            header: 'Ops...',
                            message: 'Ocorreu um erro ao processar sua requisição'
                        });
                    })
                );
            } else {
                this.setState({ 
                    currentStep: STEP_LOGIN_SENHA,
                    fieldToFocus: 'senha'
                });
            }
        } else {
            this._changeErrorState('entradaStepError', {
                header: 'Ops!',
                message: 'Verifique o preenchimento do campo'
            });
        }
    }

    _processaLoginPerguntas = (e) => {
        e.preventDefault(); 

        let updateQuestions = this.state.questions;
        const questionName = updateQuestions.current === null ? '' : updateQuestions.in_use[updateQuestions.current];
        const question = updateQuestions[questionName];
        if(!question) {
            return;
        }

        const isLast = !(updateQuestions.current < (updateQuestions.in_use.length-1));
        if(!isLast) { // Next question
            $(`#input-${questionName}`).val(""); // Limpara para evitar sujeita no próximo campo

            updateQuestions.current++;
            this.setState({
                questions: updateQuestions
            });
        } else {
            let answers = [];
            for(let i=0; i<updateQuestions.in_use.length; i++) {
                let name = updateQuestions.in_use[i];
                let value = updateQuestions[name].value;
                answers.push({
                    nome: name,
                    valor: value
                });
            }

            // Realizar login por localizador
            let localizadorHCPA = this.state.form.cpfEmailLocalizador.value;
            const uuidv4 = require('uuid/v4');
            let fingerprint = uuidv4();
    
            this._changeErrorState('perguntasStepError', null);
            this._setLoading(true);

            loginClient.loginLocalizador(
                localizadorHCPA, answers, fingerprint, 
                (res => {
                    const response = res.data;       

                    this._setLoading(false);
                    this._finalizarLogin(response, fingerprint);
                }),
                (err => {                 
                    this._setLoading(false);
                    if (err.response && err.response.status === 403) {
                        this._changeErrorState('perguntasStepError', {
                            header: 'Atenção',
                            message: 'Localizador ou respostas erradas'
                        });
                    } else if (err.response && err.response.status === 409) {
                        this._changeErrorState('perguntasStepError', {
                            header: 'Atenção',
                            message: 'Conflito de usuário com localizador informado'
                        });
                    } else {
                        this._changeErrorState('perguntasStepError', {
                            header: 'Ops...',
                            message: 'Ocorreu um erro ao processar sua requisição'
                        });
                    }
                })
            );
        }
    }

    _processarLoginSenha = (e) => {
        e.preventDefault(); 

        const uuidv4 = require("uuid/v4");
        let fingerprint = uuidv4();                     
        
        this._changeErrorState("passwordStepError", null);
        this._setLoading(true);

        loginClient.login(
            this.state.form.cpfEmailLocalizador.value, 
            this.state.form.senha,
            fingerprint, 
            (res => {
                const response = res.data;
                this._setLoading(false);
                this._finalizarLogin(response, fingerprint);
            }),
            (err => {                 
                this._setLoading(false);
                if (err.response && err.response.status === 403) {
                    this._changeErrorState("passwordStepError", {
                        header: "Atenção",
                        message: "Usuário e/ou senha inexistentes"
                    });
                } else if (err.response && err.response.status === 409) {
                    this._changeErrorState("passwordStepError", {
                        header: "Atenção",
                        message: "Conflito de usuários com mesmo localizador"
                    });
                } else {
                    this._changeErrorState("passwordStepError", {
                        header: "Ops!",
                        message: "Ocorreu um erro ao processar sua requisição"
                    });
                }
            })
        );
    }

    _reenviarEmailAtivacao() {
        let userData = sessionStorageManager.auth.getUserData();
        if(!userData) {
            this.setState({
                envioEmail: {
                    header: "Ops!",
                    message: "Dados inválidos do usuário.",
                    enviadoComSucesso: false
                }
            }); 
            return;
        }

        usuarioClient.reenviarEmailTokenViaLogin(
            userData.cpf,
            (res => {                
                this.setState({
                    envioEmail: {
                        header: "Sucesso",
                        message: "Foi enviado um novo e-mail para ativação.",
                        enviadoComSucesso: true
                    }
                });               
            }),
            (err => {
                this.setState({
                    envioEmail: {
                        header: "Ops!",
                        message: "Erro ao enviar e-mail.",
                        enviadoComSucesso: false
                    }
                }); 
            })
        );
    }

    _setLoading = (visible) => {
        utils.setLoadingVisibility(this.props.appControllerContext, visible);
    }

    _toggleManterConectado = () => {
        this.setState({ manterConectado : !this.state.manterConectado });
    }

    _validateLoginEntrada = () => {
        return this.state.form.cpfEmailLocalizador ? this.state.form.cpfEmailLocalizador.valid : false;
    }

    _validateLoginPerguntas = () => { // Retorna true se pergunta atual preenchida corretamente
        const { questions } = this.state;
        const questionName = questions.current === null ? '' : questions.in_use[questions.current];
        const question = questions[questionName];
        return question && question.valid;
    }
    
    _validateLoginSenha = () => {
        return this.state.form.senha && this.state.form.senha!=="";
    }

    RederSwitch = () => {
        const commonProperties = {
            themeConfig: this.props.themeConfig,
            manterConectado: this.state.manterConectado ? true : false,
            showManterConectado: this._isKeepConnectedVisible(),
            onManterConectadoToggle: this._toggleManterConectado.bind(this),
            onAjuda: this._handlePrecisaAjuda
        }
        if(this.state.currentStep === STEP_LOGIN_ENTRADA) {
            return <StepEntrada
                        {...commonProperties}
                        disableAvancar={!this._validateLoginEntrada()}
                        errorMessage={this.state.errors["entradaStepError"]}
                        onLoginChange={this._handleEmailCPFLocalizadorChange.bind(this)}
                        onAvancar={this._processaEntrada.bind(this)}
                        onCadastro={this._handleCadastro.bind(this)}
                    />;
        } else if(this.state.currentStep === STEP_LOGIN_SENHA) {
            return <StepSenha
                        {...commonProperties}
                        disableEntrar={!this._validateLoginSenha()}
                        errorMessage={this.state.errors["passwordStepError"]}
                        onPasswordChange={this._handlePasswordChange.bind(this)}
                        onEntrar={this._processarLoginSenha.bind(this)}
                        onVoltar={this._handleVoltar.bind(this)}
                        onRecuperarSenha={this._handleRecuperarSenha.bind(this)}
                    />

        } else if(this.state.currentStep === STEP_LOGIN_PERGUNTAS) {
            return <StepPerguntas
                        {...commonProperties}
                        questions={this.state.questions}
                        disableActionButton={!this._validateLoginPerguntas()}
                        errorMessage={this.state.errors["perguntasStepError"]}
                        onActionButton={this._processaLoginPerguntas.bind(this)}
                        onVoltar={this._handleVoltar.bind(this)}
                    />
        } else {
            return null;
        }
    }

    componentDidMount() {
        this._handleAppIntegration();
        intervalAppIntegration = window.setInterval(this._handleAppIntegration, 100);

        this._focusFormField();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.currentStep!==prevState.currentStep || this.props.updateControl!==prevProps.updateControl) {
            this._appSetBackAction();
        }
        this._focusFormField();
    }

    componentWillUnmount() {
        clearAppIntegrationInterval();
    }

    render() {
        return(
            <div className={`login-wrapper${this.props.hidden ? " hidden" : ""}`}>
                <div className="content-wrapper">
                    <div className="content-box">

                        <div className="header">
                            <div className="app-logo">
                                <ThemeImage module="login" imageId="logo_app-horizontal" />
                            </div>
                        </div>

                        <div className="body">
                            { (this.state.currentStep === STEP_PENDENTE_ATIVACAO) ? 
                                <PendenteAtivacao 
                                    themeConfig={this.props.themeConfig}
                                    envioEmail={this.state.envioEmail}
                                    onVoltar={this._handleVoltar.bind(this)}
                                    onReenviarEmail={this._reenviarEmailAtivacao.bind(this)}
                                    onAlterarDados={this._isEnableAlterarDados() ? this._handleAlterarDados.bind(this) : null}
                                />
                                : this.RederSwitch()
                            }
                        </div>

                        <div className="footer">
                            <div className='organization-logo'>
                                <ThemeImage module="login" imageId="logo_rodape" />
                            </div>
                            <VersionInfo isAppRunning={this.state.isAppRunning} />
                        </div>

                    </div>
                </div>

                <div className="ending-padding"></div>
            </div>
        );
    }
}

export default Login;

export {
    PARAM_AUTO_OPEN_INTEGRATION
}