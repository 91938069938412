/*
** @name: Meu Clínicas - appThemeConfig
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Novembro 2020
** @description: Componente que provem rotinas de configuracao para acesso ao thema em uso da aplicação.
** => Pode se avaliar possibilidade de integar as rotinas totalmente no contexto de tema a ser criado
*/

const getAppGeneralConfig = (config) => {
    return config.generalConfig;
}

const getAppMobileConfig = (config) => {
    return config.mobileConfig;
}

const getAppUserHomeConfig = (config) => {
    return config.userHomeConfig;
}

const getAppNavigationConfig = (config) => {
    return config.navigationConfig;
}

const getAppServicesConfig = (config) => {
    return config.applicationServicesConfig;
}

const getAppImageCache = (config) => {
    return config.imageCache;
}

const getAppImagesConfig = (config) => {
    return config.imagesConfig;
}

const getAppLinkRedirectConfig = (config) => {
    return config.linkRedirect;
}

const getAppLinks = (config) => {
    return config.applicationLinks;
}

const getAppMessages = (config) => {
    return config.applicationMessages;
}

const getAppServiceConfigByName = (config, serviceName) => {
    return getAppServicesConfig(config)[serviceName];
}

const getAppConfigImagePath = (config, itemName, imageId) => {
    const imageCfg = getAppImagesConfig(config);
    return (imageCfg && imageCfg[itemName] && imageCfg[itemName][imageId]) ? imageCfg[itemName][imageId] : null;
}

const getAppGeneralConfigPropertyByName = (config, propertyName) => {
    return getAppGeneralConfig(config)[propertyName];
}

const getAppLinkRedirectPropertyByName = (config, propertyName) => {
    return getAppLinkRedirectConfig(config)[propertyName];
}

const getAppLinkByName = (config, linkName) => {
    return getAppLinks(config)[linkName];
}

const getAppMessagesById = (config, messageId) => {
    return getAppMessages(config)[messageId];
}

export {
    getAppGeneralConfig,
    getAppMobileConfig,
    getAppUserHomeConfig,
    getAppNavigationConfig,
    getAppServicesConfig,
    getAppImageCache,
    getAppImagesConfig,
    getAppLinkRedirectConfig,
    getAppLinks,
    getAppMessages,
    getAppServiceConfigByName,
    getAppConfigImagePath,
    getAppGeneralConfigPropertyByName,
    getAppLinkRedirectPropertyByName,
    getAppLinkByName,
    getAppMessagesById
}
