/*
** @name: Meu Clínicas - appCard
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2021
** @description: Componente para o 'render' dos 'cards' da aplicação. Possui botoes de navegacao (ajuda e fechar),
** título e aceita empilhamento.
*/

import React, { useState, useEffect, Component } from 'react';
import { isIOS } from 'react-device-detect';
import $ from 'jquery';

import { useAppControllerContext } from '../../../core/appControllerContext.js';
import { ThemeImage } from '../../../core/appThemeUtils.js';
import utils from '../../../core/utils.js';

// Import module styles
import './appCard.scss'; 


const WRAPPER_BASE_ID = "cardWrapperId_";
const ANIMATION_TYPES = {
    FADE: "Fade",
    VERTICAL_SLIDE: "VerticalSlide"
}

const ANIMATION_DELAY = 500;
const ANIMATION_TYPE = ANIMATION_TYPES.VERTICAL_SLIDE;

const useDelayUnmount = (isMounted, delayTime) => {
    const [shouldRender, setShouldRender] = useState(false);
  
    useEffect(() => {
        let timeoutId = null;
        if (isMounted && !shouldRender) {
            setShouldRender(true);
        } else if (!isMounted && shouldRender) {
            timeoutId = setTimeout(() => setShouldRender(false), delayTime);
        }
        return () => clearTimeout(timeoutId);
    }, [isMounted, delayTime, shouldRender]);
    return shouldRender;
}

const AppCard = (props) => {
    const { cardSchema, fadeOut, onClose, hideCloseButton, onBeforeClose } = props;

    // Validate requitred properties
    if(cardSchema!=="normal" && cardSchema!=="forms") 
        throw new Error(`Missing or invalid 'cardSchema' property for 'AppCard'. [${cardSchema}]`);

    utils.checkRequiredFunctionProperty(onClose, 'onClose', 'AppCard');
    utils.checkRequiredFunctionProperty(onBeforeClose, 'onBeforeClose', 'AppCard');

    // Animation controll
    const appControllerContext = useAppControllerContext();
    const [isMounted, setIsMounted] = useState(true);
    const shouldRender = useDelayUnmount(isMounted, ANIMATION_DELAY);
    const shouldClose = (!isMounted && !shouldRender);
    const handleClose = () => {
        if(isMounted && false!==onBeforeClose()) {
            setIsMounted(false);
        }
    };

    if(shouldClose) {
        setTimeout(() => onClose(), 10);
    } else if(fadeOut) {
        setTimeout(() => handleClose(), 10);
    }
    if(!shouldRender) {
        return null;
    }

    return(
        <AppCardImplm 
            appControllerContext={appControllerContext}
            hidden={shouldClose}
            isMounted={isMounted}
            onCloseCard={hideCloseButton ? null : handleClose}
            {...props}
        />
    )
}

class AppCardImplm extends Component {
    constructor(props) {
        super(props);
        this.state = { }
    }

    _getCardWrapperId = () => {
        return `${WRAPPER_BASE_ID}${this.props.zIndex}`;
    }

    _getScrollElements = () => {
        const containerSelector = ".app-viewport > .card-wrapper > .card-box > .module-wrapper > .module-box > .module-content";
        let content = $(`${containerSelector} > div`);
        content = content.length>0 ? content.get(content.length-1) : null;
        const container = content ? content.parentNode : null;
        return ({ container, content });
    }

    _scrollLock = (e) => {
        // Não funcionou corretamentew no iOS Mobile
        //e.preventDefault();
        e.stopPropagation();
    }

    _updateModuleContentArea = () => {
        const { container, content } = this._getScrollElements();
        utils.updateScrollableArea(container, content);
    }

    componentDidMount() {
        if(utils.isDesktop()) {
            const { content } = this._getScrollElements();
            this.contentResizeMonitor = utils.startResizeMonitor(content, this._updateModuleContentArea);
        }

        if(isIOS) {
            /* Workaround para prevenir bug do iOS de scroll dos objetos que não sejam o top most z-index */
            const elemId = `#${this._getCardWrapperId()}`;
            $(elemId).on("touchmove", this._scrollLock);
            $(elemId).on("scroll", this._scrollLock)
        }
    }

    componentWillUnmount() {
        if(utils.isDesktop()) {
            utils.stopResizeMonitor(this.contentResizeMonitor, this._updateModuleContentArea);
        }

        if(isIOS) {
            const elemId = `#${this._getCardWrapperId()}`;
            $(elemId).off("touchmove", this._scrollLock);
            $(elemId).off("scroll", this._scrollLock);
        }
    }

    render() {
        const { appControllerContext, isMounted, hidden, onCloseCard,
                zIndex, cardTitle, cardSchema, module, showCloseAsBack, helpContext } = this.props;

        // Build card
        const wrapperStyle = {};
        wrapperStyle.zIndex = zIndex ? zIndex : 1;
        wrapperStyle.animation = isMounted ? 
            `mountAnimation${ANIMATION_TYPE} ${ANIMATION_DELAY}ms ease-in` : 
            `unmountAnimation${ANIMATION_TYPE} ${ANIMATION_DELAY+20}ms ease-in`;  // Add 20ms to unmount animation to avoind component blink

        return(
            <div id={this._getCardWrapperId()} className={`card-wrapper${hidden ? " hidden" : ""}`} style={wrapperStyle}>
                <div className={`card-box ${cardSchema}`}>
                    <div className="card-header">
                        <div className="action-bar-wrapper">
                            { helpContext ?
                            <div className="action-box action-button" onClick={() => appControllerContext.methods.doSetHelp(helpContext)}>
                                <ThemeImage module="appCard" imageId={`${cardSchema}-icon_help`} className="card-action-help" />
                            </div>
                            :
                            <div className="action-box"></div>
                            }
                            { onCloseCard ?
                            <div className="action-box action-button" onClick={() => onCloseCard()}>
                                <ThemeImage module="appCard" imageId={`${cardSchema}-icon_${showCloseAsBack ? "back" : "close"}`} className="card-action-close" />
                            </div>
                            :
                            <div className="action-box"></div>
                            }
                        </div>
                        { cardTitle &&
                        <div className="card-title">{cardTitle}</div>
                        }
                    </div>
                    <div className={`module-wrapper${cardTitle ? " card-has-title" : ""}`}>
                        <div className="module-box">
                            <div id="appCardModuleContentId" className="module-content">
                                {module}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AppCard;
