/*
** @name: Meu Clínicas - newsImageItem
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2022
** @description: Componente para gera um item de imagem (com seus atributos) na lista de edição de uma notícia
** 
*/

import React from 'react';

import utils from '../../core/utils.js';
import { ThemeImage } from '../../core/appThemeUtils.js';
import { APP_SERVICE_LIST } from '../../core/appServiceList.js';

import { getImageType } from '../../components/general/imageCarousel/imageCarousel.js';

import ListDropdown from './listDropdown.js';
import TextInput from './textInput.js';


const _buildModuleList = () => {
    const options = Object.keys(APP_SERVICE_LIST)
        .sort((a, b) => a < b ? -1 : (a > b ? 1 : 0))
        .map((key, indx) => {
            return { "key": `modulo_${indx}`, "value": APP_SERVICE_LIST[key], "text": key };
        }, []);
    options.unshift({ "key": `empty`, "value": "", "text": "-" });
    return options;
}

const NewsImageItem = (props) => {
    const { data, fieldsControl, index, changed, imageElementName, onSelectImageFile, onOrderUp, onOrderDown, onFieldChange, onImageDelete } = props;
    if(!utils.isObject(data)) {
        return null;
    }

    const base64 = data.imageBase64 ? data.imageBase64.trim() : null;
    const imageType = getImageType(base64);
    const imageData = base64 ? `data:${imageType ? imageType : "image"};base64,${data.imageBase64}` : null;
    const itemDeleted = data.deleted ? true : false;
    const imageError = fieldsControl.imagens.errorMessage[index];
    const hasError = imageError || fieldsControl.legenda.errorMessage[index] || fieldsControl.link.errorMessage[index];
    const showOrderChange = onOrderUp || onOrderDown;

    return(
        <div className={`image-item-wrapper${itemDeleted ? " deleted" : (hasError ? " has-error" : (changed ? " changed" : ""))}`}>

            <div className="item-action-wrapper">

                { showOrderChange &&
                <div className="order-change-wrapper">
                    { onOrderUp &&
                    <div className="order-up" onClick={onOrderUp}>
                        <ThemeImage module="news-manager" imageId="icon_order-up" title="Diminuir ordem" />
                    </div>
                    }
                    { onOrderDown &&
                    <div className="order-down" onClick={onOrderDown}>
                        <ThemeImage module="news-manager" imageId="icon_order-down" title="Aumentar ordem" />
                    </div>
                    }
                </div>
                }

                <div className="action-buttons-wrapper">
                    { (!itemDeleted && onImageDelete) &&
                    <div className="image-delete" title="Deletar item" onClick={() => onImageDelete(index, true)}>
                        <ThemeImage module="news-manager" imageId="icon_item-delete" />
                    </div>
                    }
                    { (itemDeleted && onImageDelete) &&
                    <div className="image-restore" title="Recuperar item excluído" onClick={() => onImageDelete(index, false)}>
                        <ThemeImage module="news-manager" imageId="icon_item-restore" />
                    </div>
                    }
                </div>

            </div>

            <div className="item-content-wrapper">

                <div className="item-content-box">
                    <div className={`img-box${!imageData ? " no-img" : ""}`} onClick={imageData ? () => onSelectImageFile(index) : null}>
                        { imageData && 
                        <img name={imageElementName} src={imageData} alt="" />
                        }
                        { !imageData && 
                        <div className="image-selection-frame" onClick={() => onSelectImageFile(index)}>
                            <div className="pick-image-message">Selecione</div>
                        </div>
                        }
                        { imageError &&
                        <div className="field-message mc-warning">{imageError}</div>
                        }
                    </div>

                    <div className="info-box">
                        <div className="form-row">
                            <TextInput
                                id={`legenda_${index}`}
                                name="legenda"
                                fieldData={{ value: data.legenda, errorMessage: fieldsControl.legenda.errorMessage[index] }}
                                className="fld-legenda"
                                title="Legenda"
                                placeHolder="Informe legenda para imagem (opcional)"
                                info={"Legenda da imagem"}
                                onChange={e => onFieldChange({index, name: e.target.name, value: e.target.value})}
                                disabled={false}
                                required={false}
                            />
                        </div>

                        <div className="form-row">
                            <TextInput
                                id={`link_${index}`}
                                name="link"
                                fieldData={{ value: data.link, errorMessage: fieldsControl.link.errorMessage[index] }}
                                className="fld-link"
                                title="Link"
                                placeHolder="Informe link (opcional, ex: https://<dominio>/link)"
                                info={"Link, opcional, ao clicar na imagem"}
                                onChange={e => onFieldChange({index, name: e.target.name, value: e.target.value})}
                                disabled={false}
                                required={false}
                            />
                        </div>

                        <div className="form-row">
                            <ListDropdown
                                id={`openModule_${index}`}
                                name="openModule"
                                fieldData={{ value: data.openModule, errorMessage: fieldsControl.openModule.errorMessage[index] }}
                                className="fld-open-modulo"
                                title="Módulo"
                                placeHolder="Selecione módulo (opcional)"
                                info={"Módulo, opcional, para abrir ao clicar na imagem"}
                                options={_buildModuleList()}
                                onChange={elem => onFieldChange({index, name: elem.name, value: elem.value})}
                                disabled={false}
                                required={false}
                            />
                        </div>

                        { data.openModule &&
                        <div className="form-row">
                            <TextInput
                                id={`paramModule_${index}`}
                                name="paramModule"
                                fieldData={{ value: data.paramModule, errorMessage: fieldsControl.paramModule.errorMessage[index] }}
                                className="fld-param-modulo"
                                title="Parêmetro do Módulo"
                                placeHolder="Informe parâmetro para módulo (opcional)"
                                info={"Objeto JSON para passar par ao módulo como parâmetro (opcional)"}
                                onChange={e => onFieldChange({index, name: e.target.name, value: e.target.value})}
                                disabled={false}
                                required={false} 
                                rows={3} 
                                counter={{
                                    "top": true,
                                    "bottom": false,
                                    "minCount": 1,
                                    "maxCount": 1000
                                }}
                            />
                        </div>
                        }

                    </div>
                </div>

            </div>
        </div>
    );
}

export default NewsImageItem;