/*
** @name: Meu Clínicas - app
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2021 - Refeito para novo layout da aplicação
** @description: Módulo principal da aplicação realiza validações de pre-requisitos (cookies, browsers, ...)
** Prove os contextos de autenticação e themas e chama a 'appController'
**
** @date: Outubro 2021
** @description: Adicionado biblioteca de JavaScripts externa para uso na aplicação
** @date: Novembro 2022
** @description: Adicionado teste para evitar chamada por 'iPhone home shortcut' por causa de problemas de renderização
*/

import 'semantic-ui-css/semantic.min.css';
import './scss/app.scss';  // In app.js styles must be imported before include other modules due to override order

import React, { Component } from 'react';
import { browserName, browserVersion, isAndroid, isIOS, isChrome, isFirefox, isSafari, isMobile, isTablet } from 'react-device-detect';

import utils from '../../core/utils';
import sessionStorageManager from '../../core/sessionStorageManager.js';
import { getApplicationConfiguration } from '../../core/appConfiguration.js';
import { AuthContextProvider } from '../../core/authContext.js';
import { AppThemeContextProvider } from '../../core/appThemeContext.js';
import { appendScript, removeScript } from '../../core/scriptHandler.js';
import { appCacheImages } from '../../core/imageCacheManager.js';

import AppController from '../../components/general/appController/appController.js';
import AppInitializing from '../../components/general/appInitializing/appInitializing.js';

import { CookiesNotEnabled, NotSupportedBrowser, HomeShortcutNotAllowed } from './errorPages.js';


// Import Fonts
import './appFonts.js';

// Import theme custom modules styles
import '../../theme/themeCustomStyles.js';


const applicationConfiguration = getApplicationConfiguration();

const isChromeWebView = (browserName && browserName.toLowerCase() === "chrome webview");
const isWebKit = (browserName && browserName.toLowerCase() === "webkit");


class App extends Component {

    constructor(props) {
        super(props);

        this.imageCacheWrapper = React.createRef();
        this.state = {
            initializationReady: false
        };
    }

    _initialize = async () => {
        // force imagens preload to cache it
        await appCacheImages(applicationConfiguration, this.imageCacheWrapper.current);
     
        this.setState({ initializationReady: true });
    }

    _isHomeShortCut = () => {
        return isIOS && isMobile && window.navigator && window.navigator.standalone ? true : false;
    }

    _isValidBrowser = () => {
        const browserMajorVersion = parseInt(browserVersion.split('.')[0]);
        const isNotMobile = !(isMobile || isTablet);
        if (isSafari && browserMajorVersion >= 10) {
            return true;
        }
        if (isChrome && browserMajorVersion >= 64) {
            return true;
        }
        if(isFirefox) {
            if ((isNotMobile || isAndroid) && browserMajorVersion >= 69) {
                return true;
            }
            if ((isMobile || isTablet) && isIOS && browserMajorVersion >= 22) {
                return true;
            }
        }
        if (isChromeWebView || isWebKit) {
            return true;
        }
        return false;
    }

    /* Events and Component Render */
    componentDidMount() {
        // adicionar javascripts externos para uso em varios modulos da aplicacao
        const appPublicUrl = utils.getPublicPath(true);
        appendScript(`${appPublicUrl}/scripts/ics.js/ics.deps.min.js`);
        appendScript(`${appPublicUrl}/scripts/jitsi/external_api.min.js`);

        // Initialize
        setTimeout(() => this._initialize(), 10);
    }

    componentWillUnmount() {        
        // remover javascripts externos
        const appPublicUrl = utils.getPublicPath(true);
        removeScript(`${appPublicUrl}/scripts/ics.js/ics.deps.min.js`);
        removeScript(`${appPublicUrl}/scripts/jitsi/external_api.min.js`);
    }

    render() {
        const { initializationReady } = this.state;

        if(this._isHomeShortCut()) {
            return <HomeShortcutNotAllowed />
        }
        if(!this._isValidBrowser()) {
            return <NotSupportedBrowser />;
        }
        if(!sessionStorageManager.isCookiesEnabled()) {
            return <CookiesNotEnabled />;
        }

        return (
            <>
                { !initializationReady && <AppInitializing /> }
                { initializationReady &&
                <AppThemeContextProvider config={applicationConfiguration}>
                    <AuthContextProvider>

                        <AppController />

                    </AuthContextProvider>
                </AppThemeContextProvider>
                }
                <div ref={this.imageCacheWrapper} className="app-cached-image-wrapper"></div>
            </>
        );
    }
} export default App;
