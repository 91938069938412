/*
** @name: Meu Clínicas - validatorCPF
** @author:
** @date:
** @description: Validador de CPF (utilizado em modulos cadastrais e login)
*/
//https://medium.com/@osuissa/javascript-validacao-de-cpf-passo-a-passo-9428ee32c104

class ValidatorCPF {

    validate(cpf) {
        cpf = cpf.replace(/\D/g,'');  // Remove mask
        if(cpf.length !== 11) {
            return false;
        }

        if( this._isRepeatedNumbers(cpf) ) {
            return false;
        }

        const nonCheckDigits = this._getNonCheckDigits(cpf)
        const firstCheckDigit = this._getValidationDigit(10)(nonCheckDigits )
        const secondCheckDigit = this._getValidationDigit(11)(nonCheckDigits.concat(firstCheckDigit))
        
        return this._isEqual( this._getTwoLastDigits( cpf ) )( this._mergeDigits( firstCheckDigit, secondCheckDigit ) )
    }

    _isMaskedCPF(value) {
        return /^(\d{3})\.(\d{3})\.(\d{3})-(\d{2})$/.test(value);
    }

    _isRepeatedNumbers(cpf) {
        return /^(\d)\1+$/.test(cpf);
    }

    _getNonCheckDigits(cpf) {
        return cpf.substr( 0, 9 ).split( '' );
    } 

    _getValidationDigit(multiplier) { 
        return ( cpf ) => this._getDigit( this._mod11( this._getSumOfProducts( cpf, multiplier ) ) );
    }

    _mod11(num) {
        return num % 11;
    }

    _getSumOfProducts(list, multiplier) {
        return list.reduce( this._toSumOfProducts( multiplier ), 0 );
    }

    _toSumOfProducts(multiplier) {
        return ( result, num, i ) => result + ( num * multiplier-- );
    }

    _getDigit(num) {
        return ( num > 1 ) ? 11 - num : 0;
    }

    _getTwoLastDigits(cpf){
        return `${cpf[ 9 ]}${cpf[ 10 ]}`;
    }

    _isEqual(a) {
        return ( b ) => b === a ;
    }

    _mergeDigits( num1, num2 ) {
        return `${num1}${num2}`;
    }
}

const validatorCPF = new ValidatorCPF();
export default validatorCPF;