/*
** @name: Meu Clínicas - appServiceRouter
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2021
** @description: Componente para realizar o reteamento de servicos extenos (páginas fora do fluxo normal da aplicação)
*/

import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import utils from './utils.js';

// Import Modules
import AtivarUsuario from '../modules/ativarUsuario/ativarUsuario.js';
import ExternalLinkRedirect from '../modules/externalLinkRedirect/externalLinkRedirect.js';
import InvalidPage from '../modules/invalidPage/invalidPage.js';
import PrivacyPolicy from '../modules/privacyPolicy/privacyPolicy.js';
import RedefinirSenha from '../modules/redefinirSenha/redefinirSenha.js';

import AdminMenu from '../modulesAdmin/adminMenu/adminMenu.js';
import Dashboard from '../modulesAdmin/dashboard/dashboard.js';
import FaqManager from '../modulesAdmin/faqManager/faqManager.js';
import GeradorTokenIntegracaoLogin from '../modulesAdmin/geradorTokenIntegracaoLogin/geradorTokenIntegracaoLogin.js';
import NewsManager from '../modulesAdmin/newsManager/newsManager.js';
import PesquisaExperienciaExport from '../modulesAdmin/pesquisaExperienciaExport/pesquisaExperienciaExport.js';
import SolicitacaoAgendamentoConsultaAdm from '../modulesAdmin/solicitacaoAgendamentoConsultaAdm/solicitacaoAgendamentoConsultaAdm.js';

import TeleatendimentoExterno from '../modulesExternalServices/teleatendimentoExterno/teleatendimentoExterno.js';
import ValidadorDocumentos from '../modulesExternalServices/validadorDocumentos/validadorDocumentos.js';
import VisualizaEstudoPACS from '../modulesExternalServices/visualizaEstudoPACS/visualizaEstudoPACS.js';

const AppServiceRouter = (props) => {
    return(
        <BrowserRouter basename={utils.getPublicPath()} forceRefresh={false}>
            <Routes>
                <Route path="/page-not-found" element={ <InvalidPage />} />
                <Route path="/privacy-policy" element={ <PrivacyPolicy />} />
                <Route path="/ativar-usuario" element={ <AtivarUsuario location={{...window.location}}  />} />
                <Route path="/redefinir-senha" element={ <RedefinirSenha location={{...window.location}}  />} />
                <Route path="/admin/menu" element={ <AdminMenu />} />
                <Route path="/admin/dashboard" element={ <Dashboard />} />
                <Route path="/admin/faq-manager" element={ <FaqManager />} />
                <Route path="/admin/news-manager" element={ <NewsManager />} />
                <Route path="/admin/pesquisa-experiencia" element={ <PesquisaExperienciaExport />} />
                <Route path="/admin/solicitacao-agendamento-consulta" element={ <SolicitacaoAgendamentoConsultaAdm />} />
                <Route path="/admin/integracao-login" element={ <GeradorTokenIntegracaoLogin />} />
                <Route path="/servicos/validar/*" element={ <ValidadorDocumentos location={{...window.location}} />} />
                <Route path="/servicos/imagens/*" element={ <VisualizaEstudoPACS location={{...window.location}} />} />
                <Route path="/servicos/teleatendimento/*" element={ <TeleatendimentoExterno location={{...window.location}} />} />
                <Route path="/go/*" element={ <ExternalLinkRedirect location={{...window.location}} />} />
                <Route path="*" element={ <InvalidPage />} />
            </Routes>
        </BrowserRouter>
    )
}

export default AppServiceRouter;
export {
    AppServiceRouter
}