/*
** @name: Meu Clínicas - logout
** @author: 
** @date: 
** @description: Componente para efetuar logout da aplicação
** @update: Novembro 2020 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Implementada a integração para elimitar o token especial de login extendido do App
*/

import sessionStorageManager from './sessionStorageManager.js';
import loginClient from '../apiClients/login/loginClient.js';

function logout(e, authContext, afterRequest) {
    if(e && e.preventDefault) {
        e.preventDefault();
    }

    const token = authContext.properties.token;
    const authContextRefresh = authContext.methods.refresh;
    const onComplete = (reload) => {
        // App Integration (Remove stored login token)
        const rnIntegration = window.rnIntegration;
        if(rnIntegration && rnIntegration.isAppRunning()) {
            rnIntegration.removeAppLogin();
        }

        afterRequest();

        sessionStorageManager.clear();
        authContextRefresh();
        if(reload) {
            window.location.reload();
        }
    }

    if(token) {
        loginClient.logout(
            token,
            () => { onComplete() },
            () => { onComplete(true) },
            true
        );
    } else {
        onComplete(true);
    }
} export default logout;