/*
** @name: Meu Clínicas - utils
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Outubro 2021
** @description: Rotinas para adicionar/remover JavaScripts externos em modulos
*/

const appendScript = (scriptPath) => {
    const script = document.createElement("script");
    script.src = scriptPath;
    script.async = true;
    document.body.appendChild(script);
}

const removeScript = (scriptPath) => {
    let listScripts = document.getElementsByTagName("script");
    for (let i=listScripts.length; i>=0; i--) {
        if (listScripts[i] && listScripts[i].getAttribute("src") !== null && 
            listScripts[i].getAttribute("src").indexOf(`${scriptPath}`) !== -1 ) {
           listScripts[i].parentNode.removeChild(listScripts[i]);
        }    
    }
}

export {
    appendScript, 
    removeScript
}