/*
** @name: Meu Clínicas - reagendamentoConsultaCanceladaCovid
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Outubro 2020
** @description: Módulo para criar formulários para solicitação de reagendamento de consultas canceladas por causa da COVID
**
** @update: Março 2021
** @description: Atualizado para novo layout da aplicação e funcionamento com cards
**
** @update: Junho 2021 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Atualizando tratamento do retorno de erro para novo sistema de validacao
*/

import React, { Component } from 'react';
import FormBuilder, { setConfigFieldProperty } from 'react-dj-forms-builder';
import { Form } from 'semantic-ui-react';

import utils from '../../core/utils.js';
import specialAccessManager, { PERMISSIONS } from '../../core/specialAccessManager.js';
import { useAuthContext } from '../../core/authContext.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';
import { useAppThemeContext } from '../../core/appThemeContext.js';
import { isServiceEnable } from '../../core/appThemeUtils.js';
import { pascalCase } from '../../core/stringUtils.js';
import { APP_SERVICE_LIST } from '../../core/appServiceList.js';

import AppCardModuleBasicWrapper from '../../components/general/appCardModuleBasicWrapper/appCardModuleBasicWrapper.js';
import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';
import { DropdownField, InputField, TextareaField } from '../../components/fields/formsBuilderCustoms/';

import formulariosClient from '../../apiClients/formularios/formulariosClient.js';


// Import module styles
import './reagendamentoConsultaCanceladaCovid.scss'; 

// Import form configuration
import formConfigMain from './reagendamentoConsultaCanceladaCovid.json';


const MensagemSucesso = () => {
    return(
        <div className="information-section">
            <div className="section-box">
                <div className="section-content">
                    <div className="sucesso">Sucesso</div>
                    <p>
                        Sua solicitação foi registrada com sucesso.
                    </p>
                    <p>
                        Nossa equipe médica irá avaliar e entrar em contato conforme dados informados.
                    </p>
                    <p>
                        Aguarde contato. Você poderá verificar o agendamento da consulta na opção consultas no menu.
                    </p>
                    <p>
                        <strong>Em virtude da pandemia COVID - 19 orientamos que:</strong><br/>
                        1. Sua entrada no hospital será permitida somente apenas 30 minutos antes do horário da consulta<br/>
                        2. Venha com sua máscara facial;<br/>
                        3. A entrada de acompanhante somente será permitida para casos amparados por lei (idosos, crianças, portadores de necessidades especiais). Um acompanhante por paciente.<br/>
                        4. Se estiver com febre ou sintomas gripais não venha até o hospital;<br/>
                    </p>
                </div>
            </div>
        </div>
    );
}

const MensagemPreenchido = () => {
    return(
        <div className="information-section">
            <div className="section-box">
                <div className="section-content">
                    <div className="warning">Atenção</div>
                    <p>
                        Sua solicitação já foi registrada anteriormente e será avaliada pela equipe da Central de Teleatendimento do HCPA que priorizará as respostas conforme gravidade dos casos, podendo demorar alguns dias o retorno sobre o pedido de agendamento.
                    </p>
                    <p>
                        Pedimos a gentileza de que aguarde. Você poderá verificar o agendamento da consulta na opção consultas no menu.
                    </p>
                    <p>
                        <strong>Em virtude da pandemia COVID - 19 orientamos que:</strong><br/>
                        1. Sua entrada no hospital será permitida somente apenas 30 minutos antes do horário da consulta<br/>
                        2. Venha com sua máscara facial;<br/>
                        3. A entrada de acompanhante somente será permitida para casos amparados por lei (idosos, crianças, portadores de necessidades especiais). Um acompanhante por paciente.<br/>
                        4. Se estiver com febre ou sintomas gripais não venha até o hospital;<br/>
                    </p>
                </div>
            </div>
        </div>
    );
}

const ReagendamentoConsultaCanceladaCovid = (props) => {
    const authContext = useAuthContext();
    const appControllerContext = useAppControllerContext();
    const themeConfig = useAppThemeContext().getConfig();
    return(
        <ReagendamentoConsultaCanceladaCovidImplem
            authContext={authContext}
            appControllerContext={appControllerContext}
            themeConfig={themeConfig}
            {...props}
        />
    )
}

class ReagendamentoConsultaCanceladaCovidImplem extends Component {
    constructor(props) {
        super(props);

        this._configureForm(formConfigMain);
        this.state = {
            formConfig: formConfigMain,
            fields: null,
            resultadoRegistro: null,
            erroRegistro: null,
        }
    }

    _buildRequest = () => {
        const user = this.props.authContext.properties.user;
        const fields = this.state.fields;
        if(!user || !fields || fields.constructor!==Object) {
            return;
        }

        const request = {};
        request.pacCodigo = user.pacCodigo;
        request.prontuarioPaciente = user.prontuario;
        request.nomePaciente = pascalCase(user.nome);

        const ignoreFiels = ["uselessField"];
        Object.keys(fields).forEach((key) => {
            if(!ignoreFiels.includes(key)) {
                const value = fields[key].value;
                request[key] = (value!=null && value.trim()!=="") ? value : null;
            }
        });

        return request;
    }

    _clearFieldsError = () => {
        const updateFields = this.state.fields;
        if(utils.isObject(updateFields)) {
            Object.keys(updateFields).forEach(key => {
                updateFields[key].errorMessage = null;
            });
            this.setState({ fields: updateFields });
        }
    }

    _configureForm = (config) => {
        const user = this.props.authContext.properties.user || {};

        // Popular campos com dados do cadastro
        if(user.email)
            setConfigFieldProperty(config, ["emailContato"], "value", user.email);

        const foneRE = /^\((\d{2})\)\s([\d-]*)$/;
        if(user.celular && foneRE.test(user.celular)) {
            const ddd = user.celular.replace(foneRE, "$1");
            const numero = user.celular.replace(foneRE, "$2").replace("-", "");
            setConfigFieldProperty(config, ["dddTelefoneContato"], "value", ddd);
            setConfigFieldProperty(config, ["numeroTelefoneContato"], "value", numero);
        }
    }

    _handleAction = () => {
        this._registrarSolicitacao();
    }

    _handleClose = () => {
        this.props.appControllerContext.methods.doCardFadeOut();
    }

    _handleFormUpdate = (fields) => {
        this.setState({ fields: fields });
    }

    _processaErrosFormulario = (erros) => {
        if(erros && erros.constructor === Object) {
            const updateFields = this.state.fields;
            Object.keys(erros).forEach((key) => {
                if(key!=="erroGeral") {
                    let message = erros[key];
                    if(!updateFields[key]) {
                        updateFields[key] = {
                            value: null,
                            errorMessage: null
                        }
                    }
                    updateFields[key].errorMessage = message;
                }
            });
            this.setState({
                fields: updateFields
            });
        }
    }

    _registrarSolicitacao = () => {
        this._setLoading(true);
        this._setErroRegistro(null);
        this._clearFieldsError();

        const request = this._buildRequest();
        formulariosClient.solicitacaoReagendConsultaCanceladaCovid(
            request,
            (res => {              
                const result = res.data;
                this._setLoading(false);
                this.setState({ resultadoRegistro: result });

                if(!result.valid) {
                    const validatorResponse = result.validatorResponse || {};
                    if(validatorResponse.errors && validatorResponse.errors.erroGeral) {
                        this._setErroRegistro(validatorResponse.errors.erroGeral);
                    } else {
                        this._setErroRegistro("Por favor, verifique o correto preenchimento do formulário.");
                    }
                    this._processaErrosFormulario(validatorResponse.errors);
                } else {
                    specialAccessManager.permissions.setSolicReagendConsultaCanceladaRegistrada(this.props.authContext);
                }
            }),
            (err => { 
                this._setLoading(false);
                this._setErroRegistro("Ops!, ocorreu um erro registrando sua pesquisa.");
            })
        );
    }

    _setErroRegistro = (msg) => {
        this.setState({ erroRegistro: msg });
    }

    _setLoading = (visible) => {
        utils.setLoadingVisibility(this.props.appControllerContext, visible);
    }

    componentDidMount() {
        if(!isServiceEnable(this.props.themeConfig, APP_SERVICE_LIST.REAGENDAMENTO_CONSULTA_CANCELADA_COVID)) {
            console.error("Module disabled");
            this._handleClose();
        }
    }

    render() {
        const user = this.props.authContext.properties.user;        
        const nomePaciente = pascalCase(user.nome);
        const resultado = this.state.resultadoRegistro;
        const exibeMensagemSucesso = resultado && resultado.valid;
        const spData = specialAccessManager.permissions.getDataFromUser(user, PERMISSIONS.REAGENDAMENTO_CONSULTA_COVID);
        const preenchido = spData && spData.solicitacaoRegistrada ? spData.solicitacaoRegistrada : false;

        return(
            <AppCardModuleBasicWrapper moduleName="reagendamento-consulta-covid">

                { (exibeMensagemSucesso || preenchido) ?
                <>
                    { exibeMensagemSucesso ? <MensagemSucesso /> : <MensagemPreenchido /> }

                    <div className="main-action">
                        <button id="btn-close" className="app-form-button" onClick={() => this._handleClose()}>OK</button>
                    </div>
                </>
                :
                <>
                    <div className="user-identification-section">
                        <div className="section-box">
                            <div className="section-title">Identificação do paciente</div>
                            <div className="section-content">
                                <div>
                                    <div className="titulo">Nome completo</div>
                                    <div className="descricao">{nomePaciente}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="main-section">
                        <div className="section-box">
                            <div className="section-title">Solicitação de Agendamento de Consulta</div>
                            <div className="section-content">
                                <div className="instruction">
                                    <div className="instruction-message"><strong>Atenção:</strong> sua solicitação será avaliada pela equipe da Central de Teleatendimento do HCPA que priorizará as respostas conforme gravidade dos casos, podendo demorar alguns dias o retorno sobre o pedido de agendamento.</div>
                                    <div className="instruction-required">*Obrigatório</div>
                                </div>
                                
                                <div className="form-wrapper">
                                    <Form name="formMain">
                                        <FormBuilder 
                                            blockFieldUpdate={false}
                                            disableClearErrorOnFieldChange={false}
                                            config={this.state.formConfig}
                                            fields={this.state.fields}
                                            page={0}
                                            className="form-content" 
                                            onChange={this._handleFormUpdate}
                                            overrideFieldRender={{
                                                'dropdown': DropdownField,
                                                'input': InputField,
                                                'textarea': TextareaField
                                            }}
                                        />
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>

                    { this.state.erroRegistro &&
                    <AppMessageBox
                        id="msg-error"
                        className="error"
                        messageData={{ "message": this.state.erroRegistro }} />
                    }

                    <div className="main-action">
                        <button id="btn-enviar" className="app-form-button" onClick={() => this._handleAction()}>Enviar</button>
                    </div>
                </>
                }

            </AppCardModuleBasicWrapper>
        );
    }
}

export default ReagendamentoConsultaCanceladaCovid;