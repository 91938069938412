/*
** @name: Meu Clínicas - teleatendimentoExterno
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Outubro 2022
** @description: Módulo para atendimento de teleconsulta (Chamado pelo AGHUse e futura contingência)
** 
*/

import React, { Component } from "react";
import FormBuilder from 'react-dj-forms-builder';
import { Form } from 'semantic-ui-react';

import utils from '../../core/utils.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';
import { useAppThemeContext } from '../../core/appThemeContext.js';
import { getAppServiceConfigByName } from '../../core/appThemeConfig.js';
import { ThemeMessage } from '../../core/appThemeUtils.js';
import { APP_SERVICE_LIST } from '../../core/appServiceList.js';

import AppConfirmationDialog from '../../components/general/appConfirmationDialog/appConfirmationDialog.js';
import AppExtraDocumentHead from '../../components/general/appExtraDocumentHead/appExtraDocumentHead.js';
import AppExternalServiceHeader from '../../components/general/appExternalServiceHeader/appExternalServiceHeader.js';
import AppExternalServiceInfoMessage from '../../components/general/appExternalServiceInfoMessage/appExternalServiceInfoMessage.js';
import ExternalServicesSessionLifetime from '../../components/general/externalServicesSessionLifetime/externalServicesSessionLifetime.js';
import JitsiMeetingRoom, { HANGUP_MODE } from '../../components/general/jitsiMeetingRoom/';
import { InputField } from '../../components/fields/formsBuilderCustoms';

import { ADMIN_SERVICE_LIST } from '../../apiClients/login/loginClient.js';
import teleatendimentoClient from '../../apiClients/consultas/teleatendimentoClient.js';


// Import module styles
import './scss/teleatendimentoExterno.scss'; 

// Import form configuration
import formConfigLogin from './loginForm.json';


const CREDENTIAL_EXPIRING_MS = 1200000;
const SESSION_EXPIRATION_WARNING_SEC = 300;
const ERROR_MESSAGE_CREDENTIAL = "Ops!, ocorreu um erro validando suas credenciais.";
const ERROR_MESSAGE_JITSI_CONFIG = "Ops!, ocorreu um erro obtendo as credenciais para a videochamada.";

const STEPS = {
    LOGIN: 'login',
    LISTAGEM: 'listagem',
    VIDEO: 'video'
}

const TeleatendimentoExterno = (props) => {
    const appControllerContext = useAppControllerContext();
    const themeConfig = useAppThemeContext().getConfig();
    return(
        <TeleatendimentoExternoImplem
            appControllerContext={appControllerContext}
            themeConfig={themeConfig}
            {...props}
        />
    )
}

class TeleatendimentoExternoImplem extends Component {
    constructor(props) {
        super(props);

        this.state = this._buildInitialState();
    }

    _buildInitialState = () => {
        const ec = this._readExternalParams();
        return {
            currentStep: ec ? STEPS.VIDEO : STEPS.LOGIN,
            isEmbedded: ec ? true : false,
            confirmationDialog: null,
            loginFields: null,
            loginFocus: 'usuario',
            erroProcessamento: null,
            credentials: null,
            externalParams: ec,
            conferenceConfig: null,
            closedConference: false
        };
    }

    _carregarListagem = () => {
        this._handleSessionRefresh();
        this._getListagemPaciente();
    }

    _focusFormEditField = () => {
        const { currentStep, loginFocus } = this.state;
        if(currentStep===STEPS.LOGIN) {
            if(loginFocus) {
                const l_obj = document.getElementsByName(loginFocus);
                if(l_obj.length > 0) {
                    let obj = l_obj[0];
                    obj.focus();
                }    
                this.setState({ loginFocus: false });
            }
        }
    }

    _getCurrentCredentials = () => {
        const { username, password, authenticationTime} = this.state.credentials || {};
        const isCredentialValid = username && password && authenticationTime && ((Date.now() - authenticationTime) <= CREDENTIAL_EXPIRING_MS);
        if(!isCredentialValid) {
            this.setState(this._initialState());
            return [];
        }
        return [username, password];
    }

    _getListagemPaciente = () => {
        // Obter credenciais atuais
        const [ username, password ] = this._getCurrentCredentials();
        if(!username || !password) {
            return;
        }

        // Obter token de servico e listagem
        this._setLoading(true);
        this._getServiceTokenAsync(username, password)
            .then(credentials => {
                const { jwtServiceToken, fingerprint } = credentials;
                
console.log('A IMPLEMENTAR', { jwtServiceToken, fingerprint });
                this._setLoading(false);
                








            })
            .catch(err => {
                this._setLoading(false);
                if(err===false) {
                    this._goToLoginWithErrorMessage(this.state.erroProcessamento);
                }
            });
    }
    
    async _getServiceTokenAsync(username, password) {
        return new Promise(async (resolve, reject) => {
            utils.asyncServiceToken(ADMIN_SERVICE_LIST.CONTENT_ADMIN, username, password)
            .then(res => {
                const result = res.data;
                if(!result.jwtServiceToken) {
                    this._setErroProcessamento(result.errorMessage ? result.errorMessage : ERROR_MESSAGE_CREDENTIAL);
                    reject(false);
                }

                const now = Date.now();
                const newCredentials = {
                    jwtServiceToken: result.jwtServiceToken,
                    fingerprint: result.fingerprint,
                    username: username,
                    password: password,
                    authenticationTime: now,
                    keepAliveTime: now
                }

                this.setState({ credentials: newCredentials }, () => resolve(newCredentials));
            })
            .catch(err => {
                this._setErroProcessamento(ERROR_MESSAGE_CREDENTIAL);
                reject(err);
            })
        });
    }

    _goToLoginWithErrorMessage = (message) => {
        const newState = this._buildInitialState();
        newState.erroProcessamento = message;
        this.setState(newState);
    }

    _handleLogin = () => {
        const { loginFields } = this.state;
        if(!loginFields) {
            return;
        }

        this._setLoading(true);
        this._setErroProcessamento(null);

        const username = loginFields.usuario ? loginFields.usuario.value : null;
        const password = loginFields.senha ? loginFields.senha.value : null;
        this._getServiceTokenAsync(username, password)
            .then(res => {
                this._setLoading(false);
                this.setState({ currentStep: STEPS.LISTAGEM }, () => this._carregarListagem());
            })
            .catch(err => {
                this._setLoading(false);
            })
    }

    _handleLoginFormUpdate = (fields) => {
        this.setState({ loginFields: fields });
    }

    _handleOnLoad = () => {
        this.setState({ }); // force state update to trigger render after all libs has been loaded (due to external library dependency)
    }

    _handleSessionExpired = () => {
        setTimeout(() => this._restart(), 2000);
    }

    _handleSessionRefresh = () => {
        const updCrendential = this.state.credentials;
        if(updCrendential) {
            updCrendential.keepAliveTime = Date.now();
            this.setState({ credentials: updCrendential });
        }
    }

    _handleConnectionError = (evnt) => {
        if(this._isEmbedded()) {
            this._postMessage('connectionError', evnt);
        } else {
console.log("################## Connecion Error:", evnt);


        }
    }

    _handleConferenceJoin = (jitsi, userData) => {
        if(this._isEmbedded()) {
            this._postMessage('conferenceJoin', userData);
        } else {
console.log("################## Confenrence Join:", userData);


        }
    }

    _handleParticipantJoined = (userData) => {
        if(this._isEmbedded()) {
            this._postMessage('participantJoined', userData);
        } else {
console.log("################## Participant Join:", userData);


        }
    }

    _handleParticipantLeft = (userData) => {
        if(this._isEmbedded()) {
            this._postMessage('participantLeft', userData);
        } else {
console.log("################## Participant Left:", userData);


        }
    }

    _handleToolbarButtonClick = (button) => {
        if(this._isEmbedded()) {
            this._postMessage('toolbarButtonClicked', button);
        } else {
console.log("################## Toolbar Button Clicked:", button);


        }
    }

    _handleHangup = () => {
        const { messageAfterHangup } = this.state.externalParams || {};
        if(this._isEmbedded() && !messageAfterHangup) {
            this._postMessage('hangupRequest', null);
        } else {
            const closeConfirmation = () => this.setState({ confirmationDialog: null });
            this.setState({
                confirmationDialog: {
                    onConfirm: () => { 
                        closeConfirmation();
                        this.setState({
                            closedConference: true 
                        });
                        if(messageAfterHangup) {
                            this._postMessage('afterHangup', null);
                        }
                    },
                    onCancel: closeConfirmation
                }
            });
        }
    }

    _isEmbedded = () => {
        return this.state.isEmbedded;
    }

    _isLoginEnabled = () => {
        const { loginFields } = this.state;
        return loginFields && loginFields.usuario && loginFields.usuario.value && loginFields.senha && loginFields.senha.value;
    }
    
    _postMessage = (topic, data) => {
        var message = {
            type: "MC_EVENT",
            topic: topic,
            data: data ? JSON.stringify(data) : null
        }
        
        const target = window.parent ? window.parent : window;
        target.postMessage(JSON.stringify(message), "*");
    }

    _readExternalParams = () => {
        const queryString = window.location.search;
        const sp = new URLSearchParams(queryString);
        const config = {
            fingerprint: sp.get("fp"),
            jwtServiceToken: sp.get("jwt"),
            messageAfterHangup: (sp.get("mah") && sp.get("mah")!=='0') ? true : false,
            useCustomHangup: (sp.get("uch") && sp.get("uch")!=='0') ? true : false,
            disableHangup: (sp.get("dh") && sp.get("dh")!=='0') ? true : false
        };
        return this._validadeExternalParams(config) ? config : null;
    }

    _restart = () => {
        this.setState(this._buildInitialState());
    }

    _setErroProcessamento = (msg) => {
        this.setState({ erroProcessamento: msg });
    }

    _setLoading = (visible) => {
        utils.setLoadingVisibility(this.props.appControllerContext, visible);
    }

    _startConference = () => {
        const { externalParams } = this.state;
        if(!this._validadeExternalParams(externalParams)) {
            this._setErroProcessamento("Configuração inválida/incompleta para iniciar a video chamada");
            return;
        }

        this._setLoading(true);
        teleatendimentoClient.obterJitsiTokenExternalFromToken(
            externalParams.jwtServiceToken,
            externalParams.fingerprint,
            res => {
                this._setLoading(false);
                this._setErroProcessamento(null);
                const config = res.data;
                if(!this._validateConferenceConfig(config)) {
                    this._setErroProcessamento(ERROR_MESSAGE_JITSI_CONFIG);
                } else {
                    this.setState({ conferenceConfig: config });
                }
            },
            err => {
                this._setLoading(false);
                this._setErroProcessamento(ERROR_MESSAGE_JITSI_CONFIG);
            }
        );
    }

    _showLoadingConference = (visible) => {
        this._setLoading(visible);
    }

    _validateConferenceConfig = (config) => {
        return config.domain && config.room && config.jwtToken
            && utils.isString(config.domain) && utils.isString(config.room) && utils.isString(config.jwtToken);
    }

    _validadeExternalParams = (config) => {
        return config.fingerprint && config.jwtServiceToken && utils.isString(config.fingerprint) && utils.isString(config.jwtServiceToken);
    }

    componentDidMount() {
        window.addEventListener('load', this._handleOnLoad, true);

        const { currentStep } = this.state;
        if(currentStep===STEPS.VIDEO) {
            this._startConference();
        }
    }

    componentDidUpdate(_, prevState) {
        const { currentStep, credentials } = this.state;
        if(currentStep===STEPS.LOGIN) {
            this._focusFormEditField();
        }
        if(currentStep===STEPS.LISTAGEM) {
            if (!credentials) {
                this._restart();
                return;
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener("load", this._handleOnLoad, true);
    }

    render() {
        const { currentStep, confirmationDialog, loginFields, erroProcessamento, credentials, conferenceConfig, closedConference } = this.state;
        const { disableHangup, useCustomHangup } = this.state.externalParams || {};
        const serviceConfig = getAppServiceConfigByName(this.props.themeConfig, APP_SERVICE_LIST.TELEATENDIMENTO) || {};
        const maxTimeToJoin = serviceConfig.maxTimeToJoinConferenceMs;
        const meetingBackgroundColor = serviceConfig.meetingDefaultBackgroundColor || "transparent";
        const jitsiApiLoaded = utils.isClass(window.JitsiMeetExternalAPI);
        const showConference = currentStep===STEPS.VIDEO && !erroProcessamento && conferenceConfig && jitsiApiLoaded && !closedConference;
        const hangupMode = disableHangup ? HANGUP_MODE.DISABLED : (useCustomHangup ? HANGUP_MODE.CUSTOM : HANGUP_MODE.DEFAULT);

        return(
            <div className="teleatendimento-externo-wrapper">
                <AppExtraDocumentHead subTitle="Teleatendimento" robots="noindex,nofollow" />

                { confirmationDialog &&
                <AppConfirmationDialog
                    title={"ATENÇÃO"}
                    message={<ThemeMessage messageId="teleatendimento_confirmar-hangup" />}
                    onConfirm={confirmationDialog.onConfirm}
                    onCancel={confirmationDialog.onCancel} 
                    hideCancelButton={false} />
                }

                <div className={`content${showConference ? " fill-area full-viewport" : ""}`}>
                    { !this._isEmbedded() &&
                    <div className="header-card">
                        <AppExternalServiceHeader linkToHome={false}>
                            <h1>Teleatendimento</h1>
                        </AppExternalServiceHeader>
                    </div>
                    }

                    { currentStep===STEPS.LOGIN &&
                    <div className="login-section">
                        <Form name="formMain">
                            <div className="form-wrapper">
                                <FormBuilder 
                                    config={formConfigLogin}
                                    fields={loginFields}
                                    page={0}
                                    className="form-login" 
                                    onChange={this._handleLoginFormUpdate}
                                    overrideFieldRender={{
                                        'input': InputField
                                    }}
                                />
                            </div>

                            <AppExternalServiceInfoMessage id="msgErrorID" className="info-error">
                                {erroProcessamento}
                            </AppExternalServiceInfoMessage>

                            <div className="action-section">
                                <button type="default" disabled={!this._isLoginEnabled()} className="btn-login" onClick={e => this._handleLogin(e)}>Login</button>
                            </div>
                        </Form>
                    </div>
                    }

                    { currentStep===STEPS.LISTAGEM &&
                    <div className="content-section">
                        <ExternalServicesSessionLifetime
                            sessionStartTime={credentials ? credentials.keepAliveTime : null}
                            onExpire={() => this._handleSessionExpired()}
                            onRefresh={() => this._handleSessionRefresh()}
                            sessionExpireTimeMs={CREDENTIAL_EXPIRING_MS}
                            expirationWarningSec={SESSION_EXPIRATION_WARNING_SEC}
                        />

                        <div>Funcionalidade a implementar ...</div>

                        { erroProcessamento &&
                        <AppExternalServiceInfoMessage id="msgErrorID" className="info-error">
                            {erroProcessamento}
                        </AppExternalServiceInfoMessage>
                        }

                    </div>
                    }

                    { currentStep===STEPS.VIDEO &&
                    <div className="content-section">
                        { showConference &&
                        <JitsiMeetingRoom
                            domain={conferenceConfig.domain}
                            roomId={conferenceConfig.room}
                            roomName={conferenceConfig.roomDescription}
                            jwtToken={conferenceConfig.jwtToken}
                            meetingDefaultBackgroundColor={meetingBackgroundColor}
                            hiddenWhileLoading={true}
                            disableLog={true}
                            maxTimeToJoin={maxTimeToJoin}
                            hangupMode={hangupMode}
                            autoHideCustomToolbar={serviceConfig.autoHideCustomToolbar}
                            onMissingRequirements={this._handleConnectionError}
                            onConnectionTimeout={this._handleConnectionError}
                            onConferenceJoined={this._handleConferenceJoin}
                            onParticipantJoined={this._handleParticipantJoined}
                            onParticipantLeft={this._handleParticipantLeft}
                            onToolbarButtonClick={this._handleToolbarButtonClick}
                            onConferenceLeft={() => {}} // not in use due to onHangup event
                            onHangup={this._handleHangup}
                            onUpload={null}
                            onStartLoading={() => this._showLoadingConference(true)}
                            onFinishLoading={() => this._showLoadingConference(false)}
                        />
                        }

                        { erroProcessamento &&
                        <AppExternalServiceInfoMessage id="msgErrorID" className="info-error">
                            {erroProcessamento}
                        </AppExternalServiceInfoMessage>
                        }

                    </div>
                    }
                </div>
            </div>
        )
    }
}

export default TeleatendimentoExterno;
