/*
** @name: Meu Clínicas - salaEspera
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Outubro 2022
** @description: Componente para renderizar a sala de espera do teleatendimento
*/

import React, { useState, useEffect } from 'react';

import utils from '../../core/utils.js';
import { ThemeMessage } from '../../core/appThemeUtils.js';

import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';

import ConnectionIndicator from './connectionIndicator.js';
import MidiaSalaEspera from './midiaSalaEspera.js';


const SalaEspera = (props) => {
    const { connectionInticator, tooEarly, checkinError, checkedIn, nomePaciente, informacoes, onSair, onAnexar } = props;
    const [ infoContent, setInfoContent ] = useState(null);
    const idMensagem = checkedIn ? "teleatendimento_sala-espera" : (tooEarly ? "teleatendimento_sala-espera-cedo" : null);

    useEffect(() => { // ativar item faq
        if(utils.isArray(informacoes)) {
            informacoes[0].active = true;
            setInfoContent(informacoes);
        }
    }, [informacoes]);

    return(
        <div className='sala-espera-wrapper'>

            <ConnectionIndicator visible={connectionInticator!==null} connected={connectionInticator} />

            { idMensagem &&
            <ThemeMessage messageId={idMensagem} params={[nomePaciente]} />
            }

            { infoContent &&
            <MidiaSalaEspera data={infoContent} />
            }

            { checkinError &&
            <>
                <AppMessageBox 
                    id="msg-checkin-error" 
                    className="error" 
                    messageData={{ message: checkinError }}
                />

                <div className='main-action'>
                    <button type="button" className="app-form-button" onClick={onSair}>Ok</button>
                    { onAnexar &&
                    <button type="button" className='app-form-button' onClick={onAnexar}>Anexar Arquivos</button>
                    }
                </div>
            </>
            }

        </div>
    );
}

export default SalaEspera;