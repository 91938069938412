/*
** @name: Meu Clínicas - solicitacaoAgendamentoConsultaAdm
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Outubro 2020
** @description: Módulo para os serviços externos (páginas fora do fluxo normal da aplicação)
** para gestão dos reagendamentos de consultas (exportação e notificação)
** 
** @update: Março 2021 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Atualizado para novo layout da aplicação e funcionamento com cards e agrupamento por futuras e históricas
**
** @update: Junho 2021 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Atualizando tratamento do retorno de erro para novo sistema de validacao
*/

import React, { Component } from 'react';
import FormBuilder, { setConfigFieldProperty } from 'react-dj-forms-builder';
import { Form } from 'semantic-ui-react';
import pt_BR from "date-fns/locale/pt-BR";

import utils from '../../core/utils.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';

import AppExternalServiceHeader from '../../components/general/appExternalServiceHeader/appExternalServiceHeader.js';
import AppExternalServiceInfoMessage from '../../components/general/appExternalServiceInfoMessage/appExternalServiceInfoMessage.js';
import AppExtraDocumentHead from '../../components/general/appExtraDocumentHead/appExtraDocumentHead.js';
import { CheckboxField, DateWithDatePicker, InputField, RadioGroupField } from '../../components/fields/formsBuilderCustoms/';

import formulariosClient from '../../apiClients/formularios/formulariosClient.js';
import { ADMIN_SERVICE_LIST } from '../../apiClients/login/loginClient.js';


// Import module styles
import './solicitacaoAgendamentoConsultaAdm.scss';

// Import form configuration
import formConfig from './solicitacaoAgendamentoConsultaAdm.json';


const SolicitacaoAgendamentoConsultaAdm = (props) => {
    const appControllerContext = useAppControllerContext();
    return(
        <SolicitacaoAgendamentoConsultaAdmImplem
            appControllerContext={appControllerContext}
            {...props}
        />
    )
}

class SolicitacaoAgendamentoConsultaAdmImplem extends Component {

    constructor(props) {
        super(props);

        setConfigFieldProperty(formConfig, ["dataInicial", "dataFinal", "notificarDataInicial", "notificarDataFinal"], "datePicker.locale", pt_BR);
        this.state = {
            fields: null,
            erroProcessamento: null,
            resultadoExportacao: null,
            resultadoNotificacao: null
        }
    }

    _clearFieldsError = () => {
        const updateFields = this.state.fields;
        if(utils.isObject(updateFields)) {
            Object.keys(updateFields).forEach(key => {
                updateFields[key].errorMessage = null;
            });
            this.setState({ fields: updateFields });
        }
    }

    _executaExportacao = (fields, jwtServiceToken, fingerprint) => {
        const tipoExportacao = fields.tipoExportacao ? fields.tipoExportacao.value : null;
        const filtro = {
            dataInicial: fields.dataInicial ? fields.dataInicial.value : null,
            dataFinal: fields.dataFinal ? fields.dataFinal.value : null
        }

        this._setLoading(true);
        this._clearFieldsError();
        formulariosClient.exportarSolicitacaoConsulta(
            jwtServiceToken,
            fingerprint,
            tipoExportacao,
            filtro,
            (res => {              
                const result = res.data;
                this._setLoading(false);
                this.setState({ resultadoExportacao: result });
                
                if(result.sucesso) {
                    this._setErroProcessamento(null);
                    this._resetForm();

                    if(result.csvContent) { // Download CSV
                        const data = utils.base64Encode(result.csvContent);
                        const linkSource = `data:text/plain;charset=Windows-1252;base64,${data}`;
                        const fileName = result.csvFilename ? result.csvFilename : "export.csv";

                        utils.automaticAnchorCreateAndClick(linkSource, fileName, "_blank");
                    }
                } else {
                    const validatorResponse = result.validatorResponse || {};
                    this._setErroProcessamento(validatorResponse.errors && validatorResponse.errors.erroGeral ?
                        validatorResponse.errors.erroGeral :
                        "Por favor, verifique o correto preenchimento do formulário."
                    );
                    this._processaErrosFormulario(validatorResponse.errors);
                }
            }),
            err => { 
                this._setLoading(false);
                this._setErroProcessamento("Ops!, ocorreu um erro obtendo os dados solicitados.");
            }
        );
    }

    _executaNotificacao = (fields, jwtServiceToken, fingerprint) => {
        const requestFields = {};
        Object.keys(fields).forEach((key) => {
            if(key.substr(0, 9).toLocaleLowerCase()==="notificar" && key.length>9) {
                const value = fields[key].value;
                const fieldName = key.substr(9, 1).toLocaleLowerCase() + key.substr(10);
                requestFields[fieldName] = value ? value : null;
            }
        });

        this._setLoading(true);
        this._clearFieldsError();
        formulariosClient.notificarSolicitacaoConsulta(
            jwtServiceToken,
            fingerprint,
            requestFields,
            (res => {              
                const result = res.data;
                this._setLoading(false);
                this.setState({ resultadoNotificacao: result });

                if(result.sucesso) {
                    this._setErroProcessamento(null);
                    this._resetForm();
                } else {
                    const validatorResponse = result.validatorResponse || {};
                    this._setErroProcessamento(validatorResponse.errors && validatorResponse.errors.erroGeral ?
                        validatorResponse.errors.erroGeral :
                        "Por favor, verifique o correto preenchimento do formulário."
                    );
                    this._processaErrosFormulario(validatorResponse.errors, "notificar");
                }
            }),
            err => { 
                this._setLoading(false);
                this._setErroProcessamento("Ops!, ocorreu um erro inicializado o processo de notificação.");
            }
        );
    }

    _executarRequisicao = (e) => {
        e.stopPropagation();
        e.preventDefault();

        const fields = this.state.fields;
        const tipoAcao = fields.tipoAcao.value;
        if(!fields || !tipoAcao) {
            return;
        }

        this._setLoading(true);
        this._setErroProcessamento(null);
        this.setState({ 
            resultadoExportacao: null,
            resultadoNotificacao: null
        });

        const username = fields.usuario ? fields.usuario.value : null;
        const password = fields.senha ? fields.senha.value : null;

        const service = tipoAcao==="EXPORTAR" ? ADMIN_SERVICE_LIST.SCC_EXPORT : ADMIN_SERVICE_LIST.SCC_NOTIFICATION;
        utils.asyncServiceToken(service, username, password)
            .then(res => {
                const result = res.data;
                if(!result.jwtServiceToken) {
                    this._setLoading(false);
                    this._setErroProcessamento(result.errorMessage ? result.errorMessage : "Ops!, ocorreu um erro validando suas credenciais.");
                    return;
                }

                if(tipoAcao==="EXPORTAR") {
                    this._executaExportacao(fields, result.jwtServiceToken, result.fingerprint);
                } else { // Notificar
                    this._executaNotificacao(fields, result.jwtServiceToken, result.fingerprint);
                }
            })
            .catch(err => {
                this._setLoading(false);
                this._setErroProcessamento("Ops!, ocorreu um erro validando suas credenciais.");
            })
    }

    _handleFormUpdate = (fields, name, value) => {
        const cleanFieldError = (fieldName) => {
            if(fields[fieldName]) {
                fields[fieldName].errorMessage = null;
            }
        }
        if(name==="notificarPacCodigo" && value) {
            cleanFieldError("notificarEmailPaciente");
            cleanFieldError("notificarDddTelefonePaciente");
            cleanFieldError("notificarNumeroTelefonePaciente");
        }
        this.setState({ fields: fields });
    }

    _isButtonEnabled = () => {
        const fields = this.state.fields;
        const buttonEnabled = fields && 
            fields.tipoAcao && fields.tipoAcao.value &&
            fields.usuario && fields.usuario.value && fields.senha && fields.senha.value ? true : false;

        return buttonEnabled;
    }

    _processaErrosFormulario = (erros, prefix) => {
        if(erros && erros.constructor === Object) {
            const updateFields = this.state.fields;
            Object.keys(erros).forEach((key) => {
                if(key!=="erroGeral") {
                    const fieldName = !prefix ? key : (prefix + key.substr(0, 1).toUpperCase() + key.substr(1));
                    const message = erros[key];

                    if(!updateFields[fieldName]) {
                        updateFields[fieldName] = {
                            value: null,
                            errorMessage: null
                        }
                    }
                    updateFields[fieldName].errorMessage = message;
                }
            });
            this.setState({
                fields: updateFields
            });
        }
    }

    _resetForm = () => {
        const updateFields = this.state.fields;
        const updateValue = (fieldName, value) => {
            if(utils.isObject(updateFields[fieldName])) {
                updateFields[fieldName].value = value;
                updateFields[fieldName].errorMessage = null;
            }
        }

        updateValue('tipoAcao', null);
        updateValue('tipoExportacao', "NORMAL");
        updateValue('dataInicial', null);
        updateValue('dataFinal', null);

        updateValue('notificarNotificationType', "NOTIFY_BOTH");
        updateValue('notificarPacCodigo', null);
        updateValue('notificarEmailPaciente', null);
        updateValue('notificarDddTelefonePaciente', null);
        updateValue('notificarNumeroTelefonePaciente', null);
        updateValue('notificarDataInicial', null);
        updateValue('notificarDataFinal', null);
        updateValue('notificarForcarRenotificacao', false);
        updateValue('notificarMaxNotifications', null);
        updateValue('notificarBatchSize', null);
        updateValue('notificarSleepPerBatch', null);

        updateValue('usuario', null);
        updateValue('senha', null);

        this.setState({ fields: updateFields });
    }

    _setErroProcessamento = (msg) => {
        this.setState({ erroProcessamento: msg });
    }

    _setLoading = (visible) => {
        utils.setLoadingVisibility(this.props.appControllerContext, visible);
    }

    render() {
        const fields = this.state.fields;
        const isSelectedAcao = fields && fields.tipoAcao && fields.tipoAcao.value;
        const isAcaoNotificacao = isSelectedAcao && fields.tipoAcao.value==="NOTIFICAR";
        const buttonText = isSelectedAcao ? (isAcaoNotificacao ? "Notificar" : "Exportar") : "Processar";
        const exportResult = this.state.resultadoExportacao;
        const notificationResult = this.state.resultadoNotificacao;
        const mensagemSucesso = 
            notificationResult && notificationResult.sucesso ? 
            ("Processo de notificação iniciado para um total de " + notificationResult.totalRegistros + " registro(s).") :
            (exportResult && exportResult.sucesso ? 
                "Processo de exportação de " + exportResult.totalRegistros + " registros realizado com sucesso." : 
                null);
        const mensagemInstrucao = isSelectedAcao ? 
            `Informe os dados para ${isAcaoNotificacao ? "notificação" : "exportação"} e clique em enviar.` :
            "Selecione o tipo de ação desejada, e posteriormente informe os dados para processamento.";

        return(
            <div className="solicitacao-agendamento-consulta-adm-wrapper">
                <AppExtraDocumentHead subTitle="Administração da Solicitação de Agendamento de Consulta" robots="noindex,nofollow" />

                <div className="content-wrapper">
                    <div className="content-box">
                        <Form name="formMain">
                            <div className="header-card">
                                <AppExternalServiceHeader linkToHome={false}>
                                    <h1>HCPA</h1>
                                    <h2>Solicitação de Agendamento de Consulta - Administração</h2>
                                </AppExternalServiceHeader>
                            </div>

                            <div className="primary-card">
                                <div className="section-instruction">
                                    <div className="instruction-message">{mensagemInstrucao}</div>
                                    <div className="instruction-required">*Obrigatório</div>
                                </div>

                                <div className="form-wrapper">
                                    <FormBuilder 
                                        blockFieldUpdate={false}
                                        disableClearErrorOnFieldChange={false}
                                        config={formConfig}
                                        fields={this.state.fields}
                                        page={0}
                                        className="form-export" 
                                        onChange={this._handleFormUpdate}
                                        overrideFieldRender={{
                                            'checkbox': CheckboxField,
                                            'input': InputField,
                                            'radio-group': RadioGroupField
                                        }}
                                        customComponents={{
                                            dataInicial: DateWithDatePicker,
                                            dataFinal: DateWithDatePicker,
                                            notificarDataInicial: DateWithDatePicker,
                                            notificarDataFinal: DateWithDatePicker
                                        }}
                                    />
                                </div>
                            </div>

                            <AppExternalServiceInfoMessage id="msgErrorID" className="info-error">
                                {this.state.erroProcessamento}
                            </AppExternalServiceInfoMessage>

                            <AppExternalServiceInfoMessage id="msgSuccessID" className="info-success">
                                {mensagemSucesso}
                            </AppExternalServiceInfoMessage>

                            <div className="action-section">
                                <button type="default" className="btn-export" disabled={!this._isButtonEnabled()} onClick={(e) => this._executarRequisicao(e)}>{buttonText}</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }

} export default SolicitacaoAgendamentoConsultaAdm;