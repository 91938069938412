/*
** @name: Meu Clínicas - consultas
** @author: 
** @date:
** @description: Módulo para visualização das consultas do paciente
** 
** @update: Março 2021 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Atualizado para novo layout da aplicação e funcionamento com cards e agrupamento por futuras e históricas
**
** @update: Setembro 2022 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Refatoramento dos cards para componente separado e ligação para teleatendimento
*/

import React, { Component } from 'react';
import { Accordion } from 'semantic-ui-react';
import moment from 'moment';
import 'moment-timezone';

import utils from '../../core/utils.js';
import sessionStorageManager from '../../core/sessionStorageManager.js';
import specialAccessManager, { PERMISSIONS } from '../../core/specialAccessManager.js';
import { useAuthContext } from '../../core/authContext.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';
import { useAppThemeContext } from '../../core/appThemeContext.js';
import { getAppServiceConfigByName } from '../../core/appThemeConfig.js';
import { ThemeMessage } from '../../core/appThemeUtils.js';
import { APP_SERVICE_LIST } from '../../core/appServiceList.js';
import { hasServicePermission } from '../../components/general/appNavigationControls/appNavigationControls.js';

import AppCardModuleBasicWrapper from '../../components/general/appCardModuleBasicWrapper/appCardModuleBasicWrapper.js';
import AppDateFilter from '../../components/general/appDateFilter/appDateFilter.js';
import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';
import AppMessageDialog from '../../components/general/appMessageDialog/appMessageDialog.js';
import { AccordionCollapsible } from '../../components/general/appNavigationControls/appNavigationControls.js';

import consultasClient from '../../apiClients/consultas/consultasClient.js';

import CardConsulta from './cardConsulta.js';
import { criarEventosConsultas } from './eventosConsultas';


// Import module styles
import './scss/consultas.scss'; 


const Consultas = (props) => {
    const authContext = useAuthContext();
    const appControllerContext = useAppControllerContext();
    const themeConfig = useAppThemeContext().getConfig();
    return(
        <ConsultasImplem
            authContext={authContext}
            appControllerContext={appControllerContext}
            themeConfig={themeConfig}
            {...props}
        />
    )
}

class ConsultasImplem extends Component {
    constructor(props) {
        super(props);

        const user = props.authContext.properties.user;
        const acessoReagendamentoCOVID = specialAccessManager.permissions.isAllowed(user, PERMISSIONS.REAGENDAMENTO_CONSULTA_COVID);
        this.state = {
            isLoaded: false,
            acessoReagendamentoCOVID,
            isFiltered: false,
            filterError: null,
            listingParameters: {},
            searchResult : [],
            searchErrorMessage: null,
            searchInfoMessage: null,
            historicoActive: false,
            exibeMensagemTele: null
        };
    }

    _buildFilename = (consulta) => {
        const { numero, dataConsulta, horaConsulta } = consulta;

        return `consulta_${numero}_${dataConsulta.replace(/\D/g, '')}_${horaConsulta.replace(/\D/g, '')}`;
    } 

    _exibirMensagemTele = (listConsulta) => {
        const { hideInformativoTeleatendimento } = getAppServiceConfigByName(this.props.themeConfig, APP_SERVICE_LIST.CONSULTAS) || {};
        const dataBase = this._getDateBeginOfDay();
        const existeTeleFutura = utils.isArray(listConsulta) && listConsulta.find(cons => !cons.dthrEstorno && cons.atendimentoRemoto && !this._isConsultaHistorico(cons, dataBase)) ? true : false;
        return this._isModuleVisible() && existeTeleFutura && !hideInformativoTeleatendimento;
    }

    _extractGruposConsulta = () => {
        const consultasAtuais = [];
        const consultasHistorico = [];
        const dataBase = this._getDateBeginOfDay();

        this.state.searchResult.forEach((consulta, index) => {
            if(this._isConsultaHistorico(consulta, dataBase)) {
                consulta.dataHoraEvento = null;
                consultasHistorico.push(consulta);
            } else {
                // atualizar data e hora do evento (para adicioanr ao calendário)
                consulta.dataHoraEvento = this._obterDataHoraConsulta(consulta);
                consultasAtuais.push(consulta);
            }
        });

        return({
            consultasAtuais, 
            consultasHistorico 
        });
    }

    _getDateBeginOfDay = () => {
        return moment().startOf('day').toDate();
    }

    _getListaConsultas = (params) => {
        const filtro = (params && params.filtro) || {};
        const filterError = params && params.filterError;

        this.setState({ filterError });
        if (filterError) {
            return;
        }        

        this._setLoading(true);
        this.setState({
            searchErrorMessage: null,
            searchInfoMessage: null,
            historicoActive: false
        });

        const pacCodigo = this.props.authContext.properties.user.pacCodigo;
        consultasClient.buscarListaConsultas(
            pacCodigo,
            filtro.dtInicial, 
            filtro.dtFinal,
            res => {
                this._setLoading(false);
                const isFiltered = (filtro.dtInicial || filtro.dtFinal) ? true : false;
                const parameters = res.data && utils.isObject(res.data.parameters) ? res.data.parameters : {};
                const listConsulta = res.data && utils.isArray(res.data.consultas) ? res.data.consultas : [];
                const searchInfoMessage = listConsulta.length > 0 ? null :
                    (!isFiltered && utils.isUserInSyncAwayTime() ? <ThemeMessage messageId="_general_mensagem-possivel-sincronismo-pendente" /> : "Nenhuma consulta encontrada");
                const mensagemTele = this._exibirMensagemTele(listConsulta);
                this.setState({
                    isFiltered: isFiltered,
                    listingParameters: parameters,
                    searchResult: listConsulta,
                    searchInfoMessage: searchInfoMessage,
                    exibeMensagemTele: this.state.exibeMensagemTele===false ? false : mensagemTele
                });
            },
            err => {                      
                this._setLoading(false);
                this.setState({
                    listingParameters: {},
                    searchResult: [], 
                    searchErrorMessage: 'Ocorreu um erro ao processar sua requisição'
                });
            }
        );   
    }

    _getPDFTicket = (consulta) => {
        this._setLoading(true);

        const user = this.props.authContext.properties.user;
        consultasClient.buscarPDFTicketConsulta(
            user.pacCodigo,
            consulta.numero,
            res => { 
                this._setLoading(false);
                this._updateDownloadError(consulta, null);
                const base64 = res.data.pdfBase64;
                const rnIntegration = window.rnIntegration;
                if(!rnIntegration || !rnIntegration.triggerAppPDFViewer(base64)) {
                    const fileName = this._buildFilename(consulta) + ".pdf";
                    utils.automaticDownloadData(base64, fileName);
                }
            },
            err => {
                this._setLoading(false);
                this._updateDownloadError(consulta, "Erro obtendo dados do comprovante."); 
            }
        ); 
    }

    _handleAccordionClick = (e) => {
        this.setState({
            historicoActive: !this.state.historicoActive
        });
    }

    _handleAddCalendar = (consulta) => {
        // criar arquivo ICS para calendário com eventos para as consultas
        const serviceConfig = getAppServiceConfigByName(this.props.themeConfig, APP_SERVICE_LIST.CONSULTAS);
        const calendarConfig = serviceConfig ? serviceConfig.calendar : null;
        const { calendar, calData } = criarEventosConsultas(calendarConfig, consulta);
        const icsData = calendar ? calendar.calendar() : null;
        if(icsData && calData) {
            // download calendário
            const fileName = `agendamento_consulta_${moment(consulta.dataHoraEvento).format("DD-MM-YYYY")}`;
            const rnIntegration = window.rnIntegration;
            if(!rnIntegration || !rnIntegration.triggerAppCalendarAdd(calData, icsData, fileName)) {
                calendar.download(fileName);
            }
        }
    }

    _handleClearFilter = () => {
        const { isFiltered } = this.state;
        this.setState({
            isFiltered: false,
            filterError: null,
            searchErrorMessage: null,
            searchInfoMessage: null
        });

        if(isFiltered) {
            this._getListaConsultas();
        }
    }

    _handleCloseDialog = () => {
        this.setState({ exibeMensagemTele: false });
    }

    _handleIniciarTeleatendimento = (consulta) => {
        const params = {
            numeroConsulta: consulta.numero
        }
        this.props.appControllerContext.methods.doAddCardModule(APP_SERVICE_LIST.TELEATENDIMENTO, params, false, this.state.listingParameters);
    }

    _handleOnLoad = () => {
        this.setState({ }); // force state update to trigger render after all libs has been loaded (due to external library dependency)
    }

    _handleUpdateFilter = () => {
        this.setState({ 
            filterError: null,
            searchErrorMessage: null,
            searchInfoMessage: null
        });
    }

    _isConsultaHistorico = (consulta, dataBase) => {
        // Consulta posteriores à data base (padrão anterior ao dia de hoje)
        const today = dataBase ? dataBase : this._getDateBeginOfDay();
        const dataConsulta = this._obterDataHoraConsulta(consulta);
        return today.getTime() > dataConsulta.getTime();
    }

    _isModuleVisible = () => {
        const { moduleName } = sessionStorageManager.navigation.getCurrentCardModule()
        return moduleName===APP_SERVICE_LIST.CONSULTAS;
    }

    _isTeleatendimentoDeviceEnabled = () => {
        const rnIntegration = window.rnIntegration;
        return (!rnIntegration || !rnIntegration.isAppRunning() || rnIntegration.getVideoConferenceEnabled());
    }

    _obterDataHoraConsulta = (consulta) => {
        // Substituido pelo parsin da string ate resolver o problema do TZ no banco de prod. do AGHUse (afeta conversao no sincronismo)
        //return consulta.dthrConsulta ? new Date(consulta.dthrConsulta) : null;

        const { dataConsulta, horaConsulta } = consulta;
        let dataHora = utils.stringToDateBR(dataConsulta);
        if(dataHora && /^\d{2}:\d{2}$/.test(horaConsulta)) {
            const hourParts = horaConsulta.split(":");
            dataHora.setHours(hourParts[0], hourParts[1], 0, 0);
            return dataHora;
        }

        return null;
    }

    _updateDownloadError = (consulta, error) => {
        consulta.downloadError = error;
        this.setState(this.state);
    }

    _setLoading = (visible) => {
        utils.setLoadingVisibility(this.props.appControllerContext, visible);
    }

    componentDidMount() {
        window.addEventListener('load', this._handleOnLoad, true);
        this._getListaConsultas();
    }

    componentWillUnmount() {
        window.removeEventListener("load", this._handleOnLoad, true);
    }

    render() {
        const serviceConfig = getAppServiceConfigByName(this.props.themeConfig, APP_SERVICE_LIST.CONSULTAS);
        const { isLoaded, acessoReagendamentoCOVID, exibeMensagemTele, listingParameters } = this.state;
        const { hideAddCalendar, hideDownloadTicket, hideTeleatendimento } = serviceConfig || {};
        const { consultasAtuais, consultasHistorico } = this._extractGruposConsulta();
        const user = this.props.authContext.properties.user;
        const userAllowedTeleatendimento = hasServicePermission(user, APP_SERVICE_LIST.TELEATENDIMENTO, listingParameters);
        const deviceTeleatendimentoEnabled = this._isTeleatendimentoDeviceEnabled();
        const disableTeleatendimento = !deviceTeleatendimentoEnabled;

        return(
            <AppCardModuleBasicWrapper moduleName="consultas">

                { exibeMensagemTele && 
                <AppMessageDialog
                    wrapper={true}
                    wrapperClassName="iformation-dlg-wrapper"
                    header={ 
                        <div className="dlg-title">
                            Atenção
                            <div className="btn-close-wrapper" onClick={this._handleCloseDialog}>
                                <div className="close"></div>
                            </div>
                        </div>
                    }
                    ignoreEsc={false}
                    className="iformation-dlg-box"
                    message={
                        <ThemeMessage 
                            messageId="consulta-mensagem"
                            params={[
                                <ThemeMessage messageId="consulta-mensagem-teleatendimento" />,
                                userAllowedTeleatendimento ? "" : <ThemeMessage elemType="span" className="warning" messageId="consulta-mensagem-teleatendimento-cadastro" />,
                                (!userAllowedTeleatendimento || deviceTeleatendimentoEnabled) ? "" : <ThemeMessage elemType="span" className="warning" messageId="consulta-mensagem-teleatendimento-dispositivo" />
                            ]}
                        />
                    }
                    messageClass="message"
                    buttonClass="app-compact-button"
                    buttonOneText="Ok"
                    buttonOneClick={this._handleCloseDialog}
                />
                }

                { acessoReagendamentoCOVID &&
                <AppMessageBox 
                    id="msg-consultas-information" 
                    className="information aviso-remarcacao"
                    messageData={{
                        header: <strong>Atenção:</strong>,
                        message: <ThemeMessage messageId="consulta-reagendamento-consulta-covid" />
                    }}
                />
                }

                <AppDateFilter
                    filterError={this.state.filterError}
                    onFilterClear={this._handleClearFilter}
                    onFilterUpdate={this._handleUpdateFilter}
                    onFilter={this._getListaConsultas}
                />

                {this.state.searchErrorMessage &&
                <AppMessageBox 
                    id="msg-consultas-error" 
                    className="error" 
                    messageData={{ message: this.state.searchErrorMessage }} />
                }

                {this.state.searchInfoMessage &&
                <AppMessageBox 
                    id="msg-consultas-information" 
                    className="information" 
                    messageData={{ message: this.state.searchInfoMessage }} />
                }

                <div className="consultas-section">

                    { consultasAtuais.length>0 &&
                        <div className="consultas-atuais">
                            <CardConsulta
                                baseKey={`atuais_${isLoaded ? "loaded" : "loading"}`}
                                consultas={consultasAtuais}
                                disableTeleatendimentoButton={disableTeleatendimento}
                                hideAddToCalendarButton={hideAddCalendar}
                                hideTicketButton={hideDownloadTicket}
                                hideTeleatendimentoButton={hideTeleatendimento}
                                userAllowedTeleatendimento={userAllowedTeleatendimento}
                                onAddToCalendar={this._handleAddCalendar.bind(this)}
                                onShowTicketPdf={this._getPDFTicket.bind(this)}
                                onOpenTeleatendimento={this._handleIniciarTeleatendimento.bind(this)}
                            />
                        </div>
                    }

                    { consultasHistorico.length>0 &&
                        <div className="consultas-historico">
                            <Accordion fluid styled>
                                <div className={`accordion-item${this.state.historicoActive ? " active" : ""}`}>
                                    <Accordion.Title onClick={(e) => this._handleAccordionClick(e)}>
                                        <div className="title-info-wrapper">
                                            <div className="information">Histórico</div>
                                        </div>
                                        <div className="collapsible-wrapper">
                                            <AccordionCollapsible active={this.state.historicoActive} iconVariant={null} />
                                        </div>
                                    </Accordion.Title>
                                    <Accordion.Content>
                                        <CardConsulta
                                            baseKey="hist"
                                            consultas={consultasHistorico}
                                            hideAddToCalendarButton={true}
                                            hideTicketButton={true}
                                            hideTeleatendimentoButton={true}
                                            onAddToCalendar={this._handleAddCalendar.bind(this)}
                                            onShowTicketPdf={this._getPDFTicket.bind(this)}
                                        />
                                    </Accordion.Content>
                                </div>
                            </Accordion>
                        </div>
                    }

                    <div className="padding-rodape"></div>

                </div>

            </AppCardModuleBasicWrapper>
        );
    }
}

export default Consultas;
