import React from 'react';
import { Checkbox } from 'semantic-ui-react';

import utils from '../../../core/utils.js';


const CheckboxField = (props) => {
    const { id, name, label, disabled, onToggle, checked, errorMessage, children, renderChild, hideChildren } = props;
    const checkboxClassName = (disabled ? "disabled" : "");

    return(
        <>
            <Checkbox
                id={id}
                name={name}
                checked={checked}
                disabled={disabled}
                label={label}
                className={checkboxClassName}
                onChange={(e) => onToggle(e, {name: name, value: !checked})} />
            { errorMessage &&
            <div className="field-error">{errorMessage}</div>
            }

            { (!(hideChildren && !checked) && utils.isArray(children)) &&
                children.map((child, indxChild) => renderChild(child, indxChild))
            }

        </>
    )
}

export default CheckboxField;