import requestAuth from "../../core/requestAuth";
import requestNoAuth from "../../core/requestNoAuth";

import { apiUrl } from "../../core/apiUrl.js";
import { API_PORT, API_SERVICE, API_VERSION } from './loginClient.js';


class LgpdIntegrationClient {

    obterTermo(dataReferencia, forceRequest, successCallback, errorCallback) {
        const extraPath = dataReferencia ? `/data-referencia/${dataReferencia}` : (forceRequest ? '/forcar-nova-requisicao' : "");
        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, `/public/lgpd/obter-termo${extraPath}`),
            successCallback,
            errorCallback,
            {}
        );
    }

    obterConsentimento(pacCodigo, successCallback, errorCallback) {
        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, `/lgpd/obter-consentimento/${pacCodigo}`),
            successCallback,
            errorCallback,
            {}
        );
    }

    gravarConsentimento(pacCodigo, resposta, successCallback, errorCallback) {
        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, `/lgpd/gravar-consentimento/${pacCodigo}/${resposta}`),
            successCallback,
            errorCallback,
            {}
        );
    }

}

const lgpdIntegrationClient = new LgpdIntegrationClient();
export default lgpdIntegrationClient;
