import React from "react";

import { ThemeImage } from '../../../core/appThemeUtils.js';


const HangupButton = (props) => {
    const { onClick } = props;
    return(
        <div className="ctb-button button-hangup" onClick={onClick}>
            <div className="icon-wrapper">
                <ThemeImage className="svg-invert" module="teleatendimento" imageId="icon_hangup" />
            </div>
        </div>
    );
}

const UploadButton = (props) => {
    const { onClick } = props;
    return(
        <div className="ctb-button button-upload" onClick={onClick}>
            <div className="icon-wrapper">
                <ThemeImage className="svg-invert" module="teleatendimento" imageId="icon_upload" />
            </div>
        </div>
    );
}

export {
    HangupButton,
    UploadButton
};