/*
** @name: Meu Clínicas - appConfiguration
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Janeiro 2022
** @description: Componente que gera arquivo de configuracao da aplicação (aplicando o tema as configuracoes basicas)
*/

// Import basic and theme configuration files
import basicConfigFile from '../configs/basicConfig.json';
import themeConfigFile from '../theme/themeConfig.json';


const applySourceToTarget = (target, source) => {
    const isObject = obj => obj && (obj instanceof Object);
    if(!isObject(target) || !isObject(source)) {
        return source;
    }
  
    Object.keys(source).forEach(key => {
        const srcValue = source[key];
        const tgtValue = target[key];

        if(source.hasOwnProperty(key) && srcValue !=null && tgtValue != null 
            && ((typeof srcValue !== typeof tgtValue) || (Array.isArray(srcValue) !== Array.isArray(tgtValue))) ) {
            throw new Error(`Incompatible property [${key}] type merging theme configuration.`);
        }

        if(Array.isArray(tgtValue) && Array.isArray(srcValue)) {
            target[key] = srcValue;
        } else if(isObject(tgtValue) && isObject(srcValue)) {
            target[key] = applySourceToTarget(Object.assign({}, tgtValue), srcValue);
        } else {
            target[key] = srcValue;
        }
    });
  
    return target;
}

const getApplicationConfiguration = () => {
    /* Realiza um 'merge' entre a configuração básica da aplicação aplicando o tema atual, tendo como base a configuração 
    ** da aplicação. Propriedades que existam no thema e na config básica devem obrigatoriamente ser do mesmo tipo ou nula.
    **
    ** ATENCAO: Arrays não sofrem 'merge' entre si, são apenas substituidos quando exitir na config do tema
    */
    
    const applConfig = { ...basicConfigFile };
    applySourceToTarget(applConfig, themeConfigFile);

    return applConfig;
}

export {
    getApplicationConfiguration
}