import React from "react";

import utils from '../../core/utils.js';
import { ThemeImage } from '../../core/appThemeUtils.js';


const GridIcon = (props) => {
    const { visible, className, title, imageId, onClick } = props;
    if(!visible) {
        return null;
    }
    const clickFunc = utils.isFunction(onClick) ? onClick : () => {};
    return(
        <div className={className} title={title} onClick={clickFunc}>
            <ThemeImage module="faq-manager" imageId={imageId} onErrorReturn="N/A" />
        </div>
    );
}

export default GridIcon;