/*
** @name: Meu Clínicas - utils
** @author:
** @date:
** @description: Conjunto de rotinas utilitárias para uso geral na aplicação
** @update: Dezembro 2020 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Ampliado com 20 novas rotinas de uso geral (Token, Scrollbars, Loading, Card, App, Fromatação, etc...)
*/

import $ from 'jquery';
import moment from 'moment';
import { Buffer } from 'buffer';
import { isMobile, isTablet, isIOS, isAndroid } from 'react-device-detect';

import sessionStorageManager from './sessionStorageManager.js';

import loginClient from '../apiClients/login/loginClient.js';


const AUTOMATIC_CLOSE_TIMEOUT = 5000;
const EMPTY_HREF = "#";
const MAX_SYNC_TIME_MS = 900000; // 15 minutos
const MOBILE_INDICATOR_SELECTOR = "#root .app-viewport.mobile";

class Utils {
    async asyncMediaDevices(filter) {
        return new Promise(async resolve => {
            if (!navigator || !navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
                console.error("Unable to get user's device list");
                resolve(null);
            } else {
                navigator.mediaDevices.enumerateDevices()
                    .then(deviceList => {
                        if(!this.isObject(filter)) {
                            resolve(deviceList);
                        } else {
                            const mediaDevices = [];
                            deviceList.forEach(device => {
                                if(!Object.keys(filter).find(f => device[f]!==undefined && filter[f]!==device[f])) {
                                    mediaDevices.push(device);
                                }
                            });
                            resolve(mediaDevices);
                        }
                    })
                    .catch((err) => {
                        console.error(`Error getting user's device list: [${err.name}] ${err.message}`);
                        resolve(null);
                    });
            }
        });
    }

    async asyncMediaDevicePresent() {
        return new Promise(async resolve => {
            const videoInputDevices = await this.asyncMediaDevices({ kind: 'videoinput' });
            const audioInputDevices = await this.asyncMediaDevices({ kind: 'audioinput' });
            const audioOutputDevices = await this.asyncMediaDevices({ kind: 'audiooutput' });
            resolve({
                camera: videoInputDevices && videoInputDevices.length > 0 ? true : false,
                microphone: audioInputDevices && audioInputDevices.length > 0 ? true : false,
                speeker: audioOutputDevices && audioOutputDevices.length > 0 ? true : false
            });    
        });
    }
    
    async asyncMediaStream(constraints) {
        return new Promise(async resolve => {
            if(!navigator || !navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
                resolve(null);
                console.error('unable to request user media');
            }
    
            navigator.mediaDevices.getUserMedia(constraints)
                .then(stream => {
                    resolve(stream);
                })
                .catch(err => {
                    resolve(null);
                    console.error(`error requesting user media: ${err}`);
                })
        });
    }
    
    async asyncServiceToken(service, username, password) {
        return new Promise(async (resolve, reject) => {
            const uuidv4 = require('uuid/v4');
            const fingerprint = uuidv4();
            loginClient.serviceToken(
                service, 
                fingerprint, 
                username, 
                password,
                res => { 
                    const result = res;
                    result.data.fingerprint = fingerprint;
                    resolve(result);
                },
                err => { reject(err); }
            );
        });
    }

    async asyncTokenRefresh(pacCodigo, extendedLifetime) {
        return new Promise(async (resolve, reject) => {
            loginClient.refreshToken(
                pacCodigo,
                extendedLifetime ? true : false, 
                res => { resolve(res); },
                err => { reject(err); }
            );
        });
    }

    async asyncCopyTextToClipboard(text) {
        const fallbackCopyTextToClipboard = (txt) => {
            try {
                const textArea = document.createElement('textarea');
                textArea.innerText = txt;

                document.body.appendChild(textArea);
                textArea.select();
                const successful = document.execCommand('copy');
                document.body.removeChild(textArea);

                return successful;
              } catch (err) {
                return err;
              }            
        }

        return new Promise(async (resolve, reject) => {
            if(navigator && navigator.clipboard && navigator.clipboard.writeText) { // Only in some browsers and requires a secure origin (localhost or https)
                navigator.clipboard.writeText(text)
                    .then(() => { resolve(true) })
                    .catch(err => { console.error('Clipboard error: ', err.message); reject(err) });
            } else { // Deprecated
                const res = fallbackCopyTextToClipboard(text);
                if(res === true) {
                    resolve(true);
                } else {
                    reject(res);
                }
            }
        });
    }

    automaticAnchorCreateAndClick = (source, downloadName, target) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = source;
        if(downloadName) {
            downloadLink.download = downloadName;
        }
        if(target) {
            downloadLink.target = target;
        }

        document.body.appendChild(downloadLink);
        downloadLink.click();  
        document.body.removeChild(downloadLink);
    }

    automaticDownloadData = (base64, downloadName, contentType='application/pdf') => {
        if(!base64 || !downloadName) {
            return;
        }

        const blob = this.base64toBlob(base64, contentType, 1024);
        const url = URL.createObjectURL(blob);

        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = downloadName;

        document.body.appendChild(downloadLink);
        downloadLink.click();

        setTimeout(() => { // For Firefox it is necessary to delay revoking the ObjectURL
            document.body.removeChild(downloadLink);
            window.URL.revokeObjectURL(url);
        }, 100);
    }

    base64Encode = (data, encode='ascii') => {
        return Buffer.from(data, encode).toString('base64');
    }

    base64Decode = (data, encode='binary') => {
        return Buffer.from(data, 'base64').toString(encode);
    }

    base64toBlob = (base64Data, contentType='', sliceSize=1024) => {
        const binData = this.base64Decode(base64Data);
        const byteArrays = [];
        for (let offset=0; offset<binData.length; offset+=sliceSize) {
            const slice = binData.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const returnBlob = new Blob(byteArrays, {type: contentType});
        return returnBlob;
    }

    buildEpoch = () => {
        const m = process.env.REACT_APP_BUILD_MOMENT;
        if(m && m.match(/^\d+$/)) {
            return parseInt(m);
        } else if(this.isDev()) {
            return new Date().getTime();
        }
        return null;
    }

    calculateHash32 = (input) => {
        if(!this.isString(input)) {
            return null;
        }

        var hash = 0;
        for(let i=0; i<input.length; i++) {
            const chr = input.charCodeAt(i);
            hash = ((hash << 5)-hash) + chr;  // same as (hash * 31 + char) but faster
            hash |= 0; // convert to 32bit integer
        }
        if(hash<0) {
            hash = hash + Math.pow(2, 32);
        }
        hash = hash.toString(16).toLowerCase();
        return hash;
    }

    calculateDateDiff = (initialDate, finalDate, period='days') => {
        const initial = moment(initialDate, 'DD/MM/YYYY');
        const final = moment(finalDate, 'DD/MM/YYYY');

        return final.diff(initial, period, true);
    }

    checkAppVersion(appVersion, requiredVersion) {
        const versionParts = (version) => {
            if(version) {
                const noRC = version.replace(/-RC$/, "");
                const reParts = /^(\d+)\.(\d+)\.(\d+)$/;
                if(noRC.match(reParts)) {
                    const major = parseInt(noRC.replace(reParts, "$1"));
                    const minor = parseInt(noRC.replace(reParts, "$2"));
                    const revision = parseInt(noRC.replace(reParts, "$3"));
                    return ({ major, minor, revision });
                }
            }
            return null;
        }

        const rvParts = versionParts(requiredVersion);
        const cvParts = versionParts(appVersion);
        if(!cvParts || !rvParts) {
            return null;
        }

        // check major version
        if(cvParts.major > rvParts.major) { return true; }
        if(cvParts.major < rvParts.major) { return false; }

        // compare minor version (if major is the same)
        if(cvParts.minor > rvParts.minor) { return true; }
        if(cvParts.minor < rvParts.minor) { return false; }

        // compare revision version (if major and minor are the same)
        if(cvParts.revision >= rvParts.revision) { return true; }
        
        return false;
    }

    convertDateToEpochAtMidday = (date) => {
        let epoch = /^\d{2}\/\d{2}\/\d{4}$/.test(date) ? moment(date + "12:00:00", 'DD/MM/YYYY hh:mm:ss').valueOf() : null;
        return !isNaN(epoch) ? epoch : null;
    }

    checkRequiredFunctionProperty(prop, name, module) {
        if(!this.isFunction(prop)) {
            throw new Error(`Missing or invalid '${name}' property for '${module}'.`);
        }
    }

    elementVisibility = (container, elem) => {
        if(!container || !elem) {
            return null;
        }
    
        const ch = $(container).height(); // container height
        const yt = $(elem).offset().top - $(container).offset().top; // element top vertical position (relative to container)
        const eh = $(elem).height(); // element height
        const yb = yt + eh; // element bottom vertical position (relative to container)
        const pa = yt < 0 ? Math.abs(yt) : 0; /* pixels above container view */
        const pb = yb > ch ? yb - ch : 0; /* pixels below container view */

        return Math.max(0, (eh-pa-pb)) / eh;
    }    

    formatGuidProtocol = (key) => {
        if(!key) {
            return null;
        }
        while(`${key}`.length < 24) {
            key = "0" + key;
        }
        return key.match(/.{1,6}/g).join("-");
    }

    formatProntuario = (prontuario) => {
        return prontuario ? prontuario.slice(0, (prontuario.length-1)) + '/' + prontuario.slice(-1) : null;
    }

    getPublicPath = slashAsEmpty  => {
        const appPublicUrl = String(process.env.PUBLIC_URL || "").replace(/\/+$/g, '') || "/";
        return (slashAsEmpty && appPublicUrl === '/') ? "" : appPublicUrl;
        }

    getScrollbarWidth() {
        const inner = document.createElement("p");
        inner.style.width = "100%";
        inner.style.height = "200px";

        const outer = document.createElement("div");
        outer.className = "scrollbar-measure-box";
        outer.appendChild(inner);

        document.body.appendChild(outer); 

        const widthInner = inner.offsetWidth;
        outer.style.overflow = "scroll";
        var widthOuter = inner.offsetWidth;
        if (widthInner === widthOuter) {
            widthOuter = outer.clientWidth;
        }

        document.body.removeChild(outer);

        return (widthInner - widthOuter);
    }

    goToHome() {
        window.location = process.env.REACT_APP_LOGIN_ADDRESS;
    }

    scrollAppCardModuleContentTo = (top) => {
        const moduleContent = document.getElementById("appCardModuleContentId");
        if(moduleContent) {
            moduleContent.scrollTo(0, (top ? top : 0));
        }
    }

    setAutomaticCardClose(appControllerContext, moduleName, timeout) {
        if(appControllerContext) {
            timeout = this.isInteger(timeout) && timeout > 0 ? timeout : AUTOMATIC_CLOSE_TIMEOUT;
            return setTimeout(() => appControllerContext.methods.doCardFadeOut(moduleName), timeout);
        }
    }

    setLoadingVisibility(appControllerContext, visible) {
        if(appControllerContext && appControllerContext.methods) {
            if(visible) {
                appControllerContext.methods.doShowLoading();
            } else {
                appControllerContext.methods.doHideLoading();
            }
        }
    }

    setViewPortRescale = (enable) => {
        let vpl = document.getElementsByName('viewport');
        var vp = (vpl.length===1) ? vpl[0] : null;

        if(vp) {
            if(enable) {
                vp.setAttribute('content', 'width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=3, user-scalable=yes');
            } else {
                vp.setAttribute('content', 'width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1, user-scalable=no');
            }
        }

    }

    startResizeMonitor(elem, fn) {
        $(window)
            .on("load", fn)
            .on("resize", fn);

        if(elem) {
            try {
                const observerCallback = (entries) => {
                    window.requestAnimationFrame(() => {
                      if (!this.isArray(entries) || !entries.length) {
                        return;
                      }
                      fn();
                    });
                };                
                const observer = new ResizeObserver(observerCallback);
                observer.observe($(elem).get(0));
                return observer;
            } catch(e) {
                // Avoid errors on unsupported browsers and trigger one time 'fn' call on a timeout basis
                setTimeout(() => fn(), 10);
            }
        }

        return null;
    }

    stopResizeMonitor(monitor, fn) {
        $(window).off("load", fn);
        $(window).off("resize", fn);
        if(monitor instanceof ResizeObserver) {
            monitor.disconnect();
        }
    }

    stringToDateBR = (strDate, defaultValue) => {
        if(/^\d{2}\/\d{2}\/\d{4}$/.test(strDate)) {
            var dateParts = strDate.split("/");
            return new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);    
        }
        return defaultValue ? defaultValue : null;
    }

    updateScrollableArea(container, content) {
        if(container && content) {
            const isMobileView = $(MOBILE_INDICATOR_SELECTOR).length>0 ? true : false;
            const isScrollActive = !isMobileView && this.isScrollable(container);
            const marginRight = isScrollActive ? `-${Math.min(10, this.getScrollbarWidth())}px` : "0";
            $(content).css({ "margin-right": marginRight });
        }
    }

    // === JWT Token
    jwtParsePayload(token) {
        const parts = this.isString(token) ? token.split('.') : [];
        try {
            return parts.length===3 ? JSON.parse(this.base64Decode(parts[1])) : null;
        } catch(e) {
            return null;
        }
    }

    jwtGetExpiration(token) {
        const { exp } = this.jwtParsePayload(token) || {};
        return this.isInteger(exp) ? (exp * 1000) : null;
    }

    jwtGetIssueTime(token) {
        const { iat } = this.jwtParsePayload(token) || {};
        return this.isInteger(iat) ? (iat * 1000) : null;
    }

    // === Arrays
    arrayEllipsis(arr, maxSize) {
        if(!this.isArray(arr) || !this.isInteger(maxSize)) {
            return arr;
        }
        const result = arr.slice(0, maxSize);
        if(arr.length > maxSize) {
            result.push("...");
        }
        return result;
    }

    inArray(needle, haystack) {
        if(this.isArray(haystack)) {
            for(var i=0; i<haystack.length; i++) {
                if(haystack[i] === needle) {
                    return true;
                }
            }
        }
        return false;
    }

    // === General check
    isHttpsProtocol() {
        return window && window.location && window.location.protocol==="https:";
    }

    isScrollable(elem) {
        return elem && elem.scrollHeight > elem.clientHeight;
    }

    isUserInSyncAwayTime = (userData) => {
        const user = userData ? userData : sessionStorageManager.auth.getUserData();
        const localCriadoEm = (user.criadoEm || 0) + (user.localClockDifference || 0);
        const elapsedTime = (Date.now() - localCriadoEm);

        return (elapsedTime <= MAX_SYNC_TIME_MS);
    }

    isValidDate(strData) {     
        if (!strData) {
            return true;
        }

        var strDataSemMascara = strData.replace(/_/g, '', true);
        if (strDataSemMascara.length !== 10) {
            return false;
        }

        return moment(strData, 'DD/MM/YYYY').isValid();
    }

    // === Variable Type check
    isArray(arr) {
        return arr && arr.constructor===Array;
    }

    isFunction(func) {
        return func && func.constructor && func.call && func.apply &&
            (typeof func === 'function') && ({}.toString.call(func) === "[object Function]");
    }

    isInteger(value) {
        return Number.isInteger ? Number.isInteger(value) : (typeof value==="number" && Math.floor(value)===value);
    }

    isObject(obj) {
        return obj && obj.constructor===Object;
    }

    isString(value) {
        return typeof value === 'string' || value instanceof String;
    }

    isClass(clazz) {
        const isConstrClass = (c) => c.constructor && c.constructor.toString().substring(0, 5)==='class';
        const isPrototypeConstrClass = (c) => c.prototype!==undefined && c.prototype.constructor && c.prototype.constructor.toString
            && c.prototype.constructor.toString().substring(0, 5)==='class';
        return clazz && (isConstrClass || isPrototypeConstrClass) ? true : false;
    }

    // === Device/SO check
    isDesktop() {
        return (!isMobile && !isTablet);
    }

    isMobile() {
        return (isMobile || isTablet);
    }

    isAndroid() {
        return isAndroid;
    }

    isIOS() {
        return isIOS;
    }

    // === Environment
    isDev = () => process.env.REACT_APP_ENVIRONMENT === 'DEV';

    isHml = () => process.env.REACT_APP_ENVIRONMENT === 'HML';

    isProd = () => process.env.REACT_APP_ENVIRONMENT === 'PRD';
}

const utils = new Utils();
export default utils;
export {
    EMPTY_HREF,
    MOBILE_INDICATOR_SELECTOR
}