/*
** @name: Meu Clínicas - sessionStorageManager
** @author:
** @date:
** @description: Prove rotinas para o controle de sessão da aplicação
** 
** @update: Março 2021 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Realizada expansão para armazenar o controle de navegacao no novo layout na sessao
** 
** @update: Agosto 2021 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Adicionado novas funcionalidade ao objeto 'navigation'
*/

import utils from './utils.js';


const APP_KEY_AUTH = "sessionKey_Authentication";
const APP_KEY_NAV = "sessionKey_Navigation";
const APP_KEY_USAGE = "sessionKey_UsageTrack";

const buildCleanEvents = () => {
    return {
        onBeforeCloseModule: {}
    };
}

const buildTokenLifetimeData = (token, user) => {
    const issuedAt = utils.jwtGetIssueTime(token);
    const expireAt = utils.jwtGetExpiration(token);
    if(issuedAt && expireAt && utils.isObject(user)) {
        return {
            issuedAt,
            expireAt,
            localExpireAt: (expireAt + (user.localClockDifference || 0))
        }
    }
    return null;
}

const getStoredObject = (appKey) => {
    return JSON.parse(sessionStorage.getItem(appKey));
}

const setStoredObject = (appKey, data) => {
    sessionStorage.setItem(appKey, JSON.stringify(data));
}

class SessionStorageManager {

    isCookiesEnabled() {
        try {
            return sessionStorage !== null;
        } catch(e) {
            return false;
        }
    }

    clear() {
        this.auth.clear();
        this.navigation.clear();
        this.usageTrack.clear();
    }

    auth = {
        clear() {
            this.store(null, null, null);
        },

        store(token, fingerprint, user) {
            const lifetimeData = token!==this.getToken() ? buildTokenLifetimeData(token, user) : this.getTokenLifetimeData();
            setStoredObject(APP_KEY_AUTH, {
                jwt: token,
                fingerprint: fingerprint,
                user: user,
                lifetimeData
            });
        },

        getFingerprint() {
            const storedObject = getStoredObject(APP_KEY_AUTH);
            return storedObject !== null ? storedObject.fingerprint : null;        
        },

        getToken() {
            const storedObject = getStoredObject(APP_KEY_AUTH);
            return storedObject !== null ? storedObject.jwt : null;
        },

        getUserData() {
            const storedObject = getStoredObject(APP_KEY_AUTH);
            return storedObject !== null ? storedObject.user : null;
        },

        getTokenLifetimeData() {
            const storedObject = getStoredObject(APP_KEY_AUTH) || {};
            const { jwt, fingerprint, user } = storedObject;
            if(jwt!==null && fingerprint!==null && user!==null) {
                const { issuedAt, expireAt, localExpireAt } = storedObject.lifetimeData || {};
                return {
                    issuedAt: issuedAt || null,
                    expireAt: expireAt || null,
                    localExpireAt: localExpireAt || null
                };
            }
            return null;
        }
    }

    navigation = {
        _modulesEvents: buildCleanEvents(),

        // General
        _get() {
            const storedObject = getStoredObject(APP_KEY_NAV);
            return utils.isObject(storedObject) ? storedObject : {};
        },

        _set(navObject) {
            setStoredObject(APP_KEY_NAV, utils.isObject(navObject) ? navObject : {});
        },

        clear() {
            this._set({});
            this._modulesEvents = buildCleanEvents();
        },

        // Modules related methods
        getCardModules() { // Obtem a toda lista de cardModules
            const storedObject = this._get();
            return storedObject.cardModule ? storedObject.cardModule : [];
        },

        getCurrentCardModule() { // Obter o 'card module' atual
            const moduleList = this.getCardModules();
            const module = moduleList && moduleList.length>0 ? moduleList[moduleList.length-1] : null;
            return utils.isObject(module) ? module : {};
        },

        getCurrentCardModuleParameters() { // Obter os parametros do 'card module' atual
            const currModuleData = this.getCurrentCardModule();
            const { parameters } = currModuleData;
            return utils.isObject(parameters) ? parameters : {};
        },

        addCardModule(moduleName, parameters, allowModuleRepetition) { // Adiciona um novo cardModule à lista
            if(allowModuleRepetition || moduleName !== this.getCurrentCardModule().moduleName) {
                const updateObject = this._get();
                updateObject.cardModule = this.getCardModules();
                updateObject.cardModule.push({ 
                    moduleName, 
                    parameters: utils.isObject(parameters) ? parameters : null
                });
                this._set(updateObject);
            }
        },

        clearCardModules() { // Limpa toda lista de cardModules
            const updateObject = this._get();
            updateObject.cardModule = [];
            this._set(updateObject);
            this._modulesEvents = buildCleanEvents();
        },

        eventOnBeforeCloseModule(func, moduleName) { // Define evento onBeforeCloseModule
            if(!this.getCardModules().find(m => moduleName===m.moduleName)) {
                moduleName = this.getCurrentCardModule().moduleName;
            }
            if(moduleName) {
                this._modulesEvents.onBeforeCloseModule[moduleName] = utils.isFunction(func) ? func : null;
            }
        },

        removeCardModules(listModuleName) { // Remove um ou mais 'cardModules'
            const modules = utils.isArray(listModuleName) ? listModuleName : (utils.isString(listModuleName) ? [listModuleName] : null);
            if(!modules) {
                return;
            }

            const updateObject = this._get();
            if(utils.isArray(updateObject.cardModule)) {
                const newCardModule = updateObject.cardModule.filter(module => !utils.inArray(module.moduleName, modules));
                updateObject.cardModule = newCardModule;
                this._set(updateObject);

                // Clear events for removed modules
                modules.forEach(name => this.removeModuleEvents(name));
            }
        },

        removeCurrentCardModule(shouldBeModule) { // Remove o 'cardModule' atual
            if(shouldBeModule) { // caso indicado, validar se este é o mesmo do módulo atual
                const { moduleName } = this.getCurrentCardModule();
                if(shouldBeModule !== moduleName) {
                    return;
                }
            }

            const updateObject = this._get();
            if(utils.isArray(updateObject.cardModule)) {
                const removed = updateObject.cardModule.pop();
                this._set(updateObject);

                if(removed && removed.moduleName) {
                    if(!updateObject.cardModule.find(m => removed.moduleName===m.moduleName)) {
                        // No more of this module in stack => clear module events
                        this.removeModuleEvents(removed.moduleName);
                    }
                }
                return removed;
            }
            return null;
        },

        removeModuleEvents(moduleName) {
            Object.keys(this._modulesEvents).forEach(evnt => {
                if(this._modulesEvents[evnt][moduleName]) { 
                    delete this._modulesEvents[evnt][moduleName];
                }
            });
        },

        runCurrentModuleBeforeClose(shouldBeModule) {
            if(shouldBeModule) { // caso indicado, validar se este é o mesmo do módulo atual
                const { moduleName } = this.getCurrentCardModule();
                if(shouldBeModule !== moduleName) {
                    return;
                }
            }
        
            const storedObject = this._get();
            if(utils.isArray(storedObject.cardModule)) {
                const module = storedObject.cardModule.slice(-1)[0];
                if(module) {
                    if(module.moduleName && this._modulesEvents.onBeforeCloseModule[module.moduleName]) {
                        // Trigger event onBeforeCloseModule
                        return this._modulesEvents.onBeforeCloseModule[module.moduleName]();
                    }
                }
            }
            return true;
        },

        setCardModuleParameters(moduleName, parameters) { // Altera os parametros do card (padrao modulo atual)
            if(!utils.isString(moduleName)) {
                moduleName = this.getCurrentCardModule().moduleName;
            }
            if(!moduleName) {
                return;
            }

            const updateObject = this._get();
            if(utils.isArray(updateObject.cardModule)) {
                updateObject.cardModule.forEach(module => {
                    if(moduleName === module.moduleName) {
                        module.parameters = utils.isObject(parameters) ? parameters : null;
                    }                    
                });
                this._set(updateObject);
            }
        },

        // Help related methods
        getHelpContext() {
            const storedObject = this._get();
            return storedObject.helpContext ? storedObject.helpContext : null;
        },
        
        removeHelpContext() {
            const updateObject = this._get();
            updateObject.helpContext = null;
            this._set(updateObject);
        },

        setHelpContext(helpContext) {
            const updateObject = this._get();
            updateObject.helpContext = helpContext;
            this._set(updateObject);
        }
    }

    usageTrack = {
        _get() {
            const so = getStoredObject(APP_KEY_USAGE);
            return utils.isObject(so) ? so : {};
        },

        _set(usageObject) {
            setStoredObject(APP_KEY_USAGE, utils.isObject(usageObject) ? usageObject : {});
        },

        clear() {
            this._set({});
        },

        getUsageTime() {
            const so = this._get();
            return so.usageTime ? so.usageTime : null;
        },

        setUsageTime() {
            const updateObject = this._get();
            updateObject.usageTime = new Date().getTime();
            this._set(updateObject);
        }
    }
}

const sessionStorageManager = new SessionStorageManager();
export default sessionStorageManager;