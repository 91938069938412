/*
** @name: Meu Clínicas - dashboard
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Fevereiro 2021
** @description: Módulo para acompanhamentos de estatísticas da aplicação
** 
** @update: Abril 2021 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Adicionada novas estatísticas e gráficos de acesso
*/

import React, { Component } from 'react';
import FormBuilder from 'react-dj-forms-builder';
import { Form, Accordion } from 'semantic-ui-react';

import utils from '../../core/utils.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';

import AppExternalServiceHeader from '../../components/general/appExternalServiceHeader/appExternalServiceHeader.js';
import AppExternalServiceInfoMessage from '../../components/general/appExternalServiceInfoMessage/appExternalServiceInfoMessage.js';
import AppExtraDocumentHead from '../../components/general/appExtraDocumentHead/appExtraDocumentHead.js';
import ExternalServicesSessionLifetime from '../../components/general/externalServicesSessionLifetime/externalServicesSessionLifetime.js';
import { InputField } from '../../components/fields/formsBuilderCustoms';

import consultasClient from '../../apiClients/consultas/consultasClient.js';
import examesClient from '../../apiClients/exames/examesClient.js';
import formulariosClient from '../../apiClients/formularios/formulariosClient.js';
import notificationClient from '../../apiClients/notification/notificationClient.js';
import loginClient, { ADMIN_SERVICE_LIST } from '../../apiClients/login/loginClient.js';
import receitasClient from '../../apiClients/receitas/receitasClient.js';
import registrosMedicosClient from '../../apiClients/registrosMedicos/registrosMedicosClient.js';
import wikiClient from '../../apiClients/wiki/wikiClient.js';

import AccessStatistics from './accessStatistics.js';
import UserStatistics from './userStatistics.js';
import ServicesStatistics from './servicesStatistics.js';
import ServicesInfo from './servicesInfo.js';

import packageJson from '../../../package.json';


// Import module styles
import './dashboard.scss';

// Import form configuration
import formConfigLogin from './loginForm.json';


const CREDENTIAL_EXPIRING_MS = 1200000;
const SESSION_EXPIRATION_WARNING_SEC = 300;
const MESSAGE_FETCHING_DATA = <div className="fetching-data">Dados ainda sendo coletados. Por favor aguarde ...</div>;
const STEP_LOGIN = 'login';
const STEP_SHOW = 'show';

const Dashboard = (props) => {
    const appControllerContext = useAppControllerContext();
    return(
        <DashboardImplem
            appControllerContext={appControllerContext}
            {...props}
        />
    )
}

class DashboardImplem extends Component {

    constructor(props) {
        super(props);

        this.state = this._initialState();
    }

    _carregarDados = () => {
        this._fetchServicesInfo();
        this._fetchServicesStatistics();
    }

    _emptyStatistics = () => {
        const emptyStatistic = {
            users: {
                accesses: {},
            },
            services: {
                consultas: {},
                exames: {},
                formularios: {},
                notification: {},
                login: {},
                receitas: {},
                regmedicos: {},
                wiki: {}
            }
        };

        return emptyStatistic;
    }

    _fetchServicesInfo = () => {
        // Initialize with WFE info
        this.setState({
            buildInfo: [
                {
                    title: "Frontend - WEB",
                    active: false,
                    order: 11,
                    repoUrl: 'https://git.local.hcpa.ufrgs.br/meuclinicas/web-frontend',
                    data: {
                        serviceName: "web-frontend",
                        serviceVersion: packageJson.version,
                        git: {
                            branch: process.env.REACT_APP_CURRENT_BRANCH,
                            commit: {
                                id: process.env.REACT_APP_LAST_COMMIT_HASH,
                                time: process.env.REACT_APP_LAST_COMMIT_DATE_HOUR,
                                message: process.env.REACT_APP_LAST_COMMIT_MESSAGE,
                                user: {
                                    email: process.env.REACT_APP_LAST_COMMIT_AUTHOR
                                }
                            }
                        },
                        buildEpoch: utils.buildEpoch()
                    }
                }
            ]
        });

        // Fetch other services
        consultasClient.buildInfo(res => {
            this.state.buildInfo.push({
                title: "Consultas",
                repoUrl: 'https://git.local.hcpa.ufrgs.br/meuclinicas/consultas-service',
                order: 21,
                active: false,
                data: res.data.buildInfo
            });
            this.setState({});
        }, err => {
            this._logError(err, 'consultas');
        });

        examesClient.buildInfo(res => {
            this.state.buildInfo.push({
                title: "Exames",
                repoUrl: 'https://git.local.hcpa.ufrgs.br/meuclinicas/exames-service',
                order: 22,
                active: false,
                data: res.data.buildInfo
            });
            this.setState({});
        }, err => {
            this._logError(err, 'exames');
        });

        formulariosClient.buildInfo(res => {
            this.state.buildInfo.push({
                title: "Formularios",
                repoUrl: 'https://git.local.hcpa.ufrgs.br/meuclinicas/formularios-service',
                order: 23,
                active: false,
                data: res.data.buildInfo
            });
            this.setState({});
        }, err => {
            this._logError(err, 'formularios');
        });

        loginClient.buildInfo(res => {
            this.state.buildInfo.push({
                title: "Login",
                order: 24,
                repoUrl: 'https://git.local.hcpa.ufrgs.br/meuclinicas/login-service',
                active: false,
                data: res.data.buildInfo
            });
            this.setState({});
        }, err => {
            this._logError(err, 'login');
        });

        notificationClient.buildInfo(res => {
            this.state.buildInfo.push({
                title: "Notification",
                repoUrl: 'https://git.local.hcpa.ufrgs.br/meuclinicas/notification-service',
                order: 25,
                active: false,
                data: res.data.buildInfo
            });
            this.setState({});
        }, err => {
            this._logError(err, 'notification');
        });

        receitasClient.buildInfo(res => {
            this.state.buildInfo.push({
                title: "Receitas",
                repoUrl: 'https://git.local.hcpa.ufrgs.br/meuclinicas/receitas-service',
                order: 26,
                active: false,
                data: res.data.buildInfo
            });
            this.setState({}); 
        }, err => {
            this._logError(err, 'receitas');
        });

        registrosMedicosClient.buildInfo(res => {
            this.state.buildInfo.push({
                title: "Registros Médicos",
                repoUrl: 'https://git.local.hcpa.ufrgs.br/meuclinicas/regmedicos-service',
                order: 27,
                active: false,
                data: res.data.buildInfo
            });
            this.setState({});
        }, err => {
            this._logError(err, 'regmedicos');
        });

        wikiClient.buildInfo(res => {
            this.state.buildInfo.push({
                title: "Wiki",
                repoUrl: 'https://git.local.hcpa.ufrgs.br/meuclinicas/wiki-service',
                order: 28,
                active: false,
                data: res.data.buildInfo
            });
            this.setState({});
        }, err => {
            this._logError(err, 'wiki');
        });
    }

    _fetchServicesStatistics = () => {
        const checkErrorStatus = (err) => {
            if(err.response && err.response.status && err.response.status === 401) {
                this.setState(this._initialState());
            }
        }

        // Initialize statistics data
        this.setState({
            statisticsData: this._emptyStatistics()
        });

        // Fetch statistics data from services
        const credentials = this.state.credentials ? this.state.credentials : {};
        const { jwtServiceToken, fingerprint } = credentials;
        if(!jwtServiceToken || !fingerprint) {
            console.error("Credenciais inválidas.");
            this.setState(this._initialState());
            return;
        }

        consultasClient.statistics(
            jwtServiceToken,
            fingerprint,
            res => {
                const updStatisticsData = this.state.statisticsData;
                updStatisticsData.services.consultas.data = res.data["consultas-service"];
                this.setState({
                    statisticsData: updStatisticsData
                });
            }, 
            err => {
                console.error("consultasStatistics", err);
                checkErrorStatus(err);
            }
        );

        examesClient.statistics(
            jwtServiceToken,
            fingerprint,
            res => {
                const updStatisticsData = this.state.statisticsData;
                updStatisticsData.services.exames.data = res.data["exames-service"];
                this.setState({
                    statisticsData: updStatisticsData
                });
            }, 
            err => {
                console.error("examesStatistics", err);
                checkErrorStatus(err);
            }
        );

        formulariosClient.statistics(
            jwtServiceToken,
            fingerprint,
            res => {
                const updStatisticsData = this.state.statisticsData;
                updStatisticsData.services.formularios.data = res.data["formularios-service"];
                this.setState({
                    statisticsData: updStatisticsData
                });
            }, 
            err => {
                console.error("formulariosStatistics", err);
                checkErrorStatus(err);
            }
        );

        notificationClient.statistics(
            jwtServiceToken,
            fingerprint,
            res => {
                const updStatisticsData = this.state.statisticsData;
                updStatisticsData.services.notification.data = res.data["notification-service"];
                this.setState({
                    statisticsData: updStatisticsData
                });
            }, 
            err => {
                console.error("notificationStatistics", err);
                checkErrorStatus(err);
            }
        );

        loginClient.statistics(
            jwtServiceToken,
            fingerprint,
            res => {
                const updStatisticsData = this.state.statisticsData;
                updStatisticsData.services.login.data = {};
                updStatisticsData.users.accesses.data = res.data["login-service"].acessos;
                updStatisticsData.users.data = res.data["login-service"];
                delete updStatisticsData.users.data.acessos;
                this.setState({
                    statisticsData: updStatisticsData
                });
            }, 
            err => {
                console.error("loginStatistics", err);
                checkErrorStatus(err);
            }
        );

        receitasClient.statistics(
            jwtServiceToken,
            fingerprint,
            res => {
                const updStatisticsData = this.state.statisticsData;
                updStatisticsData.services.receitas.data = res.data["receitas-service"];
                this.setState({
                    statisticsData: updStatisticsData
                });
            }, 
            err => {
                console.error("receitasStatistics", err);
                checkErrorStatus(err);
            }
        );

        registrosMedicosClient.statistics(
            jwtServiceToken,
            fingerprint,
            res => {
                const updStatisticsData = this.state.statisticsData;
                updStatisticsData.services.regmedicos.data = res.data["regmedicos-service"];
                this.setState({
                    statisticsData: updStatisticsData
                });
            }, 
            err => {
                console.error("regmedicosStatistics", err);
                checkErrorStatus(err);
            }
        );

        wikiClient.statistics(
            jwtServiceToken,
            fingerprint,
            res => {
                const updStatisticsData = this.state.statisticsData;
                updStatisticsData.services.wiki.data = res.data["wiki-service"];
                this.setState({
                    statisticsData: updStatisticsData
                });
            }, 
            err => {
                console.error("wikiStatistics", err);
                checkErrorStatus(err);
            }
        );
    }
   
    _focusFormEditField = () => {
        const { currentStep, loginFocus } = this.state;
        if(currentStep===STEP_LOGIN) {
            if(loginFocus) {
                const l_obj = document.getElementsByName(loginFocus);
                if(l_obj.length > 0) {
                    let obj = l_obj[0];
                    obj.focus();
                }    
                this.setState({ loginFocus: false });
            }
        }
    }

    _getCurrentCredentials = () => {
        const { username, password, authenticationTime} = this.state.credentials || {};
        const isCredentialValid = username && password && authenticationTime && ((Date.now() - authenticationTime) <= CREDENTIAL_EXPIRING_MS);
        if(!isCredentialValid) {
            this.setState(this._initialState());
            return [];
        }
        return [username, password];
    }

    async _getServiceTokenAsync(username, password) {
        return new Promise(async (resolve, reject) => {
            utils.asyncServiceToken(ADMIN_SERVICE_LIST.DASHBOARD, username, password)
            .then(res => {
                const result = res.data;
                if(!result.jwtServiceToken) {
                    this._setErroProcessamento(result.errorMessage ? result.errorMessage : "Ops!, ocorreu um erro validando suas credenciais.");
                    reject(false);
                }

                const now = Date.now();
                const newCredentials = {
                    jwtServiceToken: result.jwtServiceToken,
                    fingerprint: result.fingerprint,
                    username: username,
                    password: password,
                    authenticationTime: now,
                    keepAliveTime: now
                }

                this.setState({ credentials: newCredentials }, () => resolve(newCredentials));
            })
            .catch(err => {
                this._setErroProcessamento("Ops!, ocorreu um erro validando suas credenciais.");
                reject(err);
            })
        });
    }

    _handleAccordionClick = (object) => {
        object.active = !object.active;
        this.setState({});
    }

    _handleLoginFormUpdate = (fields) => {
        this.setState({ loginFields: fields });
    }

    _handleLogin = () => {
        const loginFields = this.state.loginFields;
        if(!loginFields) {
            return;
        }

        this._setLoading(true);
        this._setErroProcessamento(null);

        const username = loginFields.usuario ? loginFields.usuario.value : null;
        const password = loginFields.senha ? loginFields.senha.value : null;
        this._getServiceTokenAsync(username, password)
            .then(res => {
                this._setLoading(false);
                this.setState({
                    currentStep: STEP_SHOW
                });
                this._carregarDados();
            })
            .catch(err => {
                this._setLoading(false);
            })
    }

    _handleRefreshData = () => {
        const [ username, password ] = this._getCurrentCredentials();
        if(!username || !password) {
            return;
        }

        this._setLoading(true);
        this._setErroProcessamento(null);

        this._getServiceTokenAsync(username, password)
            .then(res => {
                this._setLoading(false);
                this._carregarDados();
            })
            .catch(err => {
                this._setLoading(false);
            })
    }

    _handleSessionExpired = () => {
        setTimeout(() => this.setState(this._initialState()), 3000);
    }

    _handleSessionRefresh = () => {
        const updCrendential = this.state.credentials;
        if(updCrendential) {
            updCrendential.keepAliveTime = Date.now();
            this.setState({ credentials: updCrendential });
        }
    }

    _initialState = () => {
        return {
            currentStep: STEP_LOGIN,
            loginFields: null,
            loginFocus: 'usuario',
            erroProcessamento: null,
            buildInfo: [],
            statisticsData: this._emptyStatistics(),
            credentials: null
        };
    }

    _logError = (error, module) => {
        console.warn('Erro ao buscar a informação de build: ' + module, JSON.stringify(error));
    }

    _setErroProcessamento = (msg) => {
        this.setState({ erroProcessamento: msg });
    }

    _setLoading = (visible) => {
        utils.setLoadingVisibility(this.props.appControllerContext, visible);
    }

    componentDidUpdate() {
        this._focusFormEditField();        
    }

    render() {
        const { currentStep, credentials, loginFields, erroProcessamento } = this.state;
        const loginEnabled = loginFields && loginFields.usuario && loginFields.usuario.value && loginFields.senha && loginFields.senha.value;
        return (
            <div className="dashboard-wrapper">
                <AppExtraDocumentHead subTitle="Dashboard" robots="noindex,nofollow" />

                <div className="content">
                    <div className="header-card">
                        <AppExternalServiceHeader linkToHome={false}>
                            <h1>Dashboard</h1>
                        </AppExternalServiceHeader>
                    </div>

                    { currentStep===STEP_LOGIN &&
                    <div className="login-section">
                        <Form name="formMain">
                            <div className="form-wrapper">
                                <FormBuilder 
                                    config={formConfigLogin}
                                    fields={loginFields}
                                    page={0}
                                    className="form-login" 
                                    onChange={this._handleLoginFormUpdate}
                                    overrideFieldRender={{
                                        'input': InputField
                                    }}
                                />
                            </div>

                            <AppExternalServiceInfoMessage id="msgErrorID" className="info-error">
                                {erroProcessamento}
                            </AppExternalServiceInfoMessage>

                            <div className="action-section">
                                <button type="default" disabled={!loginEnabled} className="btn-login" onClick={e => this._handleLogin(e)}>Login</button>
                            </div>
                        </Form>
                    </div>
                    }

                    { currentStep===STEP_SHOW &&
                    <div className="content-section">

                        <ExternalServicesSessionLifetime
                            sessionStartTime={credentials ? credentials.keepAliveTime : null}
                            onExpire={() => this._handleSessionExpired()}
                            onRefresh={() => this._handleSessionRefresh()}
                            sessionExpireTimeMs={CREDENTIAL_EXPIRING_MS}
                            expirationWarningSec={SESSION_EXPIRATION_WARNING_SEC}
                        />

                        <div className="section-header-wrapper">
                            <h2>Estatísticas de Uso:</h2>
                            <div className="refresh-wrapper">
                                <button type="default" className="btn-refresh" onClick={() => this._handleRefreshData()}>Atualizar dados</button>
                            </div>
                        </div>

                        <div className="statistics-info-data">
                            <Accordion fluid styled>
                                <UserStatistics data={this.state.statisticsData?.users} fetchingMessage={MESSAGE_FETCHING_DATA} onToggle={this._handleAccordionClick} />

                                <AccessStatistics data={this.state.statisticsData?.users?.accesses} fetchingMessage={MESSAGE_FETCHING_DATA} onToggle={this._handleAccordionClick} />

                                <ServicesStatistics data={this.state.statisticsData?.services} fetchingMessage={MESSAGE_FETCHING_DATA} onToggle={this._handleAccordionClick} />
                            </Accordion>
                        </div>

                        <div className="section-header-wrapper">
                            <h2>Informações do Serviços:</h2>
                        </div>
                        <div className="build-info-data">
                            <ServicesInfo buildInfo={this.state.buildInfo} onToggle={this._handleAccordionClick} />
                        </div>

                        <AppExternalServiceInfoMessage id="msgErrorID" className="info-error">
                            {erroProcessamento}
                        </AppExternalServiceInfoMessage>

                    </div>
                    }
                </div>
            </div>
        );
    }
}

export default Dashboard;