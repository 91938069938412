/*
** @name: Meu Clínicas - privacyPolicy
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Novembro 2020
** @description: Módulo para os serviços externos (páginas fora do fluxo normal da aplicação)
** para gerar o termos de uso da aplicação fora do fluxo de cadastro
** 
** @update: Março 2021 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Atualizado para novo layout da aplicação e funcionamento com cards e agrupamento por futuras e históricas
*/

import React from 'react';

import AppExternalServiceHeader from '../../components/general/appExternalServiceHeader/appExternalServiceHeader.js';
import AppExtraDocumentHead from '../../components/general/appExtraDocumentHead/appExtraDocumentHead.js';


// Import module styles
import './privacyPolicy.scss'; 


const _TERMOS_ACEITE_ = require('../../theme/resources/termosAceite.json');


const PrivacyPolicy = (props) => {
    const conteudoTermo = _TERMOS_ACEITE_["conteudo"];
    const conteudoAppExtra = _TERMOS_ACEITE_["app-complemento"];
    const privacyPolicy = { 
        __html: conteudoTermo.join("\n") + "\n" + conteudoAppExtra.join("\n")
    };

    return(
        <div className="privacy-policy-wrapper">
            <AppExtraDocumentHead subTitle="Termos de uso e privacidade" robots={null} />

            <div className="content">
                <div className="header-card">
                    <AppExternalServiceHeader linkToHome={true}>
                        <h1>Termos de uso e privacidade</h1>
                    </AppExternalServiceHeader>
                </div>

                <div className="body">
                    <div dangerouslySetInnerHTML={privacyPolicy} />
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy;