/*
** @name: Meu Clínicas - imageCacheManager
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2023
** @description: Prove rotinas para o ralizar o cache de imagens da aplicação
*/

import utils from './utils.js';

import { getAppImageCache, getAppImagesConfig } from './appThemeConfig.js';
import { importThemeImage } from './appThemeUtils.js';


const appCacheImages = async (themeConfig, cacheContainer) => {
    resetCacheControl();
    const imageCache = getAppImageCache(themeConfig);
    if(imageCache && imageCache.enabled) {
        const { requiredModules, priorityModules } = imageCache;
        const container = imageCache.renderCachedImages ? cacheContainer : null;
        if(utils.isArray(requiredModules) && requiredModules.length) {
            const requiredImages = buildImageList(themeConfig, requiredModules);
            await cacheImagesList(requiredImages)
                .then(res => appendToContainer(container, res))
                .catch(() => { });
        }
        const priorityImages = utils.isArray(priorityModules) ? buildImageList(themeConfig, priorityModules) : [];
        cacheImagesList(priorityImages)
            .then(res => {
                appendToContainer(container, res);
                const allImages = buildImageList(themeConfig);
                cacheImagesList(allImages)
                    .then(res => appendToContainer(container, res))
                    .catch(() => { });
            })
            .catch(() => { });
    }
}

const appendToContainer = (container, cachedList) => {
    if(container && utils.isArray(cachedList)) {
        cachedList.forEach(ci => {
            if(ci.img) {
                container.appendChild(ci.img);
            }
        });
    }
}

const buildImageList = (themeConfig, moduleList) => {
    const imagesConfig = utils.isObject(themeConfig) ? getAppImagesConfig(themeConfig) : null;
    if(utils.isObject(imagesConfig)) {
        const imageList = [];
        Object.keys(imagesConfig).forEach(moduleId => {
            const moduleCfg = imagesConfig[moduleId];
            if((!utils.isArray(moduleList) || moduleList.includes(moduleId)) && utils.isObject(moduleCfg)) {
                Object.keys(moduleCfg).forEach(imageId => {
                    const image = importThemeImage(themeConfig, moduleId, imageId, true);
                    if(image && image.substring(0, 5)!=="data:" && !imageList.includes(image)) { // ignore inline image data and duplicated items
                        imageList.push(image);
                    }
                })
            }
        });
        return imageList;
    }
    return null;
}

const cacheImage = async (src, disableCacheControl) => {
    return new Promise(resolve => {
        if(disableCacheControl || !isImageCached(src)) {
            const img = new Image();
            img.onload = () => {
                if(!disableCacheControl) {
                    updateCacheControl(src, true);
                }
                resolve({ success: true, src, img });
            };
            img.onerror = () => {
                if(!disableCacheControl) {
                    updateCacheControl(src, false);
                }
                resolve({ success: false, src, img: null });
            };
            img.src = src;
        } else {
            resolve({ success: true, src, img: null });
        }
    });
}

const cacheImagesList = async (srcList, disableCacheCheck) => {
    const promises = await srcList.map((src) => {
        return cacheImage(src, disableCacheCheck);
    })
    return Promise.all(promises);
}

const isImageCached = src => {
    return src && window.__cachedImages && window.__cachedImages[src];
}

const resetCacheControl = () => {
    window.__cachedImages = null;
}

const updateCacheControl = (src, cached) => {
    if(!utils.isObject(window.__cachedImages)) {
        window.__cachedImages = {};
    }
    if(src) {
        if(cached) {
            window.__cachedImages[src] = true;
        } else {
            delete window.__cachedImages[src];
        }
    }
}

export {
    appCacheImages,
    buildImageList,
    cacheImage,
    cacheImagesList,
    isImageCached,
    resetCacheControl,
    updateCacheControl
}