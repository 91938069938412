/*
** @name: Meu Clínicas - requestAuth
** @author:
** @date:
** @description: Rotinas para chamadas REST usando autenticacao 
*/

import axios from 'axios';
import sessionStorageManager from './sessionStorageManager';

class RequestAuth {

    constructor() {
        this.get = this.get.bind(this);
        this.post = this.post.bind(this);
    }
    
    _forceLogout() {
        sessionStorageManager.clear();
        window.location.reload();
    }

    _getAuthenticationHeaders() {
        return {
            headers: {
                'jwt-header': sessionStorageManager.auth.getToken(),
                'fingerprint': sessionStorageManager.auth.getFingerprint(),
            }
        };
    }

    get(resourceUrl, sucessCallback, errorCallback, queryParams, avoidForcedLogout) { 
        var axiosParams = this._getAuthenticationHeaders();
        if(queryParams) {
            axiosParams.params = queryParams;
        }      
        
        axios.get(resourceUrl, axiosParams)
            .then(sucessCallback)
            .catch(err => {
                if (!avoidForcedLogout && err.response && err.response.status && err.response.status === 403) {
                    this._forceLogout();
                    return;
                }
                errorCallback(err);
            });
    }

    post(resourceUrl, data, sucessCallback, errorCallback, avoidForcedLogout) {
        var axiosParams = this._getAuthenticationHeaders();

        axios.post(resourceUrl, data, axiosParams)
            .then(sucessCallback)
            .catch(err => {
                if (!avoidForcedLogout && err.response && err.response.status && err.response.status === 403) {
                    this._forceLogout();
                    return;
                }
                errorCallback(err);
            });
    }

}

const requestAuth = new RequestAuth();
export default requestAuth;