import requestNoAuth from "../../core/requestNoAuth";

import { apiUrl } from "../../core/apiUrl.js";


const API_PORT = 8087;
const API_SERVICE = 'wiki-service';
const API_VERSION = 'v2';

class WikiClient {

    actuatorInfo(successCallback, errorCallback) {
        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, null, '/actuator/info'),
            successCallback,
            errorCallback
        );
    }

    buildInfo(successCallback, errorCallback) {
        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, null, '/build-info'),
            successCallback,
            errorCallback
        );
    }

    statistics(serviceToken, fingerprint, successCallback, errorCallback) {
        const queryParams = {
            jwtServiceToken: serviceToken,
            fingerprint: fingerprint
        }
        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/statistics'),
            successCallback,
            errorCallback,
            queryParams
        );
    } 

    news(successCallback, errorCallback) {
        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/news'),
            successCallback,
            errorCallback
        );
    }

    newsGetAll(successCallback, errorCallback) {
        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/news/all'),
            successCallback,
            errorCallback
        );
    }

    newsByLocation(exhibitLocation, successCallback, errorCallback) {
        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, `/public/news/${exhibitLocation}`),
            successCallback,
            errorCallback
        );
    }

    newsBatchUpdate(jwtServiceToken, fingerprint, listUpdateItems, successCallback, errorCallback) {
        var userData = {
            jwtServiceToken: jwtServiceToken, 
            fingerprint: fingerprint,
            listUpdateItems: listUpdateItems
        };

        requestNoAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/news/batch-update'),
            userData,
            successCallback,
            errorCallback
        );
    }

    newsDelete(jwtServiceToken, fingerprint, id, successCallback, errorCallback) {
        var userData = {
            jwtServiceToken: jwtServiceToken, 
            fingerprint: fingerprint,
            id: id
        };

        requestNoAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/news/delete'),
            userData,
            successCallback,
            errorCallback
        );
    }

    newsSave(jwtServiceToken, fingerprint, news, successCallback, errorCallback) {
        var userData = {
            jwtServiceToken: jwtServiceToken, 
            fingerprint: fingerprint,
            news: news
        };

        requestNoAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/news/save'),
            userData,
            successCallback,
            errorCallback
        );
    }

    userFaq(questionId, allTags, tags, successCallback, errorCallback) {
        var userData = {
            questionId: questionId,
            allTags: (allTags ? true : false),
            tags: tags
        };

        requestNoAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/faq'),
            userData,
            successCallback,
            errorCallback
        );
    }

    userFaqGetAll(questionId, allTags, tags, successCallback, errorCallback) {
        var userData = {
            questionId: questionId,
            allTags: (allTags ? true : false),
            tags: tags
        };

        requestNoAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/faq/all'),
            userData,
            successCallback,
            errorCallback
        );
    }

    userFaqDelete(jwtServiceToken, fingerprint, id, successCallback, errorCallback) {
        var userData = {
            jwtServiceToken: jwtServiceToken, 
            fingerprint: fingerprint,
            id: id
        };

        requestNoAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/faq/delete'),
            userData,
            successCallback,
            errorCallback
        );
    }

    userFaqSave(jwtServiceToken, fingerprint, userFaq, successCallback, errorCallback) {
        var userData = {
            jwtServiceToken: jwtServiceToken, 
            fingerprint: fingerprint,
            userFaq: userFaq
        };

        requestNoAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/faq/save'),
            userData,
            successCallback,
            errorCallback
        );
    }

    userFaqBatchUpdate(jwtServiceToken, fingerprint, listUpdateItems, successCallback, errorCallback) {
        var userData = {
            jwtServiceToken: jwtServiceToken, 
            fingerprint: fingerprint,
            listUpdateItems: listUpdateItems
        };

        requestNoAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/faq/batch-update'),
            userData,
            successCallback,
            errorCallback
        );
    }

}

const wikiClient = new WikiClient();
export default wikiClient;
export {
    API_PORT,
    API_SERVICE,
    API_VERSION
};