import React from 'react';

/*
** @name: Meu Clínicas - agendamentoConsultas
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Agosto 2022
** @description: Conteúdo de exibição do resultado do agendamento
*/

import { ThemeImage, ThemeMessage } from '../../core/appThemeUtils.js';


const resultadoAgendamento = (props) => {
    const resultado = props.resultado || {};
    return(
        <div className="step-four-wrapper">
            { (resultado.sucesso) ?
            <>
                <div className="step-title">
                    <span className="bold-text">Pronto!</span>
                </div>
                
                <div className="information-wrapper">
                    Sua consulta foi marcada com sucesso.
                </div>

                <div className="logo-confirmacao">
                    <ThemeImage 
                        module="agendamentoConsultas"
                        imageId="agendamento-confirmado" />
                </div>
            </>
            :
            <>
                <div className="step-title">
                    <span className="bold-text">Erro</span>
                </div>
                
                <ThemeMessage elemType="div" messageId="agendamento-consulta_falha"  className="information-wrapper" />
            </>
            }
        </div>
    )
}

export default resultadoAgendamento;