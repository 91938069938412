/*
** @name: Meu Clínicas - appCardModuleBasicWrapper
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Setembro 2022
** @description: App module basic wrapper component
*/

import React from 'react';

const AppCardModuleBasicWrapper = (props) => {
    const { moduleName } = props;

    return(
        <div className={`${moduleName}-wrapper`}>
            <div className="content-wrapper">
                <div className="content-box">

                    { props.children }

                </div>
            </div>
        </div>
    );
}

export default AppCardModuleBasicWrapper;