/*
** @name: Meu Clínicas - appThemeContext
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Novembro 2020
** @description: Provem rotinas de configuracao para acesso ao thema em uso da aplicação
** @description: Componente para contexto de thema da aplicação. Fornece 'Provider', 'Consumer' e o 'hook'
*/

import React, { useContext, useState } from 'react';
import { getAppConfigImagePath } from './appThemeConfig.js';

const AppThemeContext = React.createContext('ThemeConfig');
const AppThemeContextConsumer = AppThemeContext.Consumer;

const AppThemeContextProvider = (props) => {
    const [state, setState] = useState({
        themeConfig: props.config
    });
    return (
        <AppThemeContext.Provider value={[state, setState]} >
            {props.children}
        </AppThemeContext.Provider>
    );
}

const useAppThemeContext = () => {
    const [state] = useContext(AppThemeContext);

    function getConfig() {
        return state.themeConfig;
    }

    function getImagePath(moduleName, imageId) {
        const config = state.themeConfig;
        return getAppConfigImagePath(config, moduleName, imageId);
    }

    return {
        getConfig,
        getImagePath
    };
}

export { AppThemeContextConsumer, AppThemeContextProvider, useAppThemeContext };