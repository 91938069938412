/*
** @name: Meu Clínicas - etiquetasMateriais
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Setembro 2021
** @description: Módulo para acesso a etiquetas de materiais pelo paciente (listagem, visualização e download)
*/

import React, { Component } from 'react';
import { Accordion } from 'semantic-ui-react';
import Moment from 'react-moment';
import moment from "moment";

import utils from '../../core/utils.js';
import { useAuthContext } from '../../core/authContext.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';
import { useAppThemeContext } from '../../core/appThemeContext.js';
import { getAppServiceConfigByName } from '../../core/appThemeConfig.js';
import { ThemeImage, ThemeMessage } from '../../core/appThemeUtils.js';
import { pascalCase } from '../../core/stringUtils.js';
import { APP_SERVICE_LIST } from '../../core/appServiceList.js';

import AppCardModuleBasicWrapper from '../../components/general/appCardModuleBasicWrapper/appCardModuleBasicWrapper.js';
import AppDateFilter from '../../components/general/appDateFilter/appDateFilter.js';
import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';
import ViewerModal from '../../components/general/viewerModal/viewerModal.js';
import { AccordionCollapsible } from '../../components/general/appNavigationControls/appNavigationControls.js';

import registrosMedicosClient from '../../apiClients/registrosMedicos/registrosMedicosClient.js';


// Import module styles
import './etiquetasMateriais.scss'; 


const ESCAPE_KEY = 27;

const EtiquetasMateriais = (props) => {
    const authContext = useAuthContext();
    const appControllerContext = useAppControllerContext();
    const themeConfig = useAppThemeContext().getConfig();
    return(
        <EtiquetasMateriaisImplem
            authContext={authContext}
            appControllerContext={appControllerContext}
            themeConfig={themeConfig}
            {...props}
        />
    )
}

class EtiquetasMateriaisImplem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFiltered: false,
            filterError: null,
            searchResult: [],
            searchErrorMessage: null,
            searchInfoMessage: null,
            showEtiqueta: false,
            listImagensEtiqueta: null,
        };
    }

    _appSetBackAction = () => {
        const rnIntegration = window.rnIntegration;
        if(rnIntegration && rnIntegration.isAppRunning()) {
            this.props.appControllerContext.methods.doResetAppBackAction();
            if(this._getDadosVisualizacao()) {
                rnIntegration.backAction.push(() => { this._handleCloseEtiqueta() });
            }
        }
    }

    _buildFilename = (etiqueta) => {
        const dtReferencia = utils.isInteger(etiqueta.dtReferencia) ? moment(new Date(etiqueta.dtReferencia)).format("DD-MM-YYYY") : "";
        return `etiqueta_${dtReferencia}`;
    } 

    _getDadosVisualizacao = () => {
        return this.state.showEtiqueta ? this.state.listImagensEtiqueta : null;
    }

    _getEtiquetaMaterialPDF = (etiqueta) => {
        this._setLoading(true);

        const pacCodigo = this.props.authContext.properties.user.pacCodigo;
        registrosMedicosClient.buscarPDFEtiquetasMateriais(
            etiqueta.id,
            pacCodigo,
            res => { 
                this._setLoading(false);
                if(!res.data || !res.data.pdfBase64) {
                    this._updateDownloadError(etiqueta, "Retorno sem dados para o documento.");
                    return;
                }
                this._updateDownloadError(etiqueta, null);

                const base64 = res.data.pdfBase64;
                const rnIntegration = window.rnIntegration;
                if(!rnIntegration || !rnIntegration.triggerAppPDFViewer(base64)) {
                    const fileName = this._buildFilename(etiqueta) + ".pdf";
                    utils.automaticDownloadData(base64, fileName);
                }

                etiqueta.statusLeitura = res.data.statusLeitura ? res.data.statusLeitura : etiqueta.statusLeitura;

                this.setState(this.state);
            },
            err => {
                this._setLoading(false);
                this._updateDownloadError(etiqueta, "Erro obtendo dados do documento."); 
            }
        ); 
    }

    _getEtiquetaMaterialPNG = (etiqueta) => {
        this._setLoading(true);

        const pacCodigo = this.props.authContext.properties.user.pacCodigo;
        registrosMedicosClient.buscarPNGEtiquetasMateriais(
            etiqueta.id,
            pacCodigo,
            res => { 
                this._setLoading(false);
                if(!res.data || !res.data.listPngBase64 || 
                    isNaN(res.data.listPngBase64.length) || res.data.listPngBase64.length<1) {
                     this._updateDownloadError(etiqueta, "Retorno sem dados para o documento.");
                     this.setState({ showEtiqueta: false, listImagensEtiqueta: null });
                     return;
                }

                this._updateDownloadError(etiqueta, null);

                etiqueta.statusLeitura = res.data.statusLeitura ? res.data.statusLeitura : etiqueta.statusLeitura;
                this.setState({ showEtiqueta: true, listImagensEtiqueta: res.data.listPngBase64 });
            },
            err => {
                this._setLoading(false);
                this._updateDownloadError(etiqueta, "Erro obtendo dados do documento."); 
            }
        ); 
    }
        
    _getListaEtiquetas = (params) => {
        const filtro = (params && params.filtro) || {};
        const filterError = params && params.filterError;

        this.setState({ filterError });
        if (filterError) {
            return;
        }

        this._setLoading(true);
        this.setState({
            searchErrorMessage: null,
            searchInfoMessage: null,
        });

        const pacCodigo = this.props.authContext.properties.user.pacCodigo;
        registrosMedicosClient.buscarListaEtiquetasMateriais(
            pacCodigo, 
            filtro.dtInicial,
            filtro.dtFinal,
            (res => {
                this._setLoading(false);
                const isFiltered = (filtro.dtInicial || filtro.dtFinal) ? true : false;
                const searchInfoMessage = res.data.etiquetasMateriais.length > 0 ? null :
                    (!isFiltered && utils.isUserInSyncAwayTime() ? <ThemeMessage messageId="_general_mensagem-possivel-sincronismo-pendente" /> : "Nenhuma etiqueta encontrada");
                this.setState({
                    isFiltered: isFiltered,
                    searchResult: res.data.etiquetasMateriais,
                    searchInfoMessage: searchInfoMessage
                });
            }),
            (err => {                      
                this._setLoading(false);
                this.setState({
                    searchResult: [], 
                    searchErrorMessage: "Ocorreu um erro ao processar sua requisição"
                });
            })
        );
    }

    _handleAccordionClick = (e, etiqueta) => {
        etiqueta.active = !etiqueta.active;
        this.setState({});
    }

    _handleClearFilter = () => {
        const { isFiltered } = this.state;
        this.setState({
            isFiltered: false,
            filterError: null,
            searchErrorMessage: null,
            searchInfoMessage: null,
        });

        if(isFiltered) {
            this._getListaEtiquetas();
        }
    }

    _handleCloseEtiqueta = () => {
        this.setState({ 
            showEtiqueta: false,
            listImagensEtiqueta: null
        });
    }

    _handleKeyDown = (event) => {
        if(this._getDadosVisualizacao()) {
            switch( event.keyCode ) {
                case ESCAPE_KEY:
                    event.preventDefault();
                    event.stopPropagation();
                    this._handleCloseEtiqueta();
                    break;
                default: 
                    break;
            }
        }
    }

    _handleUpdateFilter = () => {
        this.setState({ 
            filterError: null,
            searchErrorMessage: null,
            searchInfoMessage: null,
        });
    }

    _isStatusNovo = (statusLeitura) => {
        if (statusLeitura==='NAO_LIDO' || statusLeitura==='ATUALIZACAO_NAO_LIDA') {
            return true;
        }
        return false;
    }

    _setLoading = (visible) => {
        utils.setLoadingVisibility(this.props.appControllerContext, visible);
    }

    _updateDownloadError = (etiqueta, error) => {
        etiqueta.downloadError = error;
        this.setState(this.state);
    }

    RenderVisualizador = () => {
        const listaImages = this._getDadosVisualizacao();

        if(listaImages) {
            const items = [];
            const msgPaginas = `(Total ${listaImages.length} página` + (listaImages.length>1 ? "s" : "") + ")";
            const titulo = `Etiquetas ${msgPaginas}`;

            for(var pg=0; pg<listaImages.length; pg++) {
                const imageData = `data:image/png;base64,${listaImages[pg]}`;

                items.push(
                    <div key={`page_${pg}`}>
                        <img src={imageData} alt=""></img>
                    </div>
                );
                if(pg<(listaImages.length-1)) {
                    items.push(
                        <div key={`space_${pg}`} className="result-pagespace"></div>
                    );
                }
            }

            utils.setViewPortRescale(true);

            return (
                <div className="etiquetas-viwer-wrapper">
                    <ViewerModal 
                        title={<div className="titulo-modal">{titulo}</div>} 
                        onCloseButton={this._handleCloseEtiqueta}>
                        {items}
                    </ViewerModal>
                </div>
            );

        } else {
            utils.setViewPortRescale(false);
            return null;
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this._handleKeyDown, true);
        this._getListaEtiquetas();
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.state.showEtiqueta!==prevState.showEtiqueta) {
            this._appSetBackAction();
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this._handleKeyDown, true);
    }

    render() {
        const serviceConfig = getAppServiceConfigByName(this.props.themeConfig, APP_SERVICE_LIST.ETIQUETAS_MATERIAIS) || {};

        return(
            <AppCardModuleBasicWrapper moduleName="etiquetas-materiais">

                { this.RenderVisualizador() }

                { serviceConfig.mensagemDisponibilidade &&
                <AppMessageBox
                    id="msg-etiquetas-information" 
                    className="information aviso-disponibilidade"
                    messageData={{
                        header: <strong>Atenção:</strong>,
                        message: serviceConfig.mensagemDisponibilidade
                    }} />
                }

                <AppDateFilter
                    filterError={this.state.filterError}
                    onFilterClear={this._handleClearFilter}
                    onFilterUpdate={this._handleUpdateFilter}
                    onFilter={this._getListaEtiquetas}
                />

                {this.state.searchErrorMessage &&
                <AppMessageBox 
                    id="msg-etiquetas-error" 
                    className="error" 
                    messageData={{ message: this.state.searchErrorMessage }} />
                }

                {this.state.searchInfoMessage &&
                <AppMessageBox 
                    id="msg-etiquetas-information" 
                    className="information" 
                    messageData={{ message: this.state.searchInfoMessage }} />
                }

                <div className="etiquetas-section">
                    <Accordion fluid styled>
                        { this.state.searchResult.map((etiqueta, indxEtiqueta) => {
                            const indicativoNovaEtiqueta = this._isStatusNovo(etiqueta.statusLeitura);

                            return (
                                <div 
                                    key={'etiqueta-' + indxEtiqueta}
                                    className={`accordion-item${etiqueta.active ? " active": ""}${indicativoNovaEtiqueta ? " emphasis" : ""}`}
                                >
                                    <Accordion.Title onClick={(e) => this._handleAccordionClick(e, etiqueta)}>
                                        <div className="title-info-wrapper">
                                            { indicativoNovaEtiqueta &&
                                            <div className="indicativo-novo bold-text">[NOVO]</div>
                                            }
                                            <div className="information">
                                                <div className="caption bold-text">Data do procedimento:</div>
                                                <div className="data bold-text">
                                                    <Moment format="DD/MM/YYYY" >{etiqueta.dtReferencia}</Moment>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="collapsible-wrapper">
                                            <AccordionCollapsible active={etiqueta.active} iconVariant={indicativoNovaEtiqueta ? "new" : null} />
                                        </div>
                                    </Accordion.Title>
                                    <Accordion.Content>
                                        <div className={`content-row${indicativoNovaEtiqueta ? " novo" : ""}`}>
                                            <div className="etiquetas-wrapper">
                                                <div className="buttons-wrapper">
                                                    <button 
                                                        type="button"
                                                        className="btn-visualizar"
                                                        title="Visualizar etiqueta"
                                                        onClick={() => this._getEtiquetaMaterialPNG(etiqueta)}>
                                                        <ThemeImage 
                                                            module="etiquetasMateriais"
                                                            imageId={`icon_botao-visualizar${indicativoNovaEtiqueta ? "-new" : ""}`}
                                                            className="img-buttomimg" />

                                                    </button>                                               
                                                    <button 
                                                        type="button"
                                                        className="btn-baixar-pdf"
                                                        title="Baixar arquivo PDF da etiqueta"
                                                        onClick={() => this._getEtiquetaMaterialPDF(etiqueta)}>
                                                        <ThemeImage
                                                            module="etiquetasMateriais"
                                                            imageId={`icon_botao-pdf${indicativoNovaEtiqueta ? "-new" : ""}`}
                                                            className="img-buttomimg" />
                                                    </button>
                                                </div>
                                                <div className="info-wrapper">
                                                    <div className="etiqueta-titulo">
                                                        <span className="bold-text">Documento:</span><br/>
                                                        {pascalCase(etiqueta.titulo)}
                                                    </div>
                                                </div>                    
                                            </div>
                                        </div>

                                        { etiqueta.downloadError &&
                                        <div className="content-row">
                                            <div className="download-error">{etiqueta.downloadError}</div>
                                        </div>
                                        }
                                    </Accordion.Content>
                                </div>
                            )
                        })}
                    </Accordion>  

                    <div className="padding-rodape"></div>
                </div>  

            </AppCardModuleBasicWrapper>
        );
    }
}

export default EtiquetasMateriais;