/*
** @name: Meu Clínicas - requestNoAuth
** @author:
** @date:
** @description: Rotinas para chamadas REST sem autenticacao 
*/

import axios from 'axios';

class RequestNoAuth {

    constructor() {
        this.get = this.get.bind(this);
        this.post = this.post.bind(this);
    }

    post(resourceUrl, data, sucessCallback, errorCallback) {
        axios.post(resourceUrl, data).then(sucessCallback).catch(errorCallback);
    }

    put(resourceUrl, data, sucessCallback, errorCallback) {
        axios.put(resourceUrl, data).then(sucessCallback).catch(errorCallback);
    }

    get(resourceUrl, sucessCallback, errorCallback, queryParams) { 
        axios.get(resourceUrl, {params: queryParams}).then(sucessCallback).catch(errorCallback);
    }
}

const requestNoAuth = new RequestNoAuth();
export default requestNoAuth;