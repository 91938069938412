import React from "react";
import { Input } from 'semantic-ui-react';
import MaskedInput from 'react-text-mask';
import { getFieldContentClasses, FormBuilderSupportFields } from 'react-dj-forms-builder';

import utils from "../../../core/utils.js";

const FieldRender = (props) => {
    const { masked } = props;
    const params = { ...props };
    delete params.masked;
    if(utils.isObject(masked) && utils.isArray(masked.mask)) {
        return <MaskedInput {...params} {...masked} />;
    }
    return <input {...params} />;
}

const InputField = (props) => {
    const { id, name, label, afterLabel, placeHolder, floatingPlaceHolder, disabled, isPasswordInput, requiredMark, 
            onChange, value, errorMessage, maxSize, toUpperCase, toLowerCase, autoComplete, masked, disablePaste } = props;
    const inputClassName = errorMessage ? "error" : "";
    const wrapperClass = utils.isObject(masked) && utils.isArray(masked.mask) ? "masked-input-wrapper" : "input-wrapper";
    const extraParams = {};
    if(isPasswordInput) {
        extraParams.type = "password";
    }
    if(disablePaste) {
        extraParams.onPaste = e => e.preventDefault();
        extraParams.onDrop = e => e.preventDefault();
    }

    const _handleChange = (e, name, value) => {
        if(value) {
            if(maxSize && value.length > maxSize) {
                return;
            }
            if(toLowerCase) {
                value = value.toLowerCase();
            } else if(toUpperCase) {
                value = value.toUpperCase();
            }
        }
        onChange(e, { name, value });
    }

    return(
        <>
            { !floatingPlaceHolder && label &&
            <div className="field-label">{label}{requiredMark ? <FormBuilderSupportFields.RequiredMark /> : null}{afterLabel}</div>
            }
            <Input
                fluid
                children={
                    <div className={`${wrapperClass} ${getFieldContentClasses(value, errorMessage, disabled)}`}>
                        <FieldRender 
                            id={id}
                            name={name}
                            disabled={disabled}
                            className={inputClassName}
                            placeholder={floatingPlaceHolder ? null: placeHolder}
                            onChange={(e) => _handleChange(e, name, e.target.value)}
                            autoComplete={autoComplete ? autoComplete : name}
                            value={value ? value : ""}
                            masked={masked}
                            {...extraParams}
                        />
                        { floatingPlaceHolder && 
                        <div className='ui label label'>{placeHolder}</div>
                        }
                    </div>
                }
            />
            { errorMessage &&
            <div className="field-error">{errorMessage}</div>
            }
        </>
    );
}

export default InputField;