/*
** @name: Meu Clínicas - modalTermos
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Dezembro 2022
** @description: Módulo para renderizar modal com termos de uso para o cadastro
*/
import React from 'react';

import utils from '../../core/utils.js';
import { ThemeImage } from '../../core/appThemeUtils.js';
import AppModal from '../../components/general/appModal/appModal.js';


const _TERMOS_ACEITE_ = require('../../theme/resources/termosAceite.json');

const ModalTermos = (props) => {
    const { onClose } = props;
    const conteudoTermo = _TERMOS_ACEITE_.conteudo;
    const termosAceite = {
        __html: utils.isArray(conteudoTermo) ? conteudoTermo.join("\n") : null
    };

    return(
        <div id="termosModalWrapperId">
            <AppModal
                closeOnEsc={true}
                closeOnClickOutside={true}
                onClose={onClose}
                headerContent={
                    <div className="modal-header-wrapper">
                        <div className="close-button" onClick={onClose}>
                            <ThemeImage module="appCard" imageId="normal-icon_back" />
                        </div>
                    </div>
                }
            >
                <div className="termos-content-wrapper">
                    <div className="termos">
                        <div dangerouslySetInnerHTML={termosAceite} />
                    </div>
                </div>
            </AppModal>
        </div>
    );
}

export default ModalTermos;