import React, { Fragment } from 'react';

import 'moment-timezone';

import { ThemeImage } from '../../core/appThemeUtils.js';

const CardLaudosAtestados = (props) => {
    const { listaItens, habilitaDownload, siglasExibirDownload, onLaudoAtestadoShow, onLaudoAtestadoDownload } = props;

    return(
        <div className='lista-documentos-wrapper'>
            { (listaItens||[]).map((item, indx) => {
                const indicativoNaoLido = !item.visualizadoEm;
                const exibirItem = !siglasExibirDownload || !siglasExibirDownload.length || siglasExibirDownload.includes(item.atestadoSigla);
                if(!exibirItem) {
                    return null;
                }
                return (
                    <Fragment key={`item_${indx}`}>
                        <div className={`lista-laudos-wrapper content-row${indicativoNaoLido ? " novo" : ""}`}>
                            <div className="buttons-wrapper">
                                <button
                                    disabled={!habilitaDownload}
                                    type="button"
                                    className={`btn-visualizar${habilitaDownload ? "" : " disabled"}`}
                                    title="Visualizar solicitação"
                                    
                                    onClick={() => onLaudoAtestadoShow(item)}>
                                    <ThemeImage
                                        module="exames"
                                        imageId={`icon_botao-visualizar${indicativoNaoLido ? "-new" : ""}`}
                                        className="img-buttomimg" />
                                </button>
                                <button
                                    disabled={!habilitaDownload}
                                    type="button"
                                    className={`btn-baixar-pdf${habilitaDownload ? "" : " disabled"}`}
                                    title="Baixar arquivo da solicitação"
                                    onClick={() => onLaudoAtestadoDownload(item)}>
                                    <ThemeImage
                                        module="exames"
                                        imageId={`icon_botao-pdf${indicativoNaoLido ? "-new" : ""}`}
                                        className="img-buttomimg" />
                                </button>
                            </div>
                            <div className="info-wrapper">
                                { (item.atestadoSigla === "O" ? "Atestado " : item.atestadoTipoDescricao ) }
                                { item.downloadError && 
                                <div className="download-error">{item.downloadError}</div> }
                            </div>         
                        </div>
                    </Fragment>
                );
            }) }
        </div>
    )
}


export default CardLaudosAtestados;