/*
** @name: Meu Clínicas - appThemeUtils
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Novembro 2020
** @description: Prove alguns 'Function Components' e rotinas para uso com os temas
**      => <ThemeText {...props} /> - Renderiza um elemento de texto baseado em propriedades e configuração do tema
**      => <ThemeMessage {...props} /> - Renderiza um elemento de mensagem baseado em propriedades e configuração do tema
**      => <ThemeLink {...props} /> - Renderiza um elemento link/achor baseado em propriedades e configuração do tema
**      => <ThemeImage {...props} /> - Renderiza um elemento imagem baseada em propriedades e configuração do tema
**      => importThemeImage - Retorna o conteudo da imagem baseada nas propredades e configuração do tema
**      => isServiceEnable - Retorna informação de um servico está habilitado conforme configuração do tema
**
** @date: Favereiro 2022
** @description: Adicionada novo componente <ThemeMessage />
*/

import React from 'react';

import { getAppLinkByName, getAppConfigImagePath, getAppServiceConfigByName, getAppMessagesById } from './appThemeConfig.js';

import { useAppThemeContext } from './appThemeContext.js';
import { prepareMessage } from './stringUtils.js';
import utils from './utils.js';


const ThemeText = (props) => {
    /*
    =============================
    Parametros e exemplos de uso:
    =============================

    @config: Se for informada será considerada com objeto JSON de configuração para origem dos dados senão será usada a configuração de thema do contexto da aplicação 'AppThemeContext'.
    @elemType: [fragment | span | div] indica tipo do elemento para retorno do texto. Padrão é fragment
    @className: adicoonada ao elemento (caso não seja um fragment, esta propriedade quando será ignorada)
    @propertyPath: caminho para propriedade cujo valor será exibido
    @propertyName: opcional, será adiconado ao final da 'propertyPath'

    1) Equivalentes, retorna um elemento de texto com o valor da propriedade cabeacalho no caminho propA.grupo1.messages 
    da configuração do tema. className é opcional.

    <ThemeText 
        elemType="div"
        className="info-header"
        propertyPath="propA.grupo1.messages" 
        propertyName="cabeacalho" />

    <ThemeText 
        elemType="div"
        className="info-header"
        propertyPath="propA.grupo1.messages.cabeacalho" />

    */

    const { elemType, className, propertyPath, propertyName } = props;
    const fullPath = propertyPath + (propertyName ? `.${propertyName}` : "");
    const hookConfig = useAppThemeContext().getConfig();
    const currConfig = utils.isObject(props.config) ? props.config : hookConfig;
    let text;
    
    try {
        text = getConfigEntryByPath(currConfig, fullPath);
        if(!utils.isString(text)) {
            throw new Error(`Text value not found to render <ThemeText />.`);
        }

    } catch(e) {
        text = `Theme configuration property not found: ${fullPath}`;
        console.error(text);
    }

    let result;
    switch(elemType) {
        case 'div':
            result = <div className={className}>{text}</div>;
            break;
        case 'span':
            result = <span className={className}>{text}</span>;
            break;
        case 'fragment':
        default:
            result = <>{text}</>;
    }
    return result;
}

const ThemeMessage = (props) => {
    /*
    =============================
    Parametros e exemplos de uso:
    =============================

    @config: Opcional, se for informada será considerada com objeto JSON de configuração para origem dos dados senão será usada a configuração de thema do contexto da aplicação 'AppThemeContext'.
    @elemType: [fragment | span | div] indica tipo do elemento para retorno do texto. Padrão é fragment
    @className: adicoonada ao elemento (caso não seja um fragment, esta propriedade quando será ignorada)
    @messageId: Id do template da mensagem para exebição. (Localizada dentro das consufurações 'applicationMessages')
    @messageTemplate: Se infromado ignora messageId e usa como template para exibicao.
    @missingParameter: Opcional, se informado serve para substituir parametros caso os parametros sejam insuficientes, evitando excessão.
    @params: Opcional, array parametros para substituiçao (podendo ser outro componente) no template da mensagem {X}. Onde X é um números do parâmetro para substituição.

    ...
    mensagemTeste_001: "P0 = {0}, P1 = {1}, P2 = {2}"
    ...

    <ThemeText 
        messageId={"mensagemTeste_001"}
        params={["A", "B", <div>Componente</div>]} />

    Resultado: 
        <>
            P0 = A, P1 = B, P2 = <div>Componente</div>
        </>

    OU

    <ThemeText 
        messageTemplate={"Param2 = {2}, Param0 = {0}"}
        params={["A", "B", <div>Componente</div>]} />
    
    Resultado: 
        <>
            Param2 = <div>Componente</div>, Param0 = A
        </>

    TAGs Reservadaas (case insensitive):
        [br] => força uma quebra de linha
        [p] => força um novo parágrafo

        Para retornar valor literal da TAG faça por parâmetro, realizando substituição pelo valor desejado, como exemplo:

        ...
        mensagemTeste_002: "Teste para mostrar as TAGs {0} {1}."
        ...

        <ThemeText 
            messageId={mensagemTeste_002}
            params={["[br]", "[P]"]} />

        Resultado: Teste para mostrar as TAGs [br] [P].

    */

    const { elemType, className, messageId, params, missingParameter } = props;
    const hookConfig = useAppThemeContext().getConfig();
    const currConfig = utils.isObject(props.config) ? props.config : hookConfig;
    let message;
    
    try {
        let messageTemplate = props.messageTemplate;

        if(!utils.isString(messageTemplate)) {
            if(!messageId) {
                throw new Error(`Missing property 'messageId' or 'messageTemplate' to render <ThemeMessage />.`);
            }

            messageTemplate = getAppMessagesById(currConfig, messageId);
            if(!utils.isString(messageTemplate)) {
                throw new Error(`Message id '${messageId}' not found to render <ThemeMessage />.`);
            }
        }

        if(params && !utils.isArray(params)) {
            throw new Error("Invalid property params to render <ThemeMessage />. If informed it must be an array.");
        }

        message = prepareMessage(messageTemplate, params, missingParameter);

    } catch(e) {
        message = `ERRO: ${e.message}`;
        console.error(message);
    }

    let result;
    switch(elemType) {
        case 'div':
            result = <div className={className}>{message}</div>;
            break;
        case 'span':
            result = <span className={className}>{message}</span>;
            break;
        case 'fragment':
        default:
            result = <>{message}</>;
    }
    return result;
}

const ThemeLink = (props) => {
    /*
    =============================
    Parametros e exemplos de uso:
    =============================

    @config: Se for informada será considerada com objeto JSON de configuração para origem dos dados senão será usada a configuração de thema do contexto da aplicação 'AppThemeContext'.
    @linkName: key for link name in 'applicationLinks' section (applicationLinks[linkName])
    @target: [_blank|_self] same as HTML anchor target property
    @className: adicoonada ao elemento

    1) Retorna image para o módulo 'login' com id 'logo-rodape' adicionando className 'teste'.
    Em caso do arquivo ou configuração não encontrado vai dispara uma excessão.
    Evento onClick informado.

    <ThemeLink 
        linkName="portal-hospital"
        target="_blank"
        className="link" />

    */

    const { className, linkName, target } = props;
    const hookConfig = useAppThemeContext().getConfig();
    const currConfig = utils.isObject(props.config) ? props.config : hookConfig;

    try {
        let { link, text } = getAppLinkByName(currConfig, linkName);
        return(
            <a href={link} rel="noopener noreferrer" target={target}><div className={className}>{text}</div></a>
        )
    } catch(e) {
        const errorMessage = `Theme configuration link not found: ${linkName}`;
        console.error(errorMessage);
        return(
            <div>{errorMessage}</div>
        );
    }
}

const ThemeImage = (props) => {
    /*
    =============================
    Parametros e exemplos de uso:
    =============================

    @config: Se for informada será considerada com objeto JSON de configuração para origem dos dados senão será usada a configuração de thema do contexto da aplicação 'AppThemeContext'.
    @module: key for module name in 'imagesConfig' section (imagesConfig[module])
    @imageId: key for image id in 'imagesConfig[module]' section
    @className: adicoonada ao elemento
    @onClick: function to be executed when the click event wer triggered on the image
    @title: image tooltip

    1) Retorna image para o módulo 'login' com id 'logo-rodape' adicionando className 'teste'.
    Em caso do arquivo ou configuração não encontrado vai dispara uma excessão.
    Evento onClick informado.

    <ThemeImage 
        module="login" 
        imageId="logo-rodape" 
        className="teste" 
        onClick={() => { do something here ... })} />

    2) Retorna image para o módulo 'login' com id 'logo-rodape' adicionando className 'teste'.
    Em caso do arquivo ou configuração não encontrado retorna o que for definido em onErrorReturn.
    Se onErrorReturn for false, null ou não definido retorna {null}
    Sem evento onClick.
    Se onErrorReturn for informado implica em disableException=true.

    <ThemeImage 
        module="login" 
        imageId="logo-rodape" 
        className="teste" 
        disableException={true}
        onErrorReturn={errorImage} />

    */

    const { id, module, imageId, className, disableException, onErrorReturn, onClick, title, alt } = props;
    const hookConfig = useAppThemeContext().getConfig();
    const currConfig = utils.isObject(props.config) ? props.config : hookConfig;
    const image = importThemeImage(currConfig, module, imageId, (onErrorReturn || disableException ? true : false));
    if(image) {
        const params = { src: image, className: `ui image ${className}`, title, onClick };
        if(id) {
            params.id = id;
        }
        return <img {...params} alt={alt} />;
    }

    return(onErrorReturn ? onErrorReturn : null);
}

const getConfigEntryByPath = (currConfig, path) => {
    let entry = null;
    if(utils.isObject(currConfig) && utils.isString(path) && path.trim().length) {
        const pathParts = path.trim().split('.');
        entry = currConfig;
        pathParts.forEach(part => (entry = entry && entry[part]));
    }
    return entry ? entry : null;
}

const importThemeImage = (currConfig, module, imageId, disableException) => {
    let imgPath = null;
    try {
        imgPath = getAppConfigImagePath(currConfig, module, imageId);
    } catch(e) {
        if(!disableException) {
            throw new Error("Theme configuration not found.");
        }
        return null;
    }

    if(!imgPath && !disableException) {
        throw new Error("Theme configuration don't contains imageId: '" + imageId + "' for module: '" + module + "'");
    }

    try {
        const themeImages = require.context('../theme/images/', true);
        const image = (imgPath || !disableException) ? themeImages('./' + imgPath.replace(/^[./]*/, '')) : null;

        return utils.isObject(image) && image.default ? image.default : image;
    } catch(e) {
        if(!disableException) {
            throw new Error("Theme image file not found: theme/images/" + imgPath);
        }

        return null;
    }
}

const isServiceEnable = (currConfig, serviceName, exceptionOnMissingConfiguration) => {
    try {
        return getAppServiceConfigByName(currConfig, serviceName).enable;
    } catch(e) {
        if(exceptionOnMissingConfiguration) {
            throw new Error("Theme configuration don't contains service configuration property 'applicationServicesConfig.<service_name>' for '" + serviceName + "'");
        }
        return false;
    }
}


export { 
    ThemeText,
    ThemeMessage,
    ThemeLink,
    ThemeImage,
    getConfigEntryByPath,
    importThemeImage,
    isServiceEnable
};