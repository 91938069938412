/*
** @name: Meu Clínicas - addFile
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Dezembro 2022
** @description: Componente para renderizar botão de adicionar arquivos na lista e arquivos de upload
*/
import React from "react";

import { ThemeImage } from "../../../core/appThemeUtils";

const AddFile = (props) => {
    const { disabled, onSelectFile } = props;

    return(
        <div className={`mfs-button add-button ${disabled ? "disabled" : "enabled"}`}>
            <ThemeImage module="multiFileSelect" imageId="icon_add-file" onErrorReturn="Add" onClick={() => onSelectFile()} />
        </div>
    )
}

export default AddFile;