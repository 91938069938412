/*
** @name: Meu Clínicas - multiFileSelect
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Dezembro 2022
** @description: Componente para renderizar lista e arquivos de upload
*/
import React, { useState, useEffect, useCallback } from "react";

import utils from "../../../core/utils.js";
import { ThemeMessage } from "../../../core/appThemeUtils.js";
import AppConfirmationDialog from "../appConfirmationDialog/appConfirmationDialog.js";

import AddFile from "./addFile.js";
import DescriptionModal from "./descriptionModal.js";
import FileItem from "./fileItem.js";
import { formatFileSize } from "./tools.js";


// Import component styles
import './scss/multiFileSelect.scss';


let inputFile = null;

const MultiFileSelect = (props) => {
    const { className, maxFiles, maxFileSize, minFileDescriptionSize, fileAccept, sentFiles, onChange } = props;
    const [ fileTooLarge, setFileTooLarge ] = useState(false);
    const [ toConfirm, setToConfirm ] = useState(null);
    const [ listSent, setListSent ] = useState([]);
    const [ listAddFiles, setListAddFiles ] = useState([]);
    const [ listRemoveFiles, setListRemoveFiles ] = useState([]);
    const fileCount = listSent.length + listAddFiles.length;
    const maxFileText = formatFileSize(maxFileSize);
    const isAddFilesAllowed = fileCount < maxFiles; 

    const _addNewFile = (description) => {
        const { name, mimeType, fileBase64 } = toConfirm || {};
        if(name && mimeType && fileBase64) {
            const newList = [...listAddFiles];
            newList.push({ name, description, mimeType, fileBase64 });
            setListAddFiles(newList);
            onChange({ add: newList, remove: listRemoveFiles });
        }
        setToConfirm(null);
    }

    const _handleFileAdd = () => {
        if(isAddFilesAllowed) {
            if(inputFile && inputFile.click) {
                inputFile.click();
            }
        }
    }

    const _handleFileChange = useCallback(e => {
        const file = e.target.files[0];
        e.target.value = "";
        if(file) {
            const { name, size, type } = file;
            if(size <= maxFileSize) {
                const fr = new FileReader();
                fr.onload = ev => {
                    const fileBase64 = utils.base64Encode(ev.target.result);
                    setToConfirm({ name, mimeType: type, size, fileBase64, description: null });
                }
                fr.readAsArrayBuffer(file);
            } else {
                setFileTooLarge(true);               
            }
        }
    }, [maxFileSize]);

    const _handleRemoveNewFile = (indx) => {
        if(indx>=0 && indx<listAddFiles.length) {
            const newList = [...listAddFiles];
            newList.splice(indx, 1);
            setListAddFiles(newList);
            onChange({ add: newList, remove: listRemoveFiles });
        }
    }

    const _handleRemoveOldFile = (solrKey) => {
        if(listSent.find(elem => elem.solrKey===solrKey)) {
            const newSentList = listSent.filter(elem => elem.solrKey!==solrKey);
            const newRemoveList = [...listRemoveFiles];
            newRemoveList.push(solrKey);
            setListSent(newSentList);
            setListRemoveFiles(newRemoveList);
            onChange({ add: listAddFiles, remove: newRemoveList });
        }
    }

    useEffect(() => {
        inputFile = document.createElement('input');
        inputFile.id = "fileUploadId";
        inputFile.name = "file_upload";
        inputFile.type = "file";
        inputFile.accept = fileAccept;
        inputFile.className = "file-select-input";
        inputFile.style = "height: 0px; width: 0px; overflow: hidden;";
        inputFile.onchange = e => _handleFileChange(e);
        document.body.appendChild(inputFile);
        return () => {
            if(inputFile) {
                inputFile.remove();
                inputFile = null;
            }
        }
    }, [fileAccept, _handleFileChange]);

    useEffect(() => {
        const list = [];
        if(utils.isArray(sentFiles)) {
            sentFiles.forEach(sf => {
                if(utils.isObject(sf) && sf.solrKey) {
                    list.push({
                        solrKey: sf.solrKey,
                        mimeType: sf.mimeType,
                        description: sf.description,
                        originalName: sf.originalName
                    });
                }
            });
        }
        setListSent(list);
        setListRemoveFiles([]);
        setListAddFiles([]);
    }, [sentFiles]);

    return(
        <>
            { fileTooLarge &&
            <AppConfirmationDialog
                title={"ATENÇÃO"}
                message={<ThemeMessage messageId="teleatendimento_upload-file-too-large" params={[maxFileText]} />}
                onConfirm={() => setFileTooLarge(false)}
                onCancel={() => setFileTooLarge(false)}
                hideCancelButton={true} />
            }

            <div className={`file-select-list${className ? ` ${className}` : ""}`}>
                <div className="mfs-elem-grid">
                    { listSent.map((file, indx) => {
                        if(utils.isObject(file)) {
                            return <FileItem key={`savedFile_${indx}`} className="sent" description={file.description || file.originalName} mimeType={file.mimeType} onRemove={() => _handleRemoveOldFile(file.solrKey)} />;
                        } else {
                            return null;
                        }
                    }) }
                    { listSent.length>0 && <div className="flex-break"></div> }

                    { listAddFiles.map((file, indx) => {
                        return <FileItem key={`addFile_${indx}`} className="new" description={file.description || file.name} mimeType={file.mimeType} onRemove={() => _handleRemoveNewFile(indx)} />;
                    }) }

                    <AddFile disabled={!isAddFilesAllowed} onSelectFile={() => _handleFileAdd()} />

                    { listAddFiles.length>0 && <div className="flex-break"></div> }

                    { utils.isObject(toConfirm) &&
                    <DescriptionModal
                        minFileDescriptionSize={minFileDescriptionSize}
                        fileData={toConfirm}
                        onCancel={() => setToConfirm(null)}
                        onConfirm={_addNewFile} />
                    }
                </div>
            </div>
        </>            
    );
}

export default MultiFileSelect;
