import React from 'react';

import { isServiceEnable, ThemeImage } from '../../core/appThemeUtils.js';
import { APP_SERVICE_LIST } from '../../core/appServiceList.js';


const ExtraActions = (props) => {
    const { themeConfig, onVoltar, onCadastro, onRecuperarSenha, onAjuda } = props;

    return (
        <div className="extra-actions">
            { onVoltar && 
            <div className="button-wrapper">
                <ThemeImage 
                    module="login"
                    imageId="icon_voltar"
                    className="action-image" 
                    onClick={onVoltar} />
            </div>
            }

            { (onCadastro && isServiceEnable(themeConfig, APP_SERVICE_LIST.CADASTRO)) && 
            <div className="button-wrapper">
                <ThemeImage 
                    module="login"
                    imageId="icon_cadastre-se"
                    className="action-image" 
                    onClick={onCadastro} />
            </div>
            }

            { (onRecuperarSenha && isServiceEnable(themeConfig, APP_SERVICE_LIST.RECUPERAR_SENHA)) &&
            <div className="button-wrapper">
                <ThemeImage 
                    module="login"
                    imageId="icon_recuperar-senha"
                    className="action-image" 
                    onClick={onRecuperarSenha} />
            </div>
            }

            { (onAjuda && isServiceEnable(themeConfig, APP_SERVICE_LIST.FAQ)) && 
            <div className="button-wrapper">
                <ThemeImage
                    module="login"
                    imageId="icon_ajuda"
                    className="action-image"
                    onClick={onAjuda} />
            </div>
            }
        </div>  
    );
}

export default ExtraActions;