/*
** @name: Meu Clínicas - appLoading
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2021
** @description: Componente para render da modal indicativa de processando/carregando
*/

import React from 'react';

import { ThemeImage } from '../../../core/appThemeUtils.js';

// Import module styles
import './appLoading.scss'; 


const AppLoading = (props) => {
    return(
        <div id="loadingWrapperId">
            <div className="loading-frame">
                <div className="loading-box">
                    <ThemeImage 
                        module="appLoading"
                        imageId="loading-indicator" 
                        className="loading"
                        disableException={true} />
                </div>
            </div>
        </div>
    )
}

export default AppLoading;