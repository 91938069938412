/*
** @name: Meu Clínicas - registerDone
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Dezembro 2022
** @description: Módulo para renderizar finalizacao do cadastro
*/
import React from "react";

import { ThemeImage } from '../../core/appThemeUtils.js';


const RegisterDone = (props) => {
    const { onClose } = props;
    return (
        <div className="confirmacao-registro">
            <div className="info-principal">
                <h2>Cadastro realizado com sucesso!</h2>
            </div>

            <div className="email-enviado">
                <ThemeImage module="cadastro" imageId="logo_email-ativacao" />
            </div>

            <div className="info-adicional">
                <p>Te enviamos um e-mail para a ativação de sua conta.</p>
            </div>

            <div className="main-action">
                <button id="button-registro-ok" className="app-form-button" onClick={onClose}>OK</button>
            </div>
        </div>
    )
}

export default RegisterDone;
