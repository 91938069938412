/*
** @name: Meu Clínicas - allServices
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: MArço 2021
** @description: Módulo para listar todos serviços disponiveis ao paciente
*/

import React, { Component } from 'react';
import $ from 'jquery';

import utils from '../../core/utils.js';
import { useAuthContext } from '../../core/authContext.js';
import { useAppThemeContext } from '../../core/appThemeContext.js';
import { getAppServiceConfigByName, getAppServicesConfig } from '../../core/appThemeConfig.js';
import { getAppServiceIdByConfigName } from '../../core/appServiceList.js';
import { APP_SERVICE_LIST } from '../../core/appServiceList.js';

import { hideService, NavigationServiceButton } from '../../components/general/appNavigationControls/appNavigationControls.js';


// Import module styles
import './allServices.scss'; 


const ServiceButtonList = (props) => {
    const { serviceList } = props;
    const authContext = useAuthContext();
    const themeConfig = useAppThemeContext().getConfig();
    const servicesConfig = getAppServicesConfig(themeConfig);
    const user = authContext.properties.user;

    return(
        <div id="menuButtonsSectionId">
            <div id="navigationButtonsWrapperId">
                <ul id="navigationButtonsListId">
                { serviceList.map((serviceName, indx) => {
                    if(!servicesConfig[serviceName] || hideService(user, themeConfig, serviceName)) {
                        return null;
                    }
                        
                    return(
                        <li key={`serviceKey_${indx}`}>
                            <NavigationServiceButton
                                serviceName={serviceName}
                                hidden={false}
                                useAlternativeImage={props.useAlternativeImage}
                                useCardStyle={props.useButtonCardStyle}
                            />
                        </li>
                    );
                })
                }
                </ul>
            </div>
        </div>
    );
}

const AllServices = (props) => {
    const themeConfig = useAppThemeContext().getConfig();
    return(
        <AllServicesImplem 
            themeConfig={themeConfig}
            {...props}
        />
    )
}

class AllServicesImplem extends Component {
    constructor(props) {
        super(props);

        this.state = { };
    }

    _shouldRenderServiceButton = (servicesConfig, name) => {
        const svcConfig = (servicesConfig && servicesConfig[name]) || {};
        const serviceId = getAppServiceIdByConfigName(name);
        return serviceId && svcConfig.showOnAllServices;
    }

    _updateButtonsWrapper = () => {
        const buttonsWrapper = $(".all-services-wrapper #navigationButtonsWrapperId");
        const serviceConfig = getAppServiceConfigByName(this.props.themeConfig, APP_SERVICE_LIST.ALL_SERVICES);
        const { buttonSize, largeButtonSize, spacing, minWidthToSizeChange } = this._validadeButtonConfig(serviceConfig.buttonConfiguration);
        const totalButtons = $("#navigationButtonsListId li").length;
        const normalMaxWidth = totalButtons * (buttonSize + spacing);
        const largeMaxWidth = totalButtons * (largeButtonSize + spacing);
        const vpWidth = Math.trunc($("body").width());
        buttonsWrapper.css({ 
            "max-width": `${vpWidth < minWidthToSizeChange ? normalMaxWidth : largeMaxWidth}px` 
        });
    }

    _validadeButtonConfig = (btnConfig) => {
        if(!utils.isObject(btnConfig) ||
                !utils.isInteger(btnConfig.buttonSize) || !utils.isInteger(btnConfig.largeButtonSize) || 
                !utils.isInteger(btnConfig.spacing) || !utils.isInteger(btnConfig.minWidthToSizeChange)) {
            throw new Error("Missing or incomplete All Services module 'allServices.buttonConfiguration'");
        }
        return btnConfig;
    }

    componentDidMount() {
        $(window).on("resize", this._updateButtonsWrapper);
    }

    componentWillUnmount() {
        $(window).off("resize", this._updateButtonsWrapper);
    }

    render() {
        const { themeConfig } = this.props;
        const servicesConfig = getAppServicesConfig(themeConfig);
        const serviceConfig = getAppServiceConfigByName(this.props.themeConfig, APP_SERVICE_LIST.ALL_SERVICES);
        let serviceList = serviceConfig.servicesList || [];
        
        // Adicionar ao fim da lista os os demais servicos não priorizados
        Object.keys(servicesConfig).forEach(key => {
            if(!utils.inArray(key, serviceList)) {
                serviceList.push(key);
            }
        });

        // Remover serviços que nao devem ter o botao renderizados
        serviceList = serviceList.filter(serviceName => this._shouldRenderServiceButton(servicesConfig, serviceName));

        return(
            <div className="all-services-wrapper">

                <ServiceButtonList 
                    serviceList={serviceList}
                    useAlternativeImage={false}
                    useButtonCardStyle={true} />

            </div>
        );
    }
}

export default AllServices;

export {
    ServiceButtonList
}