/*
** @name: Meu Clínicas - newsEditList
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Fevereiro 2022
** @description: Módulo para listagem e edicao de notícias
** 
*/

import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';

import { ThemeImage } from '../../core/appThemeUtils.js';

import ImageCarousel, { getImageType } from '../../components/general/imageCarousel/imageCarousel.js';
import ToggleButton from '../../components/fields/toggleButton/toggleButton.js';

import { EXHIBIT_LOCATION } from './exhibitLocation.js';


// Import module styles
import './scss/newsEditList.scss';


const NewsEditList = (props) => {
    const { newsList, checkItemChanged, checkItemImageType, onToggleVisibility, onItemInsert, onItemEdit, onItemDelete, onSetOrder, onSwapOrder } = props;
    const [orderInputValue, setOrderInputValue] = useState("");
    const [orderEditingIndex, setOrderEditingIndex] = useState(null);
    const [previewIndex, setPreviewIndex] = useState(null);

    const _editOrder = (index, objId) => {
        if(orderEditingIndex!==index) {
            setTimeout(() => {
                const obj = document.getElementById(objId);
                if(obj) {
                    obj.focus();
                }
            }, 10);
        }
        setOrderInputValue(index);
        setOrderEditingIndex(orderEditingIndex!==index ? index : null);
    }

    const _inputOrderChange = (value) => {
        const regEx = /^[0-9]{0,3}$/;
        if(!value || regEx.test(value)) {
            setOrderInputValue(value);
        }
    }

    const _newsSetOrder = (index) => {
        if(/^[0-9]{1,3}$/.test(orderInputValue)) {
            const newOrder = parseInt(orderInputValue);
            onSetOrder(index, newOrder);
            setOrderEditingIndex(null);
        }
    }

    const _previewShow = (index) => {
        setPreviewIndex(index);
    }

    const _previewHide = (e) => {
        if(e.target === e.currentTarget) {
            setPreviewIndex(null);
        }
    }

    useEffect(() => {
        setOrderInputValue("");
        setOrderEditingIndex(null);
    }, [newsList]);

    if(!newsList) {
        return null;
    }

    return(
        <div id="newsEditListId">
            { newsList.map((item, indx) => {
                const onOrderUp = indx > 0 ? () => onSwapOrder(indx, indx-1) : null;
                const onOrderDown = indx < (newsList.length-1) ? () => onSwapOrder(indx, indx+1) : null;
                const { imagens, ordem, visivel } = item;
                const itemChanged = checkItemChanged(item, ["ordem", "visivel"]);
                const imageTypeError = !checkItemImageType(imagens);
                const inputChangeOrderId = `changeOrder_${indx}`;
                const enableChangeConfirmation = /^[0-9]{1,3}$/.test(orderInputValue);
                const enablePreview = (imagens && imagens.length > 0 && !imageTypeError) ? true : false;
                const imageType = getImageType(imagens[0].imageBase64);
                const thumbnailData = (imagens && imagens.length > 0) ? `data:${imageType ? imageType : "image"};base64,${imagens[0].imageBase64}` : null;

                return(
                    <div key={`news_${indx}`} className={`news-content-row${visivel ? " visible" : ""}${itemChanged ? " changed" : ""}`}>
                        <div className="news-item-block">

                            <div className="inset-before" onClick={() => onItemInsert(indx)}>+</div>

                            <div className="action-wrapper">
                                <div className="order-wrapper">
                                    <div className="current-order-wrapper">
                                        <div className="order-value" onClick={() => _editOrder(indx, inputChangeOrderId)}>{ordem}</div>
                                        <div className={`edit-order-wrapper${indx===orderEditingIndex ? " enabled" : ""}`}>
                                            <input 
                                                id={inputChangeOrderId}
                                                name="changeOrder"
                                                className="change-order-input"
                                                type="text"
                                                placeholder=""
                                                autoComplete="off"
                                                onChange={(e) => { _inputOrderChange(e.target.value) }}
                                                value={orderInputValue} />
                                            <div
                                                className={`change-order-ok${enableChangeConfirmation ? " enabled" : ""}`} 
                                                onClick={enableChangeConfirmation ? () => _newsSetOrder(indx) : null}>Ok</div>
                                        </div>
                                    </div>
                                    <div className="order-change-wrapper">
                                        <div className={`order-up${onOrderUp ? "" : " disabled"}`} onClick={onOrderUp}>
                                            <ThemeImage module="news-manager" imageId="icon_order-up" title="Diminuir ordem" />
                                        </div>
                                        <div className={`order-down${onOrderDown ? "" : " disabled"}`} onClick={onOrderDown}>
                                            <ThemeImage module="news-manager" imageId="icon_order-down" title="Aumentar ordem" />
                                        </div>
                                    </div>
                                </div>

                                <div className="right-block-wrapper">
                                    <div className="info-row item-visibility">
                                        <span className="caption">Visível:</span>
                                        <div className="visibility-toggle">
                                            <ToggleButton
                                                id={`switchId_${indx}`}
                                                value={visivel}
                                                disabled={imageTypeError}
                                                onToggle={() => onToggleVisibility(indx)}
                                            /> 
                                        </div>
                                    </div>
                                    <div className="info-row">
                                        <span className="caption">Criação:</span>
                                        <div className="value">{item.criadoEm ? <Moment format="DD/MM/YYYY HH:mm:ss" >{item.criadoEm}</Moment> : ""}</div>
                                    </div>
                                    <div className="info-row">
                                        <span className="caption">Ultima atualização:</span>
                                        <div className="value">{item.atualizadoEm ? <Moment format="DD/MM/YYYY HH:mm:ss" >{item.atualizadoEm}</Moment> : ""}</div>
                                    </div>
                                    <div className="info-row">
                                        <span className="caption">Onde exibir:</span>
                                        <div className="value">{item.exhibitLocation ? EXHIBIT_LOCATION[item.exhibitLocation] : <span className="missing-value">NÃO INFORMADO</span>}</div>
                                    </div>
                                    <div className="info-row">
                                        <span className="caption">Imagens carrossel:</span>
                                        <div className="value">{imagens ? imagens.length : 0}</div>
                                    </div>
                                    { imageTypeError &&
                                    <div className="image-error">
                                        <div className="value">Item possui uma ou mais imagens de tipo inválido</div>
                                    </div>
                                    }
                                    <div className="buttons-wrapper">
                                        { enablePreview &&
                                        <div className="item-preview" title="Visualizar em tamanho real" onClick={() => _previewShow(indx)}>
                                            <ThemeImage module="news-manager" imageId="icon_item-preview" />
                                        </div>
                                        }
                                        { onItemEdit &&
                                        <div className="item-edit" title="Editar imagens" onClick={() => onItemEdit(item, indx)}>
                                            <ThemeImage module="news-manager" imageId="icon_item-edit" />
                                        </div>
                                        }
                                        { onItemDelete &&
                                        <div className="item-delete" title="Deletar item" onClick={() => onItemDelete(item, indx)}>
                                            <ThemeImage module="news-manager" imageId="icon_item-delete" />
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="news-wrapper">

                                { thumbnailData &&
                                <div 
                                    className={`news-thumbnail visible${enablePreview ? " peview-allowed" : ""}`} 
                                    onClick={enablePreview ? () => _previewShow(indx) : null}>
                                    <img src={thumbnailData} alt="" />
                                </div>
                                }

                                { (enablePreview && indx===previewIndex) &&
                                <div id="previewWrapperId" className="full-preview" onClick={e => _previewHide(e)}>
                                    <div className="carousel-wrapper">
                                        <ImageCarousel 
                                            keyBase="carousel" 
                                            interval={10000}
                                            imageList={imagens} />
                                    </div>
                                </div>
                                }
                            </div>

                            { indx === (newsList.length-1) &&
                            <div className="inset-after" onClick={() => onItemInsert(indx+1)}>+</div>
                            }
                        </div>
                    </div> 
                );
            })}
        </div>
    );
}

export default NewsEditList;