/*
** @name: Meu Clínicas - pesquisaExperiencia
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Setembro 2020
** @description: Módulo para criar formulários de pesquisa de experiência do paciente
**
** @update: Março 2021
** @description: Atualizado para novo layout da aplicação e funcionamento com cards
**
** @update: Junho 2021 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Atualizando tratamento do retorno de erro para novo sistema de validacao
*/

import React, { Component } from 'react';
import FormBuilder, { setConfigFieldProperty, setConfigFieldCustomComponent } from 'react-dj-forms-builder';
import { Form } from 'semantic-ui-react';

import utils from '../../core/utils.js';
import sessionStorageManager from '../../core/sessionStorageManager.js';
import { useAuthContext } from '../../core/authContext.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';
import { ThemeLink, ThemeMessage, ThemeText } from '../../core/appThemeUtils.js';
import { emptyToNull, pascalCase } from '../../core/stringUtils.js';

import formulariosClient from '../../apiClients/formularios/formulariosClient.js';

import AppCardModuleBasicWrapper from '../../components/general/appCardModuleBasicWrapper/appCardModuleBasicWrapper.js';
import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';
import { DateWithDatePicker, CheckboxField, DropdownField, RadioGroupField, InputField, ScoreMeter } from '../../components/fields/formsBuilderCustoms/';


// Import module styles
import './pesquisaExperiencia.scss'; 

// Import form configuration
import formConfigSelecao from './pesqSelecao.json';
import formConfigAmbulatorio from './pesqAmbulatorio.json';
import formConfigExames from './pesqExames.json';
import formConfigInternacao from './pesqInternacao.json';
import formConfigTeleconsulta from './pesqTeleconsulta.json';


const STEP_SELECAO = 'selecao';
const STEP_PESQUISA_AMBULATORIAL = 'ambulatorio';
const STEP_PESQUISA_EXAME = 'exames';
const STEP_PESQUISA_INTERNACAO = 'internacao';
const STEP_PESQUISA_TELEATENDIMENTO = 'teleatendimento';

const Instruction = () => {
    return(
        <>
            <ThemeMessage
                elemType="div"
                className="instruction-message"
                messageId="pesq-experiencia_intrucoes-preenchimento"
            />
            <div className="instruction-required">*Obrigatório</div>
        </>
    )
}

const Footer = () => {
    return(
        <ThemeMessage
            elemType="div"
            className="footer-content"
            messageId="pesq-experiencia_footer"
            params={[
                <ThemeLink linkName="portal-hospital" target="_blank" className="link" />,
                <ThemeLink linkName="email-ouvidoria" className="link" />,
                <ThemeText propertyPath="generalConfig" propertyName="pesquisa-experiencia-ouvidoria-presencial" />,
                <ThemeLink linkName="pesquisa-experiencia-contato-telefone" className="link" />
            ]}
        />
    )
}

const MensagemSucesso = () => {
    return (
        <div className="information-section">
            <div className="section-box">
                <div className="section-content">
                    <div className="sucesso">Sucesso</div>
                    <ThemeMessage messageId="pesq-experiencia_sucesso" />
                </div>
            </div>
        </div>
    )
}

const PesquisaExperiencia = (props) => {
    const authContext = useAuthContext();
    const appControllerContext = useAppControllerContext();
    return(
        <PesquisaExperienciaImplem
            authContext={authContext}
            appControllerContext={appControllerContext}
            {...props}
        />
    )
}

class PesquisaExperienciaImplem extends Component {
    constructor(props) {
        super(props);

        const { selecaoPesquisa, disableSelecao } = sessionStorageManager.navigation.getCurrentCardModuleParameters() || {};
        this._configureForms(selecaoPesquisa, disableSelecao);
        this.state = {
            currentStep: STEP_SELECAO,
            fields: null,
            resultadoRegistro: null,
            erroRegistro: null,
            renderForm: true,
            exibirMensagemSelecaoPesquisa: disableSelecao ? false : true
        }
    }

    _appSetBackAction = () => {
        const rnIntegration = window.rnIntegration;
        if(rnIntegration && rnIntegration.isAppRunning()) {
            const { currentStep } = this.state;
            this.props.appControllerContext.methods.doResetAppBackAction();
            if(currentStep!==STEP_SELECAO) {
                rnIntegration.backAction.push(() => { this._handleVoltar() });
            }
        }
    }

    _buildRequest = () => {
        const user = this.props.authContext.properties.user;
        const fields = this.state.fields;
        if(!user || !fields || fields.constructor!==Object) {
            return;
        }

        let fieldAnswerKey = "";
        switch(fields["selecaoPesquisa"].value) { // mapeamento para campo do backend
            case "AMB": fieldAnswerKey = "ambulatorio"; break;
            case "EXM": fieldAnswerKey = "exames"; break;
            case "INT": fieldAnswerKey = "internacao"; break;
            case "TELE": fieldAnswerKey = "teleatendimento"; break;
            default: return;
        }

        const request = {};
        request["selecaoPesquisa"] = fields["selecaoPesquisa"].value;
        request["pesquisaAnonima"] = fields["pesquisaAnonima"].value;
        request["pacCodigo"] = user.pacCodigo;
        request["prontuarioPaciente"] = user.prontuario;
        request["nomePaciente"] = pascalCase(user.nome);
        request["emailPaciente"] = user.email;
        request["telefonePaciente"] = user.celular;

        request["indicacaoHCPA"] = fields["indicacaoHCPA"].value;
        request["elogio"] = emptyToNull(fields["elogio"].value);
        request["sugestao"] = emptyToNull(fields["sugestao"].value);
        request["critica"] = emptyToNull(fields["critica"].value);

        const ignoreFiels = ["selecaoPesquisa", "pesquisaAnonima", "indicacaoHCPA", "elogio", "sugestao", "critica"];
        const dateFields = ["dataNascimento"];
        request[fieldAnswerKey] = {};
        Object.keys(fields).forEach((key) => {
            if(!ignoreFiels.includes(key)) {
                let value = fields[key].value;
                if(value && dateFields.includes(key)) {
                    const date = utils.stringToDateBR(value);
                    value = date ? date.getTime() : null;
                }
                request[fieldAnswerKey][key] = value;
            }
        });

        return request;
    }

    _clearFieldsError = () => {
        const updateFields = this.state.fields;
        if(utils.isObject(updateFields)) {
            Object.keys(updateFields).forEach(key => {
                updateFields[key].errorMessage = null;
            });
            this.setState({ fields: updateFields });
        }
    }

    _configureForms = (selecaoPesquisa, disableSelecao) => {
        setConfigFieldProperty(formConfigSelecao, ["selecaoPesquisa"], "value", selecaoPesquisa);
        setConfigFieldProperty(formConfigSelecao, ["selecaoPesquisa"], "disabled", ((selecaoPesquisa && disableSelecao) ? true : false));
        setConfigFieldCustomComponent(formConfigAmbulatorio, 
            ["ambPergunta1", "ambPergunta2", "ambPergunta3", "ambPergunta5",
             "ambPergunta6", "ambPergunta7", "ambPergunta8", "ambPergunta9", "ambPergunta10",
             "ambPergunta11", "ambPergunta12", "ambPergunta13", "ambPergunta14", "indicacaoHCPA"], ScoreMeter);
        setConfigFieldCustomComponent(formConfigExames, 
            ["exmPergunta1", "exmPergunta2", "exmPergunta3", "exmPergunta4", "exmPergunta5", 
             "exmPergunta6", "exmPergunta7", "exmPergunta8", "indicacaoHCPA"], ScoreMeter);
        setConfigFieldCustomComponent(formConfigInternacao, 
            ["intPerguntaA1", "intPerguntaA2", "intPerguntaA3", "intPerguntaA4",
             "intPerguntaB1", "intPerguntaB2", "intPerguntaB3", "intPerguntaB4", "intPerguntaB5",
             "intPerguntaC1", "intPerguntaC2", "intPerguntaC3", "intPerguntaC4",
             "indicacaoHCPA"], ScoreMeter);
        setConfigFieldCustomComponent(formConfigTeleconsulta, 
            ["telePergunta1", "telePergunta2", "telePergunta3", "telePergunta5",
             "telePergunta6", "telePergunta7", "telePergunta8", "indicacaoHCPA"], ScoreMeter);

        setConfigFieldCustomComponent(formConfigAmbulatorio, ["dataNascimento"], DateWithDatePicker);
        setConfigFieldCustomComponent(formConfigExames, ["dataNascimento"], DateWithDatePicker);
        setConfigFieldCustomComponent(formConfigInternacao, ["dataNascimento"], DateWithDatePicker);
        setConfigFieldCustomComponent(formConfigTeleconsulta, ["dataNascimento"], DateWithDatePicker);
    }

    _getConfigPesquisa = () => {
        const { exibirMensagemSelecaoPesquisa } =  this.state;
        let configPesquisa = null;

        switch(this.state.currentStep) {
            case STEP_SELECAO:
                configPesquisa = {
                    formConfig: Object.assign({}, formConfigSelecao),
                    className: "selecao-section",
                    title: "Seleção da Pesquisa de Experiência",
                    instruction: <ThemeMessage 
                                    messageId="pesq-experiencia_intrucoes-iniciais" 
                                    params={[exibirMensagemSelecaoPesquisa ? <ThemeMessage messageId="pesq-experiencia_intrucoes-iniciais-selecao" /> : ""]} />,
                    buttonMessage: "Avançar",
                    enableVoltar: false
                }
                break;
            case STEP_PESQUISA_AMBULATORIAL:
                configPesquisa = {
                    formConfig: Object.assign({}, formConfigAmbulatorio),
                    className: "pesquisa-section pesquisa-ambulatorial",
                    title: "Experiência do Paciente no Atendimento Ambulatorial",
                    instruction: <Instruction />,
                    footer: <Footer />,
                    buttonMessage: "Enviar",
                    enableVoltar: true
                }
                break;
            case STEP_PESQUISA_EXAME:
                configPesquisa = {
                    formConfig: Object.assign({}, formConfigExames),
                    className: "pesquisa-section pesquisa-exame",
                    title: "Experiência do Paciente no Atendimento de Exames",
                    instruction: <Instruction />,
                    footer: <Footer />,
                    buttonMessage: "Enviar",
                    enableVoltar: true
                }
                break;
            case STEP_PESQUISA_INTERNACAO:
                configPesquisa = {
                    formConfig: Object.assign({}, formConfigInternacao),
                    className: "pesquisa-section pesquisa-internacao",
                    title: "Experiência do Paciente no Atendimento de Internação",
                    instruction: <Instruction />,
                    footer: <Footer />,
                    buttonMessage: "Enviar",
                    enableVoltar: true
                }
                break;
            case STEP_PESQUISA_TELEATENDIMENTO:
                configPesquisa = {
                    formConfig: Object.assign({}, formConfigTeleconsulta),
                    className: "pesquisa-section pesquisa-teleatendimento",
                    title: "Experiência do Paciente na Consulta Remota",
                    instruction: <Instruction />,
                    footer: <Footer />,
                    buttonMessage: "Enviar",
                    enableVoltar: true
                }
                break;
            default:
                return null;
        }
    
        return configPesquisa;
    }
    
    _handleAction = () => {
        let newState = this.state.currentStep;
        const updateConfigDataNascimento = (config) => {
            const user = this.props.authContext.properties.user;
            const dataNascimento = user && user.dtNascimento ? user.dtNascimento : null;
            const anonymous = this._isAnonymous();
            if(dataNascimento) { // Em teoria todos usuarios devem ter data de nascimento no cadastro
                setConfigFieldProperty(config, ["dataNascimento"], "datePicker.enabled", false);
                setConfigFieldProperty(config, ["dataNascimento"], "value", dataNascimento);
                setConfigFieldProperty(config, ["informarDataNascimento"], "value", !anonymous);
                setConfigFieldProperty(config, ["informarDataNascimento"], "disabled", !anonymous);
                this._setFieldState("dataNascimento", dataNascimento, undefined, true);
                this._setFieldState("informarDataNascimento", !anonymous, undefined, !anonymous);
            } else {
                setConfigFieldProperty(config, ["dataNascimento"], "datePicker.enabled", true);
                setConfigFieldProperty(config, ["dataNascimento"], "disabled", false);
                setConfigFieldProperty(config, ["dataNascimento"], "value", null);
                setConfigFieldProperty(config, ["informarDataNascimento"], "disabled", false);
                setConfigFieldProperty(config, ["informarDataNascimento"], "value", false);
                this._setFieldState("dataNascimento", null, undefined, false);
                this._setFieldState("informarDataNascimento", !anonymous, undefined, false);
            }
        }

        switch(this.state.currentStep) {
            case STEP_SELECAO:
                const selecao = this.state.fields.selecaoPesquisa;
                if(selecao) {
                    switch(selecao.value) {
                        case "AMB": 
                            newState = STEP_PESQUISA_AMBULATORIAL;
                            updateConfigDataNascimento(formConfigAmbulatorio);
                            break;
                        case "EXM": 
                            newState = STEP_PESQUISA_EXAME; 
                            updateConfigDataNascimento(formConfigExames);
                            break;
                        case "INT":
                            newState = STEP_PESQUISA_INTERNACAO;
                            updateConfigDataNascimento(formConfigInternacao);
                            break;
                        case "TELE":
                            newState = STEP_PESQUISA_TELEATENDIMENTO;
                            updateConfigDataNascimento(formConfigTeleconsulta);
                            break;
                        default:
                            this._setFieldState("selecaoPesquisa", undefined, "Você deve escolher uma pesquisa");
                            break;
                    }
                }
                break;
            case STEP_PESQUISA_AMBULATORIAL:
            case STEP_PESQUISA_EXAME:
            case STEP_PESQUISA_INTERNACAO:
            case STEP_PESQUISA_TELEATENDIMENTO:
                this._registrarPesquisa();
                break;
            default:
                return null;
        }

        if(newState !== this.state.currentStep) {
            this.setState({ 
                currentStep: newState
            });
        }
    }

    _handleClose = () => {
        this.props.appControllerContext.methods.doCardFadeOut();
    }

    _handleFormUpdate = (fields) => {
        this.setState({ fields: fields });
    }

    _handleVoltar = () => {
        this.setState({
            currentStep: STEP_SELECAO,
            resultadoRegistro: null,
            erroRegistro: null,
            fields: null,
            renderForm: false
        });
    }

    _isAnonymous = () => {
        const { pesquisaAnonima } = this.state.fields || {};
        return (pesquisaAnonima && pesquisaAnonima.value) ? true : false;
    }

    _processaErrosFormulario = (erros) => {
        if(erros && erros.constructor === Object) {
            const updateFields = this.state.fields;
            Object.keys(erros).forEach((key) => {
                if(key!=="erroGeral") {
                    let message = erros[key];
                    if(!updateFields[key]) {
                        updateFields[key] = {
                            value: null,
                            errorMessage: null
                        }
                    }
                    updateFields[key].errorMessage = message;
                }
            });
            this.setState({
                fields: updateFields
            });
        }
    }

    _registrarPesquisa = () => {
        this._setLoading(true);
        this._setErroRegistro(null);
        this._clearFieldsError();

        const request = this._buildRequest();

        formulariosClient.pesquisaExperiencia(
            request,
            (res => {              
                const result = res.data;
                this._setLoading(false);
                this.setState({ resultadoRegistro: result });

                if(!result.valid) {
                    const validatorResponse = result.validatorResponse || {};
                    if(validatorResponse.errors && validatorResponse.errors.erroGeral) {
                        this._setErroRegistro(validatorResponse.errors.erroGeral);
                    } else {
                        this._setErroRegistro("Por favor, verifique o correto preenchimento do formulário.");
                    }
                    this._processaErrosFormulario(validatorResponse.errors);
                }
            }),
            (err => { 
                this._setLoading(false);
                this._setErroRegistro("Ops!, ocorreu um erro registrando sua pesquisa.");
            })
        );
    }

    _setErroRegistro = (msg) => {
        this.setState({ erroRegistro: msg });
    }

    _setFieldState = (fieldName, value, errorMessage, disabled) => {
        const updateFields = Object.assign({}, this.state.fields);
        if(!updateFields[fieldName]) {
            updateFields[fieldName] = { value: null, errorMessage: null, disabled: null };
        }
        if(value!==undefined) {
            updateFields[fieldName].value = value;
        }
        if(errorMessage!==undefined) {
            updateFields[fieldName].errorMessage = errorMessage;
        }
        if(disabled!==undefined) {
            updateFields[fieldName].disabled = disabled;
        }
        this.setState({ fields: updateFields });
    }

    _setLoading = (visible) => {
        utils.setLoadingVisibility(this.props.appControllerContext, visible);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.currentStep!==prevState.currentStep) {
            this._appSetBackAction();
            utils.scrollAppCardModuleContentTo(0);
        }
        if(!this.state.renderForm) {
            this.setState({ renderForm: true });
        }
    }

    render() {
        const { renderForm } = this.state;
        const configPesquisa = this._getConfigPesquisa();
        const resultado = this.state.resultadoRegistro;
        const exibeMensagemSucesso = resultado && resultado.valid;
        return(
            <AppCardModuleBasicWrapper moduleName="pesquisa-experiencia">

                { exibeMensagemSucesso ? 
                <>
                    <MensagemSucesso />

                    <div className="main-action">
                        <button id="btn-close" className="app-form-button" onClick={() => this._handleClose()}>OK</button>
                    </div>
                </>
                :
                ( !configPesquisa ?
                    <AppMessageBox
                        id="msg-error"
                        className="error"
                        messageData={{ "message": "Ops!, configuração inexistente." }} />
                    :
                    <>
                        <div className={`${configPesquisa.className}`}>
                            <div className="section-box">
                                <div className="section-title">{configPesquisa.title}</div>
                                <div className="section-content">
                                    { configPesquisa.instruction && 
                                    <div className="instruction">
                                        {configPesquisa.instruction}
                                    </div>
                                    }

                                    <div className="form-wrapper">
                                        { renderForm &&
                                        <Form name="formMain">
                                            <FormBuilder 
                                                blockFieldUpdate={false}
                                                disableClearErrorOnFieldChange={false}
                                                config={configPesquisa.formConfig}
                                                fields={this.state.fields}
                                                page={0}
                                                className="form-content" 
                                                onChange={this._handleFormUpdate}
                                                overrideFieldRender={{
                                                    'checkbox': CheckboxField,
                                                    'dropdown': DropdownField,
                                                    'radio-group': RadioGroupField,
                                                    'input': InputField
                                                }}
                                            />
                                        </Form>
                                        }
                                    </div>

                                    { configPesquisa.footer && 
                                    <div className="footer">
                                        {configPesquisa.footer}
                                    </div>
                                    }

                                </div>
                            </div>
                        </div>

                        { this.state.erroRegistro &&
                        <AppMessageBox
                            id="msg-error"
                            className="error"
                            messageData={{ "message": this.state.erroRegistro }} />
                        }

                        <div className="main-action">
                            <button className="app-form-button" onClick={() => this._handleAction()}>{configPesquisa.buttonMessage}</button>
                            { configPesquisa.enableVoltar &&
                            <button className="app-form-button btn-voltar" onClick={() => this._handleVoltar()}>Voltar</button>
                            }
                        </div>

                        <div className="padding-rodape"></div>
                    </>
                )
                }

            </AppCardModuleBasicWrapper>
        );
    }
}

export default PesquisaExperiencia;