/*
** @name: Meu Clínicas - anexosModal
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Dezembro 2022
** @description: Componente para renderizar modal para anexar arquivos
*/

import React, { useEffect, useState, useCallback } from 'react';

import { ThemeImage, ThemeMessage } from '../../core/appThemeUtils.js';

import AppModal from '../../components/general/appModal/appModal.js';
import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';
import MultiFileSelect from '../../components/general/multiFileSelect/';

import consultasClient from '../../apiClients/consultas/consultasClient.js';

import ModalDocumentSaveSucess from './modalDocumentSaveSucess.js';


// Import module styles
import './scss/anexosModal.scss';


const AnexosModal = (props) => {
    const { consulta, settings, hideMessage, onClose, onSetLoading } = props;
    const { maxFiles, maxFileSize, minFileDescriptionSize, acceptedFileMimeTypes } = settings || {};
    const [ sentFiles, setSentFiles ] = useState(null);
    const [ fileToAdd, setFileToAdd ] = useState(null);
    const [ fileToRemove, setFileToRemove ] = useState(null);
    const [ forceRestart, setForceRestart ] = useState(false);
    const [ saveComplete, setSaveComplete ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(null);

    const _buildBatchRequest = () => {
        const { pacCodigo, numero } = consulta || {};
        const uploadDocumentList = [];
        (fileToAdd || []).forEach(elem => uploadDocumentList.push({
            mimeType: elem.mimeType,
            originalName: elem.name,
            description: elem.description,
            fileBase64: elem.fileBase64
        }));
        return {
            pacCodigo,
            numeroConsulta: numero,
            expireAt: null,
            uploadDocumentList,
            deleteSolrKeyList: fileToRemove
        }
    }

    const _getListSentFiles = useCallback(() => {
        const { pacCodigo, numero } = consulta || {};
        setErrorMessage(null);
        onSetLoading(true);
        consultasClient.documentList(
            pacCodigo, numero,
            res => {
                onSetLoading(false);
                setSentFiles(res.data);
            },
            err => {
                onSetLoading(false);
                setSentFiles(null);
                setErrorMessage("Ops!, ocorreu um erro obtendo lista de arquivos previamente enviados.");
            }
        );
    }, [consulta, onSetLoading]);

    const _handleFileListChange = (lists) => {
        const { add, remove } = lists;
        setFileToAdd(add);
        setFileToRemove(remove);
    }

    const _handleSave = () => {
        const payload = _buildBatchRequest();
        setErrorMessage(null);
        onSetLoading(true);
        consultasClient.documentSaveBach(
            payload,
            res => {
                onSetLoading(false);
                if(res.status !== 200 || !res.data || (!res.data.success && !res.data.errorMessage)) {
                    setErrorMessage("Ops!, ocorreu um erro inesperado salvando as alterações nos arquivos. Se desejar pode reiniciar o processo.");
                    setForceRestart(true);
                } else {
                    if(res.data.success) {
                        setSaveComplete(true);    
                    } else {
                        setErrorMessage(res.data.errorMessage);
                    }
                }
            },
            err => {
                onSetLoading(false);
                setErrorMessage("Ops!, ocorreu um erro salvando as alterações nos arquivos.")
            }
        );
    }

    const _isSaveEnabled = () => ((fileToAdd || []).length || (fileToRemove || []).length);

    useEffect(() => _getListSentFiles(), [_getListSentFiles]);

    return(
        <div id="anexosModalId">
            { saveComplete && <ModalDocumentSaveSucess onConfirm={() => onClose()} /> }

            { !saveComplete &&
            <AppModal
                closeOnEsc={false}
                closeOnClickOutside={false}
                onClose={() => onClose()}
                headerContent={
                    <div className="modal-header-wrapper">
                        Anexar arquivos
                        <div className="close-button" onClick={() => onClose()}>
                            <ThemeImage module="appCard" imageId="normal-icon_close" />
                        </div>
                    </div>
                }
            >
                { hideMessage!==true &&
                <ThemeMessage elemType="div" messageId="teleatendimento_upload-mensagem-modal" className="message-upload" />
                }

                <MultiFileSelect
                    className="file-selection"
                    maxFiles={maxFiles || 0}
                    maxFileSize={maxFileSize || 0}
                    minFileDescriptionSize={minFileDescriptionSize || 0}
                    fileAccept={acceptedFileMimeTypes || ""}
                    sentFiles={sentFiles}
                    onChange={_handleFileListChange}
                />

                { errorMessage &&
                <AppMessageBox id="msg-save-error" className="error" messageData={{ message: errorMessage }} />
                }

                <div className="action-buttons">
                    { forceRestart ?
                    <button id="button-upload-ok" className="app-form-button modal-button single" onClick={() => onClose()}>OK</button>
                    :
                    <>
                        <button id="button-upload-save" className="app-form-button modal-button" disabled={!_isSaveEnabled()} onClick={() => _handleSave()}>SALVAR</button>
                        <button id="button-upload-cancel" className="app-form-button modal-button" onClick={() => onClose()}>CANCELAR</button>
                    </>
                    }
                </div>

            </AppModal>
            }
        </div>
    );
}

export default AnexosModal;