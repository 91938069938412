/*
** @name: Meu Clínicas - exames
** @author: 
** @date:
** @description: Módulo para cadastro do usuario.
**
** @update: Julho 2020 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Implementando visualizador do exame como alternativa ao download do PDF
**
** @update: Março 2021 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Atualizado para novo layout da aplicação e funcionamento com cards
**
** @update: Julho 2021 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Implementando parte de estudos de exames (visualização, compartilhamento)
*/
/*
    Modulos extras:
        COMPARTILHAR_ESTUDO: opcional
*/

import React, { Component, Fragment } from 'react';
import { Accordion } from 'semantic-ui-react';
import Moment from 'react-moment';

import utils from '../../core/utils.js';
import { useAuthContext } from '../../core/authContext.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';
import { useAppThemeContext } from '../../core/appThemeContext.js';
import { getAppServiceConfigByName } from '../../core/appThemeConfig.js';
import { isServiceEnable, ThemeImage, ThemeMessage } from '../../core/appThemeUtils.js';
import { pascalCase } from '../../core/stringUtils.js';
import { APP_SERVICE_LIST } from '../../core/appServiceList.js';

import AppCardModuleBasicWrapper from '../../components/general/appCardModuleBasicWrapper/appCardModuleBasicWrapper.js';
import AppConfirmationDialog from '../../components/general/appConfirmationDialog/appConfirmationDialog.js';
import AppDateFilter from '../../components/general/appDateFilter/appDateFilter.js';
import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';
import ViewerModal from '../../components/general/viewerModal/viewerModal.js';
import { AccordionCollapsible } from '../../components/general/appNavigationControls/appNavigationControls.js';

import examesClient from '../../apiClients/exames/examesClient';


// Import module styles
import './exames.scss';


const ESCAPE_KEY = 27;
const DEFAULT_ERRO_SOLICITACAO = "Erro obtento informações da solicitação.";
const DEFAULT_ERRO_EXAME = "Erro obtento informações do exame.";
const DEFAULT_ERRO_ESTUDO = "Erro obtento informações do visualizador.";
const DEFAULT_ERRO_ANEXO = "Erro obtento informações do anexo.";
const DEFAULT_ERRO_COMPATILHAMENTO = "Erro criando compartilhamento de imagens.";
const DEFAULT_ERRO_REVOGA_COMPARTILHAMENTO = "Erro ao cancelar compartilhamentos."


const Exames = (props) => {
    const authContext = useAuthContext();
    const appControllerContext = useAppControllerContext();
    const themeConfig = useAppThemeContext().getConfig();
    return(
        <ExamesImplem
            authContext={authContext}
            appControllerContext={appControllerContext}
            themeConfig={themeConfig}
            {...props}
        />
    )
}

class ExamesImplem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFiltered: false,
            filterError: null,
            searchResult: [],
            searchErrorMessage: null,
            searchInfoMessage: null,
            mostraSolicitacao: false,
            listaSolicitacao: null,
            mostraResutado: false,
            listaResultado: null,
            listaAnexo: null,
            mostraAnexo: false,
            confirmDialog: null
        };
    }

    _appSetBackAction = () => {
        const rnIntegration = window.rnIntegration;
        if(rnIntegration && rnIntegration.isAppRunning()) {
            this.props.appControllerContext.methods.doResetAppBackAction();
            if(this._getDadosVisualizacao()) {
                rnIntegration.backAction.push(() => { this._handleCloseResultado() });
            }
        }
    }

    _estudoAbrir = (estudo, grupo) => {
        this._setLoading(true);
        examesClient.obterUrlXeroViewer(
            estudo.pacCodigo,
            estudo.soeSeq,
            estudo.iseSeq,
            (res => {
                this._setLoading(false);
                const urlXero = res.data ? res.data.urlXero : null;
                if(res.status !== 200 || !urlXero) {
                    this._updateEstudoViewerError(estudo, DEFAULT_ERRO_ESTUDO);
                    return;
                }

                this._updateEstudoViewerError(estudo, null);
                estudo.statusLeituraEstudo = 'LIDO';
                grupo.statusLeituraGrupo = res.data.statusLeituraGrupo;
                this.setState({ });
                this._openXeroViewer(urlXero);
            }),
            (err => {
                this._setLoading(false); // 400 (Requisição inválida) ou 500 (erro na chamada à API)
                this._updateEstudoViewerError(estudo, DEFAULT_ERRO_ESTUDO);
            })
        );
    }

    _estudoAbrirConfirmacao = (estudo, grupo) => {
        const closeConfirmation = () => this.setState({ confirmDialog: null });
        this.setState({
            confirmDialog: {
                title: "Atenção",
                message: <div><p>Esta funcionalidade irá direcionar para o portal de imagens. Certifique-se de que seu navegador não está com o bloqueio de popups ligado.</p><p>Confirma?</p></div>,
                onConfirm: () => { closeConfirmation(); this._estudoAbrir(estudo, grupo) },
                onCancel: () => closeConfirmation()
            }
        });
    }

    _estudoCompartilhar = (estudo) => {
        const abrirModuloCompartilhamento = (token) => {
            const urlBase = process.env.REACT_APP_SERVICES_ESTUDOS_IMAGENS;
            const params = {
                urlCompartilhamento: urlBase.replace(/\/+$/, "") + `/${token}`,
                descricaoEstudo: estudo.descricao
            }
            this.props.appControllerContext.methods.doAddCardModule(APP_SERVICE_LIST.COMPARTILHAR_ESTUDO, params, false);
        }

        this._setLoading(true);
        examesClient.obterTokenCompartilhamentoXeroViewer(
            estudo.pacCodigo,
            estudo.soeSeq,
            estudo.iseSeq,
            (res => {
                this._setLoading(false);
                if(res.status !== 200 || !res.data) {
                    this._updateEstudoViewerError(estudo, DEFAULT_ERRO_COMPATILHAMENTO);
                    return;
                }

                this._updateEstudoViewerError(estudo, null);
                this._updateContadorCompartilhamento(estudo, 1);
                abrirModuloCompartilhamento(res.data);
            }),
            (err => {
                this._setLoading(false);
                this._updateEstudoViewerError(estudo, DEFAULT_ERRO_COMPATILHAMENTO);
            })
        );
    }

    _estudoRevogarCompartilhamento = (estudo) => {
        const closeConfirmation = () => this.setState({ confirmDialog: null });

        this._setLoading(true);
        examesClient.cancelarCompartilhamentosXeroViewer(
            estudo.pacCodigo,
            estudo.soeSeq,
            estudo.iseSeq,
            (res => {
                this._setLoading(false);
                if(res.status === 200) {
                    this._updateEstudoViewerError(estudo, null);
                    this._updateContadorCompartilhamento(estudo, null, 0);

                    this.setState({
                        confirmDialog: {
                            title: "Sucesso",
                            message: <div><p>Todos compartilhamentos anteriores foram cancelados com sucesso, impedindo assim o acesso a essas imagens por esses links.</p><p>Você poderá realizar novos compartilhamentos quando desejar.</p></div>,
                            onConfirm: () => closeConfirmation(),
                            onCancel: null
                        }
                    });

                    return;
                }
                this._updateEstudoViewerError(estudo, DEFAULT_ERRO_REVOGA_COMPARTILHAMENTO);
            }),
            (err => {
                this._setLoading(false);
                this._updateEstudoViewerError(estudo, DEFAULT_ERRO_REVOGA_COMPARTILHAMENTO);
            })
        );
    }

    _estudoRevogarCompartilhamentoConfirmacao = (estudo) => {
        const closeConfirmation = () => this.setState({ confirmDialog: null });
        const contador = estudo.contadorCompartilhamento;
        const plural = contador > 1 ? "s" : "";
        this.setState({
            confirmDialog: {
                title: "Atenção",
                message: <div><p>No momento esta imagem possui {contador} compartilhamento{plural} ativo{plural}. Ao confirmar {plural ? "todos serão" : "este será"} cancelado{plural}, impedindo o acesso de quem possuir este{plural} link{plural}.</p><p>Você poderá realizar novos compartilhamentos quando desejar.</p><p>Confirma?</p></div>,
                onConfirm: () => { closeConfirmation(); this._estudoRevogarCompartilhamento(estudo) },
                onCancel: () => closeConfirmation()
            }
        });
    }

    _existsStatusNovo = (lista, statusField) => {
        return(
            utils.isArray(lista) && statusField &&
            lista.some((item) => {
                if(this._isStatusNovo(item[statusField])) {
                    return true;
                }
                return false;
            })
        )
    }

    _existsAnexoSolicitacao = (resultados) => {
        return( resultados && resultados.map((resultado, idx) => {
            if (resultado.idArmazenamentoAnexo !== null) {
                return true;
            }
            return false;
        }));
        
    }

    _getDadosVisualizacao = () => {
        const { mostraResutado, listaResultado, mostraSolicitacao, listaSolicitacao, listaAnexo, mostraAnexo } = this.state;
        const exibeVisualizador = (mostraResutado && listaResultado) || (mostraSolicitacao && listaSolicitacao) || (listaAnexo && mostraAnexo) ;
        const listaImages = exibeVisualizador &&  (listaResultado ? listaResultado : (listaSolicitacao ? listaSolicitacao : listaAnexo));

        return exibeVisualizador ? listaImages : null;
    }

    _getListaExames = (params) => {
        const filtro = (params && params.filtro) || {};
        const filterError = params && params.filterError;

        this.setState({ filterError });
        if (filterError) {
            return;
        }

        this._setLoading(true);
        this.setState({
            searchErrorMessage: null,
            searchInfoMessage: null
        });

        const pacCodigo = this.props.authContext.properties.user.pacCodigo;
        examesClient.buscarListaExames(
            pacCodigo,
            filtro.dtInicial,
            filtro.dtFinal,
            res => {
                this._setLoading(false);
                const isFiltered = (filtro.dtInicial || filtro.dtFinal) ? true : false;
                const listGrupoExibicao = res.data && res.data.gruposExibicao ? res.data.gruposExibicao : [];
                const searchInfoMessage = listGrupoExibicao.length > 0 ? null :
                    (!isFiltered && utils.isUserInSyncAwayTime() ? <ThemeMessage messageId="_general_mensagem-possivel-sincronismo-pendente" /> : "Nenhum resultado encontrado");
                this.setState({
                    isFiltered: isFiltered,
                    searchResult: listGrupoExibicao,
                    searchInfoMessage: searchInfoMessage
                });
            },
            err => {
                this._setLoading(false);
                this.setState({
                    searchResult: [],
                    searchErrorMessage: "Ocorreu um erro ao processar sua requisição"
                });
            }
        )
    }

    _getResultadoPDF = (exame, grupo) => {
        this._setLoading(true);

        const pacCodigo = this.props.authContext.properties.user.pacCodigo;
        examesClient.buscarResultadoPDF(
            exame.id,
            pacCodigo,
            res => {
                this._setLoading(false);
                if(!res.data || !res.data.pdfBase64) {
                    this._updateResultadoDownloadError(exame, "Retorno sem dados para consulta.");
                    return;
                }
                this._updateResultadoDownloadError(exame, null);

                const base64 = res.data.pdfBase64;
                const rnIntegration = window.rnIntegration;
                if(!rnIntegration || !rnIntegration.triggerAppPDFViewer(base64)) {
                    const dtSolicitacao = exame.dtSolicitacao ? new Date(exame.dtSolicitacao) : new Date();
                    const dataSolicitacao = dtSolicitacao.toISOString().split('T')[0].replace(/-/g, '');
                    const soeSeq = exame.soeSeq;
                    const grupo = exame.nomeGrupoExame;
                    const fileName = `${dataSolicitacao}_${soeSeq}_${grupo}.pdf`;

                    utils.automaticDownloadData(base64, fileName);
                }

                exame.statusLeituraResultado = 'LIDO';
                grupo.statusLeituraGrupo = res.data.statusLeituraGrupo;

                this.setState(this.state);
            },
            err => {
                this._setLoading(false);
                this._updateResultadoDownloadError(exame, DEFAULT_ERRO_EXAME);
            });
    }

    _getResultadoPNG = (exame, grupo) => {
        this._setLoading(true);

        const pacCodigo = this.props.authContext.properties.user.pacCodigo;
        examesClient.buscarResultadoPNG(
            exame.id,
            pacCodigo,
            res => {
                this._setLoading(false);

                if(!res.data || !res.data.listPngBase64 ||
                    isNaN(res.data.listPngBase64.length) || res.data.listPngBase64.length<1) {

                    this._updateResultadoDownloadError(exame, "Retorno sem dados para consulta.");
                    this.setState({ mostraResutado: false, listaResultado: null });
                    return;
                }
                this._updateResultadoDownloadError(exame, null);

                exame.statusLeituraResultado = 'LIDO';
                grupo.statusLeituraGrupo = res.data.statusLeituraGrupo;
                this.setState({ mostraResutado: true, listaResultado: res.data.listPngBase64 });
            },
            err => {
                this._setLoading(false);
                this._updateResultadoDownloadError(exame, DEFAULT_ERRO_EXAME);
            });
    }

    _getAnexoPDF = (exame) => {
        this._setLoading(true);

        const pacCodigo = this.props.authContext.properties.user.pacCodigo;
        examesClient.buscarAnexoExamePDF(
            exame.id,
            pacCodigo,
            res => {
                this._setLoading(false);
                if (!res.data || !res.data.pdfBase64) {
                    this._updateAnexoDownloadError(exame, "Retorno sem dados para o anexo do exame.");
                    return;
                }
                this._updateAnexoDownloadError(exame, null);

                const base64 = res.data.pdfBase64;
                const rnIntegration = window.rnIntegration;
                if (!rnIntegration || !rnIntegration.triggerAppPDFViewer(base64)) {
                    const dtSolicitacao = exame.dtSolicitacao ? new Date(exame.dtSolicitacao) : new Date();
                    const dataSolicitacao = dtSolicitacao.toISOString().split('T')[0].replace(/-/g, '');
                    const soeSeq = exame.soeSeq;
                    const grupo = exame.nomeGrupoExame;
                    const fileName = `${dataSolicitacao}_${soeSeq}_${grupo}.pdf`;
                    utils.automaticDownloadData(base64, fileName);
                }

                exame.visualizadoEmAnexo = res.data.visualizadoEmAnexo;
                this.setState(this.state);
            },
            err => {
                this._setLoading(false);
                this._updateAnexoDownloadError(exame, DEFAULT_ERRO_ANEXO);
            });
    }

    _getAnexoPNG = (exame) => {
        this._setLoading(true);

        const pacCodigo = this.props.authContext.properties.user.pacCodigo;
        examesClient.buscarAnexoExamePNG(
            exame.id,
            pacCodigo,
            res => {
                this._setLoading(false);
                if (!res.data || !res.data.listPngBase64 ||
                    isNaN(res.data.listPngBase64.length) || res.data.listPngBase64.length < 1) {
                    this._updateAnexoDownloadError(exame, "Retorno sem dados para o anexo do exame.");
                    this.setState({ mostraAnexo: false, listaAnexo: null });
                    return;
                }
                this._updateAnexoDownloadError(exame, null);
                exame.visualizadoEmAnexo = res.data.visualizadoEmAnexo;
                this.setState({ mostraAnexo: true, listaAnexo: res.data.listPngBase64 });
            },
            err => {
                this._setLoading(false);
                this._updateAnexoDownloadError(exame, DEFAULT_ERRO_ANEXO);
            });
    }

    _getSolicitacaoPDF = (grupo) => {
        this._setLoading(true);

        examesClient.buscarSolicitacaoPDF(
            grupo.soeSeq,
            res => {
                this._setLoading(false);

                if(!res.data.sucesso || !res.data.pdfBase64) {
                    const message = res.data.errorMessage ? res.data.errorMessage : "Retorno sem dados para consulta.";
                    this._updateSolicitacaoDownloadError(grupo, message);
                    return;
                }
                this._updateSolicitacaoDownloadError(grupo, null);

                const base64 = res.data.pdfBase64;
                const rnIntegration = window.rnIntegration;
                if(!rnIntegration || !rnIntegration.triggerAppPDFViewer(base64)) {
                    const dtSolicitacao = grupo.dtSolicitacao ? new Date(grupo.dtSolicitacao) : new Date();
                    const dataSolicitacao = dtSolicitacao.toISOString().split('T')[0].replace(/-/g, '');
                    const soeSeq = grupo.soeSeq;
                    const fileName = `${dataSolicitacao}_${soeSeq}.pdf`;

                    utils.automaticDownloadData(base64, fileName);
                }

                grupo.statusLeituraSolicitacao = 'LIDO';
                grupo.statusLeituraGrupo = res.data.statusLeituraGrupo;

                this.setState(this.state);
            },
            err => {
                this._setLoading(false);
                this._updateSolicitacaoDownloadError(grupo, DEFAULT_ERRO_SOLICITACAO);
            });
    }

    _getSolicitacaoPNG = (grupo) => {
        this._setLoading(true);
        examesClient.buscarSolicitacaoPNG(
            grupo.soeSeq,
            res => {
                this._setLoading(false);

                if(!res.data || !res.data.sucesso || !res.data.listPngBase64 || 
                    isNaN(res.data.listPngBase64.length) || res.data.listPngBase64.length<1) {
 
                    const message = res.data.errorMessage ? res.data.errorMessage : "Retorno sem dados para consulta.";
                    this._updateSolicitacaoDownloadError(grupo, message);
                    this.setState({ mostraSolicitacao: false, listaSolicitacao: null });
                    return;
                }
                this._updateSolicitacaoDownloadError(grupo, null);

                grupo.statusLeituraSolicitacao = 'LIDO';
                grupo.statusLeituraGrupo = res.data.statusLeituraGrupo;
                this.setState({ mostraSolicitacao: true, listaSolicitacao: res.data.listPngBase64 });
            },
            err => {
                this._setLoading(false);
                this._updateSolicitacaoDownloadError(grupo, DEFAULT_ERRO_SOLICITACAO);
            });
    }

    _handleAccordionClick = (e, grupoExames) => {
        grupoExames.active = !grupoExames.active;
        this.setState({});
    }

    _handleClearFilter = () => {
        const { isFiltered } = this.state;
        this.setState({
            isFiltered: false,
            filterError: null,
            searchErrorMessage: null,
            searchInfoMessage: null
        });

        if(isFiltered) {
            this._getListaExames();
        }
    }

    _handleCloseResultado = () => {
        this.setState({
            mostraResutado: false,
            listaResultado: null,
            mostraSolicitacao: false,
            listaSolicitacao: null,
            listaAnexo: null,
            mostraAnexo: false
        });
    }

    _handleKeyDown = (event) => {
        if(this._getDadosVisualizacao()) {
            switch( event.keyCode ) {
                case ESCAPE_KEY:
                    event.stopPropagation();
                    event.preventDefault();
                    this._handleCloseResultado();
                    break;
                default:
                    break;
            }
        }
    }

    _handleUpdateFilter = () => {
        this.setState({
            filterError: null,
            searchErrorMessage: null,
            searchInfoMessage: null
        });
    }

    _isStatusNovo = (statusLeitura) => {
        if (statusLeitura==='NAO_LIDO' || statusLeitura==='ATUALIZACAO_NAO_LIDA') {
            return true;
        }
        return false;
    }

    _openXeroViewer = (url) => {
        const rnIntegration = window.rnIntegration;
        if(!rnIntegration || !rnIntegration.triggerAppOpenURL(url)) {
            utils.automaticAnchorCreateAndClick(url, null, "_blank");
        }
    }

    _setLoading = (visible) => {
        utils.setLoadingVisibility(this.props.appControllerContext, visible);
    }

    _updateContadorCompartilhamento = (estudo, add, setTo) => {
        if(!utils.isInteger(add) && !utils.isInteger(setTo)) {
            return;
        }
        estudo.contadorCompartilhamento = utils.isInteger(setTo) ? setTo : (estudo.contadorCompartilhamento + add);
        this.setState({ });
    }

    _updateEstudoViewerError = (estudo, message) => {
        estudo.visualizacaoError = message;
        this.setState(this.state);
    }

    _updateResultadoDownloadError = (exame, message) => {
        exame.downloadError = message;
        this.setState(this.state);
    }

    _updateSolicitacaoDownloadError = (grupo, message) => {
        grupo.downloadError = message;
        this.setState(this.state);
    }

    _updateAnexoDownloadError = (exame, message) => {
        exame.downloadErrorAnexo = message;
        this.setState(this.state);
    }

    RenderVisualizador = () => {
        const { listaResultado } = this.state;
        const { listaAnexo } = this.state;
        const listaImages = this._getDadosVisualizacao();
        if(listaImages) {
            const items = [];
            const totalPaginas = "(Total " + listaImages.length + " página" + (listaImages.length>1 ? "s" : "") + ")";
            const titulo = listaResultado ? `Resultado do Exame ${totalPaginas}` : 
                            (listaAnexo ? `Anexo do Exame ${totalPaginas}` :   `Solicitação ${totalPaginas}`);

            for(var page=0; page<listaImages.length; page++) {
                const strBase64 = listaImages[page];
                const imageData = `data:image/png;base64,${strBase64}`;

                items.push(
                    <div key={`page_${page}`}>
                        <img src={imageData} alt=""></img>
                    </div>
                );
                if(page<(listaImages.length-1)) {
                    items.push(
                        <div key={"space_"+page} className="result-pagespace"></div>
                    );
                }
            }

            utils.setViewPortRescale(true);

            return (
                <div className="exames-viwer-wrapper">
                    <ViewerModal
                        title={<div className="titulo-modal">{titulo}</div>}
                        onCloseButton={this._handleCloseResultado}>
                        {items}
                    </ViewerModal>
                </div>
            );

        } else {
            utils.setViewPortRescale(false);
            return null;
        }
    }

    componentDidMount(){
        document.addEventListener("keydown", this._handleKeyDown, true);
        this._getListaExames();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { mostraResutado, mostraSolicitacao } = this.state;
        if((mostraResutado!==prevState.mostraResutado) || (mostraSolicitacao!==prevState.mostraSolicitacao)) {
            this._appSetBackAction();
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this._handleKeyDown, true);
    }

    render() {
        const { confirmDialog } = this.state;
        return(
            <AppCardModuleBasicWrapper moduleName="exames">

                { this.RenderVisualizador() }

                { confirmDialog &&
                    <AppConfirmationDialog
                        title={confirmDialog.title}
                        message={confirmDialog.message}
                        onConfirm={confirmDialog.onConfirm}
                        onCancel={confirmDialog.onCancel} />
                }

                <AppDateFilter
                    filterError={this.state.filterError}
                    onFilterClear={this._handleClearFilter}
                    onFilterUpdate={this._handleUpdateFilter}
                    onFilter={this._getListaExames}
                />

                {this.state.searchErrorMessage &&
                    <AppMessageBox
                        id="msg-exames-error"
                        className="error"
                        messageData={{ message: this.state.searchErrorMessage }} />
                }

                {this.state.searchInfoMessage &&
                    <AppMessageBox
                        id="msg-exames-information"
                        className="information"
                        messageData={{ message: this.state.searchInfoMessage }} />
                }

                <div className="results-section">
                    <Accordion fluid styled>
                        {this.state.searchResult.map((grupo, indexGrupo) => {
                            const serviceConfig = getAppServiceConfigByName(this.props.themeConfig, APP_SERVICE_LIST.EXAMES) || {};
                            const exibirTicketSolicitacao = grupo.exibeTicketSolicitacao;
                            const habilitaDownload = grupo.habilitaDownloadTicketSolicitacao;
                            const habilitarEstudos = serviceConfig && serviceConfig.estudoEnabled;
                            const exibeSituacao = grupo.situacao && grupo.situacao.toUpperCase()==="CA";
                            const exibirResultados = grupo.resultados && grupo.resultados.length>0;
                            const exibirEstudos = habilitarEstudos && grupo.estudosPACS && grupo.estudosPACS.length>0;
                            const indicativoNovoGrupo = this._isStatusNovo(grupo.statusLeituraGrupo);
                            const indicativoNovoSolic = this._isStatusNovo(grupo.statusLeituraSolicitacao);
                            const existeResultadoNovo = this._existsStatusNovo(grupo.resultados, "statusLeituraResultado");
                            const existeEstudoNovo = this._existsStatusNovo(grupo.estudosPACS, "statusLeituraEstudo");
                            const existeAnexo = this._existsAnexoSolicitacao(grupo.resultados);





                            return (
                                <div
                                    key={'grupo-exame-' + indexGrupo}
                                    className={`accordion-item${grupo.active ? " active": ""}${indicativoNovoGrupo ? " emphasis" : ""}`}
                                >
                                    <Accordion.Title onClick={(e) => this._handleAccordionClick(e, grupo)}>
                                        <div className="title-info-wrapper">
                                            { indicativoNovoGrupo &&
                                                <div className="indicativo-novo bold-text">[NOVO]</div>
                                            }
                                            <div className="information no-wrap">
                                                <div className="data bold-text separator"><Moment format="DD/MM/YYYY" >{grupo.dtSolicitacao}</Moment></div>
                                                <div className="solicitacao">Nº Solicit.: <span className="bold-text">{grupo.soeSeq}</span></div>
                                            </div>
                                        </div>
                                        <div className="collapsible-wrapper">
                                            <AccordionCollapsible active={grupo.active} iconVariant={indicativoNovoGrupo ? "new" : null} />
                                        </div>

                                    </Accordion.Title>
                                    <Accordion.Content>
                                        { exibirTicketSolicitacao &&  /* === SOLICITACAO === */
                                            <div className={`content-row${habilitaDownload ? `${indicativoNovoSolic ? " novo" : ""}` : " disabled"}`}>
                                                <div className="titulo-solicitacao">SOLICITAÇÃO/ PREPARO</div>
                                                <div className="solicitacao-wrapper">
                                                    <div className="buttons-wrapper">
                                                        <button
                                                            disabled={!habilitaDownload}
                                                            type="button"
                                                            className={`btn-visualizar${habilitaDownload ? "" : " disabled"}`}
                                                            title="Visualizar solicitação"
                                                            onClick={() => this._getSolicitacaoPNG(grupo)}>
                                                            <ThemeImage
                                                                module="exames"
                                                                imageId={`icon_botao-visualizar${indicativoNovoSolic ? "-new" : ""}`}
                                                                className="img-buttomimg" />
                                                        </button>
                                                        <button
                                                            disabled={!habilitaDownload}
                                                            type="button"
                                                            className={`btn-baixar-pdf${habilitaDownload ? "" : " disabled"}`}
                                                            title="Baixar arquivo da solicitação"
                                                            onClick={() => this._getSolicitacaoPDF(grupo)}>
                                                            <ThemeImage
                                                                module="exames"
                                                                imageId={`icon_botao-pdf${indicativoNovoSolic ? "-new" : ""}`}
                                                                className="img-buttomimg" />
                                                        </button>
                                                    </div>
                                                    <div className="info-wrapper">
                                                        <div className="especialidade-exame"><span className="bold-text">Especialidade:</span> { pascalCase(grupo.especialidade) }</div>
                                                        <div className="solicitante-exame"><span className="bold-text">Solicitante:</span> { pascalCase(grupo.nomeSolicitante) }</div>
                                                    </div>
                                                </div>
                                                { exibeSituacao &&
                                                <div className="situacao-solicitacao">
                                                    <span className="bold-text">Situação:</span> { pascalCase(grupo.situacaoDescricao) }
                                                </div>
                                                }
                                                { grupo.dadosSolicitacao && grupo.dadosSolicitacao.existeItemAgendado &&
                                                    <div className="mensagem-item-agendado"><strong>ATENÇÃO:</strong> existe agendamento para esta solicitacao</div>
                                                }
                                                { grupo.downloadError &&
                                                <div className="download-error">{grupo.downloadError}</div>
                                                }
                                            </div>
                                        }

                                        { exibirResultados &&  /* === RESULTADOS === */
                                        <>
                                            <div className={`content-row${existeResultadoNovo ? " novo" : ""}`}>
                                                <div className="titulo-resultados">RESULTADOS</div>
                                            </div>

                                            { grupo.resultados && grupo.resultados.map((exame, index) => {
                                                const indicativoNovoResult = this._isStatusNovo(exame.statusLeituraResultado);

                                                return(
                                                    <Fragment key={'exame-' + indexGrupo + '-' + index}>
                                                        <div className={`content-row${indicativoNovoResult ? " novo" : ""}`}>
                                                            <div className="resultados-wrapper">
                                                                <div className="buttons-wrapper">
                                                                    <button 
                                                                        type="button"
                                                                        className="btn-visualizar"
                                                                        title="Visualizar resultado"
                                                                        onClick={() => this._getResultadoPNG(exame, grupo)}>
                                                                        <ThemeImage 
                                                                            module="exames"
                                                                            imageId={`icon_botao-visualizar${indicativoNovoResult ? "-new" : ""}`}
                                                                            className="img-buttomimg" />
        
                                                                    </button>                                               
                                                                    <button 
                                                                        type="button"
                                                                        className="btn-baixar-pdf"
                                                                        title="Baixar arquivo do resultado"
                                                                        onClick={() => this._getResultadoPDF(exame, grupo)}>
                                                                        <ThemeImage
                                                                            module="exames"
                                                                            imageId={`icon_botao-pdf${indicativoNovoResult ? "-new" : ""}`}
                                                                            className="img-buttomimg" />
                                                                    </button>
                                                                </div>   
                                                                <div className="info-wrapper">
                                                                    <div className="tipo-exame">
                                                                        <span className="bold-text">Tipo de exame:</span><br/>
                                                                        <span>{exame.nomeGrupoExame}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        { exame.downloadError &&
                                                        <div className="content-row">
                                                            <div className="download-error">{exame.downloadError}</div>
                                                        </div>
                                                        }
                                                    </Fragment>
                                                );
                                            })}

                                            { exibirTicketSolicitacao && grupo.dadosSolicitacao && grupo.dadosSolicitacao.existeExameNaoLiberado &&
                                                <div className={`content-row mensagem-resultado-pedente${existeResultadoNovo ? " novo" : ""}`}>* existem outros exames ainda sem resultado</div>
                                            }
                                        </>
                                        }

                                        { exibirEstudos && /* === ESTUDOS === */
                                        <>
                                            <div className={`content-row${existeEstudoNovo ? " novo" : ""}`}>
                                                <div className="titulo-resultados">IMAGENS</div>
                                            </div>

                                            { grupo.estudosPACS && grupo.estudosPACS.map((estudo, index) => {
                                                const indicativoNovoEstudo = this._isStatusNovo(estudo.statusLeituraEstudo);
                                                const habilitaCompartilhamento = isServiceEnable(this.props.themeConfig, APP_SERVICE_LIST.COMPARTILHAR_ESTUDO);
                                                const habilitarRevogarCompartilhamento = estudo.contadorCompartilhamento > 0 && serviceConfig.estudoRevogarCompartilhamentoEnabled;
                                                
                                                return(
                                                    <Fragment key={'estudo-' + indexGrupo + '-' + index}>
                                                        <div className={`content-row${indicativoNovoEstudo ? " novo" : ""}`}>
                                                            <div className="estudos-wrapper">
                                                                <div className="buttons-wrapper">
                                                                    <button 
                                                                        type="button"
                                                                        className="btn-visualizar"
                                                                        title="Visualizar imagens"
                                                                        onClick={() => this._estudoAbrirConfirmacao(estudo, grupo)}>
                                                                        <ThemeImage 
                                                                            module="exames"
                                                                            imageId={`icon_botao-visualizar${indicativoNovoEstudo ? "-new" : ""}`}
                                                                            className="img-buttomimg" />
        
                                                                    </button>
                                                                    { habilitaCompartilhamento && 
                                                                    <button 
                                                                        type="button"
                                                                        className="btn-compartilhar"
                                                                        title="Compartilhar imagens"
                                                                        onClick={() => this._estudoCompartilhar(estudo)}>
                                                                        <ThemeImage
                                                                            module="exames"
                                                                            imageId={`icon_compartilhar${indicativoNovoEstudo ? "-new" : ""}`}
                                                                            className="img-buttomimg" />
                                                                    </button>
                                                                    }
                                                                    { habilitarRevogarCompartilhamento && 
                                                                    <button 
                                                                        type="button"
                                                                        className="btn-revogar"
                                                                        title="Remover compartilhamentos"
                                                                        onClick={() => this._estudoRevogarCompartilhamentoConfirmacao(estudo)}>
                                                                        <ThemeImage
                                                                            module="exames"
                                                                            imageId={`icon_cancelar-compartilhamento${indicativoNovoEstudo ? "-new" : ""}`}
                                                                            className="img-buttomimg" />
                                                                    </button>
                                                                    }
                                                                </div>   
                                                                <div className="info-wrapper">
                                                                    <div className="descricao-estudo">
                                                                        <span className="bold-text">Descrição:</span><br/>
                                                                        {pascalCase(estudo.descricao)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        { estudo.visualizacaoError &&
                                                        <div className="content-row">
                                                            <div className="download-error">{estudo.visualizacaoError}</div>
                                                        </div>
                                                        }
                                                    </Fragment>
                                                );
                                            })}

                                        </>
                                        }

                                        {existeAnexo && /* === ANEXO === */
                                        <>
                                            {grupo.resultados && grupo.resultados.map((exame, idx) => {
                                                const isResultadoContemAnexo = exame.idArmazenamentoAnexo !== null;
                                                const indicativoNovoResult = exame.visualizadoEmAnexo === null;
                                                
                                                if (isResultadoContemAnexo) {
                                                    return (
                                                        <>
                                                            <div className={`content-row${indicativoNovoResult ? " novo" : ""}`}>
                                                                <div className="titulo-anexo">ANEXO</div>
                                                            </div>
                                                            <div className={`content-row${indicativoNovoResult ? " novo" : ""}`}>
                                                                <div className="resultados-wrapper">
                                                                    <div className="buttons-wrapper">
                                                                        <button
                                                                            type="button"
                                                                            className="btn-visualizar"
                                                                            title="Visualizar anexo"
                                                                            onClick={() => this._getAnexoPNG(exame)}>
                                                                            <ThemeImage
                                                                                module="exames"
                                                                                imageId={`icon_botao-visualizar${indicativoNovoResult ? "-new" : ""}`}
                                                                                className="img-buttomimg" />
                                                                        </button>

                                                                        <button
                                                                            type="button"
                                                                            className="btn-baixar-pdf"
                                                                            title="Baixar anexo"
                                                                            onClick={() => this._getAnexoPDF(exame)}>
                                                                            <ThemeImage
                                                                                module="exames"
                                                                                imageId={`icon_botao-pdf${indicativoNovoResult ? "-new" : ""}`}
                                                                                className="img-buttomimg" />
                                                                        </button>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {exame.downloadErrorAnexo &&
                                                            <div className="content-row">
                                                                <div className="download-error">{exame.downloadErrorAnexo}</div>
                                                            </div>
                                                            }
                                                        </>
                                                    );
                                                }                                             
                                                return null;

                                            })}
                                        </>
                                        }
                                        

                                    </Accordion.Content>
                                </div>
                            )
                        })}
                    </Accordion>
                    <div className="padding-rodape"></div>
                </div>

            </AppCardModuleBasicWrapper>
        );
    }
}

export default Exames;