/*
** @name: Meu Clínicas - feedback
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Novembro 2022
** @description: Componente para renderizar a área de feed-back/ avaliação experiência após encerrada a video chamada
*/

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Form, TextArea } from 'semantic-ui-react';

import utils from '../../core/utils.js';
import { ThemeImage, ThemeMessage } from '../../core/appThemeUtils.js';
import { APP_SERVICE_LIST } from '../../core/appServiceList.js';
import { EMPTY_HREF } from '../../core/utils.js';

import { hasServicePermission, hideService } from '../../components/general/appNavigationControls/appNavigationControls.js';

import teleatendimentoClient from '../../apiClients/consultas/teleatendimentoClient.js';


const DETAILS_MAX_SIZE = 200;

let componentUnmounting;

const Feedback = (props) => {
    const { authContext, appControllerContext, themeConfig, numeroConsulta, startedAt, onFeedbackReceived, onCloseModule } = props;
    const [ positiveFeedback, setPositiveFeedback ] = useState(null);
    const [ feedbackSaved, setFeedbackSaved ] = useState(false);
    const [ showMoreDetail, setShowMoreDetail ] = useState(false);
    const [ moreDetail, setMoreDetail ] = useState(null);
    const { user } = authContext.properties;
    const serviceName = APP_SERVICE_LIST.PESQUISA_EXPERIENCIA;
    const exibirLinkPesquisaExperiencia = !hideService(user, themeConfig, serviceName) && hasServicePermission(user, serviceName);
    const finishedAt = useMemo(() => new Date().getTime(), []);

    const _saveFeedback = useCallback(async function _saveFeedback(positive, detail) {
        const payload = {
            pacCodigo: user.pacCodigo,
            numeroConsulta: numeroConsulta,
            positive,
            detail,
            startedAt: startedAt,
            finishedAt: finishedAt
        };
        return new Promise(resolve => {
            teleatendimentoClient.videoChamadaFeedback(
                payload, 
                res => resolve({ success: true, httpResponse: res }),
                err => resolve({ success: false, httpResponse: err.response }));
        });
    }, [user, numeroConsulta, startedAt, finishedAt]);
        
    useEffect(() => { // component mount
        componentUnmounting = false;
        setPositiveFeedback(null);
        setFeedbackSaved(false);
        setShowMoreDetail(false);
        setMoreDetail(null);

        return () => { // unmount
            componentUnmounting = true;
        }
    }, []);

    useEffect(() => {
        return () => {
            if(componentUnmounting && !feedbackSaved && positiveFeedback!==null) {
                _saveFeedback(positiveFeedback, moreDetail).then(res => { }).catch(err => { });
            }
        }
    }, [feedbackSaved, moreDetail, positiveFeedback, _saveFeedback]);

    const _handleDetailChange = (value) => {
        if(!value || !value.trim()) {
            setMoreDetail(null);
        } else {
            setMoreDetail(value.replace(/[ ]+$/, ' ').substring(0, DETAILS_MAX_SIZE));
        }
    }

    const _handlePesquisaExperiencia = () => {
        const parameters = { selecaoPesquisa: "TELE", disableSelecao: true };
        appControllerContext.methods.doAddCardModule(serviceName, parameters, false);
        appControllerContext.methods.doRemoveCardModules(APP_SERVICE_LIST.TELEATENDIMENTO);
    }

    const _handleRatingSelect = (good) => {
        if(positiveFeedback!==null) {
            return;
        }

        if(good) {
            setPositiveFeedback(true);
            _setLoading(true);
            _saveFeedback(true, null)
                .then(res => {
                    setFeedbackSaved(true);
                    onFeedbackReceived();
                })
                .catch(err => {})
                .finally(() => _setLoading(false));
        } else {
            setPositiveFeedback(false);
            setShowMoreDetail(true);
            onFeedbackReceived();
        }
    }

    const _handleSendMoreDetail = () => {
        _setLoading(true);
        _saveFeedback(false, moreDetail)
            .then(res => setFeedbackSaved(true))
            .catch(err => {})
            .finally(() => _setLoading(false));
    }

    const _setLoading = (visible) => {
        utils.setLoadingVisibility(appControllerContext, visible);
    }

    if(!numeroConsulta) {
        return(
            <div className="feedback-wrapper">
                <button type="button" className="app-form-button" onClick={onCloseModule}>Encerrar</button>
            </div>
        )
    }

    return(
        <div className="feedback-wrapper">

            { feedbackSaved ?
            <div className="thankyou-wrapper">
                <ThemeMessage messageId="teleatendimento_video-chamada-thankyou" elemType="span" className="thakyou-message" />
            </div>
            :
            <>
                <ThemeMessage messageId="teleatendimento_video-chamada-feedback-question" elemType="div" className="question-feedback" />
                <div className="rating-wrapper">
                    <div className={`thumb-up ${positiveFeedback!==null ? ` disabled${positiveFeedback ? ` selected` : ""}` : ""}`} onClick={() => _handleRatingSelect(true)}>
                        <ThemeImage module="teleatendimento" imageId="icon_thumb-up" />
                    </div>
                    <div className={`thumb-down ${positiveFeedback!==null ? ` disabled${positiveFeedback===false ? ` selected` : ""}` : ""}`} onClick={() => _handleRatingSelect(false)}>
                        <ThemeImage module="teleatendimento" imageId="icon_thumb-down" />
                    </div>
                </div>

                { showMoreDetail &&
                <div className="more-detail-wrapper">
                    <ThemeMessage messageId="teleatendimento_video-chamada-more-detail" elemType="div" className="more-detail-title" />
                    <Form name="frmMoreDetail">
                        <div className="field-group textarea-field">
                            <TextArea
                                id="moreDetailId"
                                name="moreDetail"
                                className="no-resize"
                                placeholder="Conte-nos mais detalhes"
                                onChange={e => _handleDetailChange(e.target.value)}
                                autoComplete="off"
                                value={moreDetail ? moreDetail : ""}
                            />
                        </div>
                        <div className="send-button-wrapper">
                            <button type="button" className="app-form-button send-detail-button" onClick={_handleSendMoreDetail}>Enviar</button>
                        </div>
                    </Form>
                </div>
                }
            </>
            }

            { exibirLinkPesquisaExperiencia &&
            <ThemeMessage 
                messageId="teleatendimento_video-chamada-link-pesquisa-satisfacao" 
                elemType="div" 
                className="pesquisa-satisfacao-link-wrapper"
                params={[
                    <a href={EMPTY_HREF} id="link-pesquisa-satisfacao" onClick={_handlePesquisaExperiencia}>Clique aqui</a>
                ]}
            />
            }

        </div>
    );
}

export default Feedback;