/*
** @name: Meu Clínicas - receitaView
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Julho 2020
** @description: Módulo para renderizar a receita do paciente
*/

import React, { Component, Fragment } from 'react';
import Moment from 'react-moment';

import { maskCNPJ, startCase, pascalCase } from '../../../core/stringUtils.js';
import { getAppServiceConfigByName, getAppGeneralConfigPropertyByName } from '../../../core/appThemeConfig.js';
import { useAppThemeContext } from '../../../core/appThemeContext.js';
import { importThemeImage } from '../../../core/appThemeUtils.js';
import { APP_SERVICE_LIST } from '../../../core/appServiceList.js';

import QRCodeBlock from '../qrCodeBlock/qrCodeBlock.js';

// Import module styles
import './receitaView.scss';


const ESCAPE_KEY = 27;
const RD_HOZIONTAL = 'horizontal';
const RD_VERTICAL = 'vertical';
const TIPO_RECEITA = {
    "G": "Receita Geral",
    "E": "Receita de Controle Especial",
    "C": "Receita de Cuidados"
}
const SITUACAO_PERIODO = {
    "DISPENSADA": null,
    "NAO_DISPENSADA": "não dispensada",
    "EXPIRADA": "expirado"
}


const ReceitaView = (props) => {
    const themeConfig = useAppThemeContext().getConfig();
    return(
        <ReceitaViewImplem 
            themeConfig={themeConfig}
            {...props}
        />
    )
}

export class ReceitaViewImplem extends Component {

    constructor(props) {
        super(props);

        this.state = { 
            showToolTip: false,
            dadosReceita: props.receita,
            validadaEm: props.validadaEm,
            chaveAutenticacao: props.chaveAutenticacao,
            enableToolTip: (props.tooltip && (props.tooltip===true || props.tooltip.toLowerCase()==="true")),
            enphasisValidacao: (props.enphasisValidacao && (props.enphasisValidacao===true || props.enphasisValidacao.toLowerCase()==="true")),
            validationBaseURL: process.env.REACT_APP_SERVICES_RECEITA_VALIDAR
        } 
    }

    _buildMensagemSituacaoPeriodo = (periodo) => {
        const situacao = periodo ? periodo.situacao : null;
        const mensagem = situacao && SITUACAO_PERIODO[situacao] ? SITUACAO_PERIODO[situacao] : null;

        return mensagem ? <span className="situacao-periodo">{mensagem}</span> : null;
    }

    _closeTooltip = () => {
        if(this.state.showToolTip) {
            this.setState({ showToolTip: false });
        }
    }

    _openTooltip = () => {
        if(!this.state.showToolTip) {
            this.setState({ showToolTip: true });
        }
    }

    _formatAuthenticationKey = (key) => {
        if(!key) {
            return null;
        }

        while(key.length < 24) {
            key = "0" + key;
        }

        const chunks = key.match(/.{1,6}/g);
        const formated = chunks.join("-");

        return formated;
    }

    _getReceitaEnderecoTelefone = () => {
        const serviceConfig = getAppServiceConfigByName(this.props.themeConfig, APP_SERVICE_LIST.RECEITAS);
        const enderecoTelefone = serviceConfig.enderecoTelefone;
        return { 
            enderecoL1: enderecoTelefone["endereco-l1"],
            enderecoL2: enderecoTelefone["endereco-l2"],
            telefone: enderecoTelefone.telefone
        }
    }
    
    _handleKeyDown = (event) => {
        if(this.state.showToolTip) {
            switch( event.keyCode ) {
                case ESCAPE_KEY:
                    event.stopPropagation();
                    this._closeTooltip();
                    break;
                default: 
                    break;
            }
        }
    }

    _isNull = (value, nullvalue) => {
        if(!value) {
            return nullvalue;
        } else {
            return value;
        }
    }

    ItensReceita(grupo) {
        let itemCount = 0;
        const itens = [];
        var tipoInternoExterno = "";
        var shownContinuo = false;
        for(var i=0; i<grupo.itens.length; i++) {
            let item = grupo.itens[i];
            let novoTipoInternoExterno = (item.usoInterno ? "Uso Interno" : "Uso Externo");
            let continuo = item.usoContinuo;

            if(!shownContinuo && continuo) {
                itens.push(
                    <div key={"item_" + itemCount++} className="continuo-row ">Uso Contínuo</div>
                );
                shownContinuo = true;
                tipoInternoExterno = "";
            }

            if(novoTipoInternoExterno !== tipoInternoExterno) {
                itens.push(
                    <div key={"item_" + itemCount++} className="interno-externo-row">{novoTipoInternoExterno}</div>
                );
                tipoInternoExterno = novoTipoInternoExterno;
            }

            let descricaoQuantidade = (item.descricao ? 
                        this._isNull(item.descricao, "") + (item.quantidade ? " - " + this._isNull(item.quantidade, "") : "") : 
                        "").toLocaleUpperCase();
            const listaFormaDeUso = [];
            this._isNull(item.formaDeUso, "").split(/\n/).map((line, indxLine) => {
                listaFormaDeUso.push(
                    <Fragment key={`line_${indxLine}`}>
                        {line}<br />
                    </Fragment>
                );
                return null;
            });

            itens.push(
                <li key={"item_" + itemCount++} className="item-row">
                    {descricaoQuantidade}
                    <ul>
                        <li>{listaFormaDeUso}</li>
                    </ul>
                </li>                
            );
        }

        return(
            <ul>
                {itens}
            </ul>                
        );

    }

    MensagemValidacao(grupo, validadaEm, chaveAutenticacao) {
        if(validadaEm) {
            const momentValidacao = <Moment format="DD/MM/YYYY HH:mm:ss" >{validadaEm}</Moment>
            return(
                <div className={this.state.enphasisValidacao ? "enphasis" : ""}>
                    Receita validada em: {momentValidacao}<br />
                    Autenticação: {chaveAutenticacao}
                </div>
            );
        } else {
            return(
                <div>
                    Para validar aponte para o QRCode ou valide em:<br />{this.state.validationBaseURL + '/' + grupo.localizador}
                </div>
            );
        }

    }

    Tooltip(grupo) {
        if(this.state.enableToolTip && this.state.showToolTip) {
            const magicLink = this.state.validationBaseURL + '/' + grupo.localizador;
            return(
                <div key="tool_tip" className="tooltip-wrapper" onClick={() => { this._closeTooltip(); }}>
                    <div className="tooltip-box">
                        <p>Para validar esta receita você pode apontar para o QRCode.</p>
                        <p> 
                            Se preferir visite:<br />
                            <a href={magicLink} rel="noopener noreferrer" target="_blank">{magicLink}</a>
                        </p>
                    </div>
                </div>
            );        
        } else {
            return null;
        }
    }

    QuadroDispensacao(listaRegistros, formato) {
        const formatarDados = (registro) => {
            return {
                dataDispensacao: <Moment format="DD/MM/YYYY" >{registro.dispensadaEm}</Moment>,
                horaDispensacao: <Moment format="HH:mm:ss" >{registro.dispensadaEm}</Moment>,
                anotacaoDispensacao: registro.anotacaoDispensacao,
                cnpjFarmacia: maskCNPJ(registro.cnpjFarmacia),
                nomeFarmacia: registro.nomeFarmacia,
                nomeFarmaceutico: registro.nomeFarmaceutico,
                crf: registro.crf + "-" + registro.ufConselho,
            };
        }

        if(listaRegistros) {
            switch(formato) {
                case RD_HOZIONTAL:
                    return(
                        <table>
                            <thead>
                                <tr>
                                    <th>Data</th>
                                    <th>Anotação</th>
                                    <th>Farmácia</th>
                                    <th>Farmacêutico</th>
                                    <th>CRF</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    listaRegistros.map((regDisp, indx) => { 
                                        const { dataDispensacao, horaDispensacao, anotacaoDispensacao, 
                                                cnpjFarmacia, nomeFarmacia, nomeFarmaceutico, crf } = formatarDados(regDisp);
                                        return(
                                            <tr key={`h_${regDisp.dispensadaEm}_${indx}`}>
                                                <td><div className="no-wrap">{dataDispensacao}</div> <div className="no-wrap">{horaDispensacao}</div></td>
                                                <td>{anotacaoDispensacao}</td>
                                                <td><div className="no-wrap">{cnpjFarmacia}</div> {nomeFarmacia}</td>
                                                <td>{nomeFarmaceutico}</td>
                                                <td><div className="no-wrap">{crf}</div></td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    );   
                case RD_VERTICAL:
                    return(
                        <table>
                            <tbody>
                                {
                                    listaRegistros.map((regDisp, indx) => { 
                                        const { dataDispensacao, horaDispensacao, anotacaoDispensacao, 
                                                cnpjFarmacia, nomeFarmacia, nomeFarmaceutico, crf } = formatarDados(regDisp);
                                        return(
                                            <Fragment key={`v_${regDisp.dispensadaEm}_${indx}`}>
                                                { (indx > 0) && 
                                                <tr>
                                                    <td colSpan={2} className="separador">
                                                        <div className="separador-registro"></div>
                                                    </td>
                                                </tr>
                                                }

                                                <tr>
                                                    <td>Data</td>
                                                    <td><div className="no-wrap">{dataDispensacao}</div> <div className="no-wrap">{horaDispensacao}</div></td>
                                                </tr>
                                                <tr>
                                                    <td>Anotação</td>
                                                    <td>{anotacaoDispensacao}</td>
                                                </tr>
                                                <tr>
                                                    <td>Farmácia</td>
                                                    <td><div className="no-wrap">{cnpjFarmacia}</div>{nomeFarmacia}</td>
                                                </tr>
                                                <tr>
                                                    <td>Farmacêutico</td>
                                                    <td>{nomeFarmaceutico}</td>
                                                </tr>
                                                <tr>
                                                    <td>CRF</td>
                                                    <td><div className="no-wrap">{crf}</div></td>
                                                </tr>
                                            </Fragment>
                                            );
                                    })
                                }
                            </tbody>
                        </table>
                    );  
                default:
                    return null;   
            }
        } else {
            return null;
        }
    }

    BlocoDispensacoes(key, grupo, formato) {
        if(formato !== RD_VERTICAL && formato !== RD_HOZIONTAL) {
            return null;
        }

        const receitaExpirada = grupo.receitaExpirada;
        const periodoAtual = grupo.periodoVisualizacao ? grupo.periodoVisualizacao : 1;
        const validade = grupo.validade ? grupo.validade : 1;
        const periodos = grupo.periodosValidade ? grupo.periodosValidade : { 1: null };
        const dispensasoes = grupo.dispensacoesPeriodos;
        const periodoAtualDispensado = dispensasoes && dispensasoes[periodoAtual] && dispensasoes[periodoAtual][0] ? true : false;
        const className = `registro-dispensacao-${formato === RD_VERTICAL ? "vert" : "horiz"}`;
        const blocoDispensacoes = [];

        // Contruir blocos por período
        let blocosExibidos = 0;
        for(let i=validade; i>=1; i--) {
            if(!receitaExpirada && !periodoAtualDispensado && i !== periodoAtual) {
                continue;
            }

            const periodo = periodos[i];
            const listDispensacao = dispensasoes ? dispensasoes[i] : null;
            const ordinalPeriodo = receitaExpirada || periodoAtualDispensado ? `${i}${String.fromCharCode(170)} ` : "";
            const title = periodo ? `${ordinalPeriodo}Retirada de ${periodo.de} até ${periodo.ate}:` : 
                            (listDispensacao ? "Registro de dispensação" : null);
            const situacao = periodo ? this._buildMensagemSituacaoPeriodo(periodo) : null;

            blocoDispensacoes.push(
                <div key={`${key}_${i}`} className={className}>
                    { (blocosExibidos > 0) && 
                        <div className="separador-registro"></div>
                    }

                    <div className="title">{title} {situacao}</div>

                    { this.QuadroDispensacao(listDispensacao, formato) }
                </div>
            );
            blocosExibidos++;
        }

        return blocoDispensacoes;
    }

    Receita() {
        const receita = this.state.dadosReceita;
        const grupo = receita.grupos[0];  // A consulta é feita a uma Receita a a um Grupo de Exibição específico
        const itensReceita = this.ItensReceita(grupo);

        const logoHeader = importThemeImage(this.props.themeConfig, "receitaView", "logo_receita-header");
        const logoImageAlt = getAppGeneralConfigPropertyByName(this.props.themeConfig, "nome-hospital");
        const { enderecoL1, enderecoL2, telefone } = this._getReceitaEnderecoTelefone();

        const identificacaoServidor = pascalCase((this._isNull(receita.servidorTitulo, "") + " " + this._isNull(receita.servidorNome, "")).trim());
        const codigoConselho = (this._isNull(receita.servidorSiglaConselho, "") + " " + this._isNull(receita.servidorRegistroConselho, "")).trim();
        const especialidadeNome = startCase(this._isNull(receita.especialidadeNome, ""));
        const tipoReceita = TIPO_RECEITA[receita.receituarioTipo.toUpperCase()];
        const nomePaciente = this._isNull(receita.pacNome, "").toUpperCase();
        const dataElaboracao = <Moment format="DD/MM/YYYY" >{receita.receituarioDthrCriacao}</Moment>;
        const chaveAutenticacao = this._formatAuthenticationKey(this.state.chaveAutenticacao);
        const headerEspecialClass = "_header-receita-especial" + (receita.receituarioTipo.toUpperCase() === "E" ? "" : " ocultar");

        const toolTip = this.Tooltip(grupo);
        const mensagemValidacao = this.MensagemValidacao(grupo, this.state.validadaEm, chaveAutenticacao);

        const qrCodeBlock = [];
        const qrCodeContent = this.state.validationBaseURL + '/' + grupo.tokenValidacao;
        qrCodeBlock.push(<QRCodeBlock keyName="camshot_frame_m" frameColor="#000000" frameThickness={3} size={250} value={qrCodeContent} enableToolTip={this.state.enableToolTip} onClick={this._openTooltip} />); // Portraid/Mobile view
        qrCodeBlock.push(<QRCodeBlock keyName="camshot_frame_d" frameColor="#000000" frameThickness={3} size={150} value={qrCodeContent} enableToolTip={this.state.enableToolTip} onClick={this._openTooltip} />); // Landscape/Desktop view

        const blocoDisp = [];
        blocoDisp.push(this.BlocoDispensacoes("reg_disp_m", grupo, RD_VERTICAL)); // Portrait/Mobile view
        blocoDisp.push(this.BlocoDispensacoes("reg_disp_d", grupo, RD_HOZIONTAL)); // Landscape/Desktop view

        return (
            <div id="contentWrapperID" className="content-wrapper">
                {toolTip}
                <div className="content-header-wrapper">
                    <div className="_head">
                        <div className="img-wrapper">
                            <img id="imgLogoID" className="img-logo" src={logoHeader} alt={logoImageAlt} />
                        </div>
                        <div className="head-content">
                            <label className="info">{identificacaoServidor}</label><br />
                            <label className="info">{codigoConselho}</label><br />
                            <label className="info">{especialidadeNome}</label>
                        </div>
                        <div className="flex-break-line"> </div>
                        <div className="tipo-receita">{tipoReceita}</div>
                    </div>
                    <div className="content-separator"><hr /></div>
                </div>
                <div className="content-body-wrapper">
                    <div className={headerEspecialClass}>
                        <div className="hre-title">Identificação do Emitente</div>
                        <div className="hre-info">
                            <div className="fld-row">
                                <div className="fld-name">Nome Completo:</div>
                                <div className="fld-info"><label>{identificacaoServidor}</label></div>
                                <div className="clear"> </div>
                            </div>
                            <div className="fld-row">
                                <div className="fld-name">Conselho profissional:</div>
                                <div className="fld-info"><label>{codigoConselho}</label></div>
                                <div className="clear"> </div>
                            </div>
                            <div className="fld-row">
                                <div className="fld-name">Endereço e Telefone:</div>
                                <div className="fld-info">
                                    <div>{enderecoL1}</div>
                                    <div>{enderecoL2}</div>
                                    <div>{telefone}</div>
                                </div>
                                <div className="clear"> </div>
                            </div>
                        </div>
                        <div className="hre-bottom-line"><hr /></div>
                    </div>
                    <div className="_body">
                        <div className="paciente">{nomePaciente}</div>
                        <div className="items">
                            {itensReceita}
                        </div>
                    </div>
                    <div className="wrapper-registro-dispensacao">
                        {blocoDisp[0]}
                        {blocoDisp[1]}
                    </div>
                </div>
                <div className="content-footer-wrapper">
                    <div className="validacao">
                        <div className="qrcode-pv">
                            {qrCodeBlock[0]}
                        </div>
                        <div className="validacao-msgs-wrapper">
                            <div className="elaborado">Esta receita foi elaborada no dia {dataElaboracao}</div>
                            <div className="mensagem-validacao no-select">{mensagemValidacao}</div>
                        </div>
                        <div className="qrcode-lv">
                            {qrCodeBlock[1]}
                        </div>
                        <div className="clear"> </div>
                    </div>
                    <div className="content-separator"><hr /></div>
                    <div className="_footer">
                        <label>{enderecoL1}</label><br />
                        <label>{enderecoL2}</label><br />
                        <label>{telefone}</label>
                    </div>
                </div>
            </div>
        );
    }
    
    componentDidMount() {
        if(this.state.enableToolTip) {
            document.addEventListener("keydown", this._handleKeyDown, true);
        }
    }

    componentWillUnmount() {
        if(this.state.enableToolTip) {
            document.removeEventListener("keydown", this._handleKeyDown, true);
        }
    }

    render() {
        if(!this.state.dadosReceita) {
            throw new Error("Não há dados de receita para exibição");
        }

        if(!this.props.themeConfig || this.props.themeConfig.constructor !== ({}).constructor) {
            throw new Error("Theme configuration not informed or is invalid");
        }

        return this.Receita();
    }    

} 

export default ReceitaView;