/*
** @name: Meu Clínicas - recuperarSenha
** @author: 
** @date:
** @description: Módulo para recuperação de senha do usuario.
** 
** @update: Março 2021 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Atualizado para novo layout da aplicação e funcionamento com cards
*/

import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';

import { useAppControllerContext } from '../../core/appControllerContext.js';
import { ThemeImage } from '../../core/appThemeUtils.js';

import AppCardModuleBasicWrapper from '../../components/general/appCardModuleBasicWrapper/appCardModuleBasicWrapper.js';
import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';
import EmailCPFLocalizador from '../../components/fields/emailCPFLocalizador/emailCPFLocalizador.js';

import usuarioClient from '../../apiClients/login/usuarioClient.js';

// Import module styles
import './recuperarSenha.scss'; 


const STEP_USER_IDENTIFICATION = 'USER_IDENTIFICATION';
const STEP_EMAIL_SENT = 'EMAIL_SENT';

const RecuperarSenha = (props) => {
    const appControllerContext = useAppControllerContext();
    return(
        <RecuperarSenhaImplem
            appControllerContext={appControllerContext}
            {...props}
        />
    )
}

class RecuperarSenhaImplem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentStep: STEP_USER_IDENTIFICATION,
            STEP_USER_IDENTIFICATION: {
                cpfEmail: {
                    value: null,
                    valid: false
                }
            },
            STEP_EMAIL_SENT: {
            },
            msgErro: null
        };
    }

    _clearMessages = () => {
        this.setState({ msgErro: null });
    }

    _handleClose = () => {
        this.props.appControllerContext.methods.doCardFadeOut();
    }

    _handleEmailCPFChange = (value, valid) => {
        this._clearMessages();

        let updatedStep = this.state.STEP_USER_IDENTIFICATION;
        updatedStep.cpfEmail = {
            value,
            valid
        };

        this.setState({STEP_USER_IDENTIFICATION: updatedStep});
    } 

    _recuperarSenhaPorEmail = (e) => {
        e.preventDefault(); 

        this._clearMessages();
        usuarioClient.recuperarSenhaPorEmail(
            this.state.STEP_USER_IDENTIFICATION.cpfEmail.value,
            (res => {                
                this.setState({
                    currentStep: STEP_EMAIL_SENT
                });             
            }),
            (err => {  
                let header = null;
                let message = null;

                if (err.response && err.response.data) {
                    header = 'Atenção'
                    message = err.response.data;
                } else {
                    header = 'Ops...'
                    message = 'Ocorreu um erro ao processar sua requisição.';
                } 

                this.setState({msgErro: {header, message}}); 
            })
        );
    }

    _validateRecoveryMethodForm = () => {
        return this.state.STEP_RECOVERY_METHOD.selectedOption !== null;
    }

    _validateUserIdentificationForm = () => {
        return this.state.STEP_USER_IDENTIFICATION.cpfEmail.valid;             
    }

    render() {
        return(
            <AppCardModuleBasicWrapper moduleName="recuperar-senha">

                <Form name="frmMain" className="content-form">
                    { this.state.currentStep === STEP_USER_IDENTIFICATION && 
                    <>
                        <div className='imagem-principal'>
                            <ThemeImage module="recuperarSenha" imageId="esqueceu-senha" />
                        </div>

                        <div className='info-adicional'>
                            <p>Informe o CPF ou o e-mail utilizado no cadastro para prosseguir na recuperação de senha.</p>
                        </div>

                        <div className="float-label-field email-cpf">
                            <EmailCPFLocalizador 
                                id='input-cpf-email' 
                                name='cpfEmail' 
                                labelText='CPF ou E-mail'
                                onChange={this._handleEmailCPFChange}
                                disableCPF={false}
                                disableEmail={false}
                                disableLocalizador={true}
                            />
                        </div> 

                        { (this.state.msgErro) && 
                        <AppMessageBox
                            id="msg-recuperar-senha-error"
                            className="error"
                            messageData={this.state.msgErro}
                        />
                        }

                        <div className="main-action">
                            <button 
                                id="button-recuperar"
                                className="app-form-button"
                                disabled={!this._validateUserIdentificationForm()}
                                onClick={e => this._recuperarSenhaPorEmail(e)}>
                                Recuperar
                            </button>
                        </div> 

                    </>
                    }

                    { this.state.currentStep === STEP_EMAIL_SENT && 
                    <>
                        <div className='info-principal'>
                            Sua solicitação foi enviada com sucesso!
                        </div>

                        <div className='info-adicional'>
                            <p>Te enviamos um e-mail com os passos para a recuperação de sua senha. Em breve você deve receber o e-mail em sua caixa postal com o link para redefinir sua senha.</p>
                            <p>Caso não encontre o email verifique a pasta de lixo eletrônico.</p>
                        </div>
                        <div className="main-action">
                            <button 
                                id="button-ok"
                                className="app-form-button"
                                onClick={() => this._handleClose()}>
                                Ok
                            </button>
                        </div> 

                    </>
                    }
                </Form>

            </AppCardModuleBasicWrapper>
        );
    }
}

export default RecuperarSenha;