/*
** @name: Meu Clínicas - fileItem
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Dezembro 2022
** @description: Componente para renderizar item na lista e arquivos de upload
*/
import { ThemeImage } from "../../../core/appThemeUtils";
import React from "react";

const FileTypeImage = (props) => {
    const mimeType = `${props.mimeType || ""}`.toLowerCase();
    switch(mimeType) {
        case "application/pdf": 
            return <ThemeImage module="multiFileSelect" imageId="icon_file-pdf" onErrorReturn="PDF" />;
        case "image/png":
            return <ThemeImage module="multiFileSelect" imageId="icon_file-png" onErrorReturn="PNG" />;
        case "image/jpeg":
            return <ThemeImage module="multiFileSelect" imageId="icon_file-jpg" onErrorReturn="JPG" />;
        default:
            return <ThemeImage module="multiFileSelect" imageId="icon_file-generic" onErrorReturn="file" />;
    }
}

const FileItem = (props) => {
    const { className, description, mimeType, onRemove } = props;
    const extraParams = {};
    if(description && description.trim()) {
        extraParams.title = description;
    }

    return(
        <div className={`mfs-button file-button${className ? ` ${className}` : ""}`} {...extraParams}>
            <div className="file-type-img-wrapper">
                <FileTypeImage mimeType={mimeType} />
            </div>
            { onRemove &&
            <div className={`file-remove-button${onRemove ? " enabled" : ""}`} onClick={() => onRemove()}>
                <ThemeImage module="multiFileSelect" imageId="icon_remove-file" onErrorReturn="x" />
            </div>
            }
        </div>
    )
}

export default FileItem;