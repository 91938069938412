/*
** @name: Meu Clínicas - aceiteTermosSessao
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2021
** @description: Componente para exibir termos de usos dentor da sessão, exigindo o aceite.
*/

import React, { Component } from 'react';
import { Checkbox } from 'semantic-ui-react';

import AppModal from '../appModal/appModal.js';


// Import module styles
import './aceiteTermosSessao.scss';


const _TERMOS_ACEITE_ = require('../../../theme/resources/termosAceite.json');

class AceiteTermosSessao extends Component {

    constructor(props) {
        super(props);

        const { aceite } = props;
        this.state = {
            aceite: aceite ? true : false,
            erroMessage: null
        }
    }

    _toggleAceite = () => {
        this.setState({ 
            aceite: !this.state.aceite,
            erroMessage: null
        });
    }

    _handleCancelar = (e) => {
        if(this.props.onCancel)
            this.props.onCancel(e);
    }

    _handleConfirmar = (e) => {
        if(!this.state.aceite) {
            this.setState({
                erroMessage: "Para confirmar você deve primeiro marcar que aceita os termos."
            });
            return;
        }

        if(this.props.onConfirm)
            this.props.onConfirm(e);
    }

    render() {
        const conteudoTermo = _TERMOS_ACEITE_.conteudo;
        const termosAceite = { 
            __html: conteudoTermo && conteudoTermo.constructor===Array ? conteudoTermo.join("\n") : null 
        };
        const ExtraContent = this.props.mensagemExtra ? this.props.mensagemExtra : () => { return null };

        return(
            <div id="aceiteTermosWrapperId">
                <AppModal>
                    <div id="aceiteTermosContentId">
                        <div className="termos">
                            <div dangerouslySetInnerHTML={termosAceite} />
                        </div>

                        <ExtraContent />

                        <div className="aceite" onClick={() => this._toggleAceite()}>
                            <Checkbox
                                id='checkbox-aceite'
                                name='aceite'
                                className='checkbox-aceite'
                                label="Li e aceito os termos de uso e privacidade."
                                checked={this.state.aceite}
                                onChange={() => { }} />
                        </div>

                        { this.state.erroMessage && 
                        <div className="error-message">
                            { this.state.erroMessage }
                        </div>
                        }

                        <div className="action-buttons">
                            <div className="left-btn">
                                <button 
                                    type="button" 
                                    className="btn btn-aceite"
                                    disabled={false}
                                    onClick={e => { this._handleConfirmar(e) }} >Confirmar</button>
                            </div>
                            <div className="right-btn">
                                <button 
                                    type="button" 
                                    className="btn btn-aceite"
                                    onClick={e => { this._handleCancelar(e) }} >Cancelar</button>
                            </div>
                            <div className="clear"> </div>
                        </div>
                    </div>
                </AppModal>
            </div>
        );
    }
} export default AceiteTermosSessao;
