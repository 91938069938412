import requestAuth from "../../core/requestAuth";
import requestNoAuth from "../../core/requestNoAuth";

import { apiUrl } from "../../core/apiUrl.js";


const API_PORT = 8088;
const API_SERVICE = 'notification-service';
const API_VERSION = 'v2';

class NotificationClient {

    actuatorInfo(successCallback, errorCallback) {
        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, null, '/actuator/info'),
            successCallback,
            errorCallback
        );
    }

    buildInfo(successCallback, errorCallback) {
        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, null, '/build-info'),
            successCallback,
            errorCallback
        );
    }

    statistics(serviceToken, fingerprint, successCallback, errorCallback) {
        const queryParams = {
            jwtServiceToken: serviceToken,
            fingerprint: fingerprint
        }
        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/statistics'),
            successCallback,
            errorCallback,
            queryParams
        );
    }

    noticesList(pacCodigo, successCallback, errorCallback) {
        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, `/notices/list/${pacCodigo}`),
            successCallback,
            errorCallback
        );
    }

    pushDeviceRegister(requestData, successCallback, errorCallback) {
        requestAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/push/register-device'),
            requestData,
            successCallback,
            errorCallback
        );
    }
}

const notificationClient = new NotificationClient();
export default notificationClient;
export {
    API_PORT,
    API_SERVICE,
    API_VERSION
};