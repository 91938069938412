import React from 'react';

/*
** @name: Meu Clínicas - agendamentoConsultas
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Agosto 2022
** @description: Conteúdo de seleção da especialidade
*/

import { EMPTY_HREF } from '../../core/utils.js';

import { ThemeMessage } from '../../core/appThemeUtils.js';
import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';


const _getMensagemSolicitacaoAgendamento = (regEspecialidade) => {
    const { possuiAgendamentoMesmaGenerica, possuiSolicitacao, possuiSolicitacaoMesmaGenerica } = regEspecialidade || {};
    const messageData = {};
    if(possuiAgendamentoMesmaGenerica) {
        messageData.header = "Atenção";
        messageData.message = <ThemeMessage messageId="agendamento-consulta_selecao-agendamento-generica" />;
    } else if(possuiSolicitacao) {
        messageData.header = "Atenção";
        messageData.message = <ThemeMessage messageId="agendamento-consulta_selecao-possui-solicitacao" />;
    } else if(possuiSolicitacaoMesmaGenerica) {
        messageData.header = "Atenção";
        messageData.message = <ThemeMessage messageId="agendamento-consulta_selecao-possui-solicitacao-generica" />;
    } else {
        return null;
    }
    return messageData;
}


const selecaoEspecialidade = (props) => {
    const { registroEspecialidade, especialidadeList, onObterPermissaoSolicitacao, onChangeEspecialidade, onSolicitarAgendameto } = props;
    const { agendaDisponivel, orientacao } = registroEspecialidade || {};
    const { isHiddenSolicAgend, showLinkSolicAgendamento, permiteSoliciarPorErro, solicitacaoImpedida } = onObterPermissaoSolicitacao(registroEspecialidade);
    const messageServicoSolicitacao = _getMensagemSolicitacaoAgendamento(registroEspecialidade);
    const espSeq = registroEspecialidade ? registroEspecialidade.seq : null;
    return(
        <div className="step-one-wrapper">
            <div className="instructions-section">
                <div className="title">Siga os passos para realizar o agendamento de uma consulta:</div>
                <div className="text">Escolha a agenda que você  possui acompanhamento e verifique se há mensagem de orientação feita por sua equipe na última consulta.</div>
            </div>

            <div className="selecao-especialidade-wrapper">
                <div className="especialidade-field">
                    <select
                        id="select-especialidade"
                        name="especialidade" 
                        className={!espSeq ? " place-holder" : ""}
                        value={espSeq || ""}
                        onChange={(e) => onChangeEspecialidade(e.target.value)}>

                        <option value="" className="place-holder">{`>>> SELECIONE UMA AGENDA <<<`}</option>
                        { especialidadeList.map((item, indx) => {
                            const value = item.seq;
                            const description = item.nomeEspecialidade;
                            return(
                                <option key={`opt_esp_${indx}`} value={value}>{description}</option>
                            )
                        }) }

                    </select>
                </div>
            </div>

            { (registroEspecialidade && (!agendaDisponivel || permiteSoliciarPorErro)) &&
            <>
                { !agendaDisponivel &&
                <div className="no-schedule-section">
                    Não há disponibilidade para marcação de consultas na agenda em acompanhamento.
                </div>
                }

                { !isHiddenSolicAgend &&
                <>
                    { showLinkSolicAgendamento &&
                    <>
                        { permiteSoliciarPorErro && 
                            <AppMessageBox 
                                id="msg-solicitacao-information" 
                                className="information"
                                messageData={{
                                    header: null,
                                    message: <ThemeMessage messageId="agendamento-consulta_solicitacao-por-erro" />
                                }} 
                            />
                        }
                        <div className="solicitar-agendamento">
                            <a href={EMPTY_HREF} id="link-solicitar-agendamento" onClick={() => onSolicitarAgendameto()}>Clique aqui</a> para solicitar o agendamento de uma consulta.
                        </div>
                    </>
                    }

                    { (agendaDisponivel && permiteSoliciarPorErro && solicitacaoImpedida) &&
                        <AppMessageBox 
                            id="msg-solicitacao-impedida" 
                            className="error"
                            messageData={{
                                header: null,
                                message: <ThemeMessage messageId={messageServicoSolicitacao ? "agendamento-consulta_erro-agendamento-solicitacao-impedida" : "agendamento-consulta_erro-agendamento-solicitacao-impedida-sem-mensagem"} />
                            }} 
                        />
                    }

                    { messageServicoSolicitacao &&
                        <AppMessageBox 
                            id="msg-solicitacao-information" 
                            className="information"
                            messageData={messageServicoSolicitacao} 
                        />
                    }
                </>
                }
            </>
            }

            { (agendaDisponivel && orientacao) &&
            <div className="recomendations-section">
                {orientacao}
            </div>
            }

        </div>
    )
}

export default selecaoEspecialidade;