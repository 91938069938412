import React from 'react';
import { isAndroid, isIOS, isSafari, isMobile, isTablet } from 'react-device-detect';

import { getApplicationConfiguration } from '../../core/appConfiguration.js';
import { importThemeImage } from '../../core/appThemeUtils.js';

const applicationConfiguration = getApplicationConfiguration();


// Import Images
const ErrorCookiesDesktop = importThemeImage(applicationConfiguration, 'app', 'error-cookies_desktop');
const ErrorCookiesMobile = importThemeImage(applicationConfiguration, 'app', 'error-cookies_mobile');
const ErrorDesktopGeneral = importThemeImage(applicationConfiguration, 'app', 'error-browser_desktop-general');
const ErrorDesktopIOS = importThemeImage(applicationConfiguration, 'app', 'error-browser_desktop-ios');
const ErrorMobileAndroid = importThemeImage(applicationConfiguration, 'app', 'error-browser_mobile-android');
const ErrorMobileIOS = importThemeImage(applicationConfiguration, 'app', 'error-browser_mobile-ios');
const ErrorMobileIOSStandalone = importThemeImage(applicationConfiguration, 'app', 'error-browser_mobile-ios-standalone');


const CookiesNotEnabled = () => {
    var errorCookiesPath = isMobile ? ErrorCookiesMobile : ErrorCookiesDesktop;
    return (
        <div className='app-error'>       
            <img src={errorCookiesPath} alt="Erro cookies não habilitados"/>
        </div>
    );
}

const NotSupportedBrowser = () => {
    const getBrowserErrorImage = () => {
        if(isMobile || isTablet) {
            if(isIOS) {
                return ErrorMobileIOS;
            }

            if(isAndroid) {
                return ErrorMobileAndroid;
            }
        }
        if(isIOS && !isSafari) {
            return ErrorDesktopIOS;
        }
        return ErrorDesktopGeneral;
    }

    return (
        <div className='app-error'>
            <img src={getBrowserErrorImage()} alt="Erro browser não suportado" />
        </div>
    );
}

const HomeShortcutNotAllowed = () => {
    return(
        <div className='app-error'>       
            <img src={ErrorMobileIOSStandalone} alt="Erro acesso por atalho"/>
        </div>
    );
}

export {
    CookiesNotEnabled,
    NotSupportedBrowser,
    HomeShortcutNotAllowed
}