import requestAuth from "../../core/requestAuth";
import requestNoAuth from "../../core/requestNoAuth";

import { apiUrl } from "../../core/apiUrl.js";


const ADMIN_SERVICE_LIST = {
    CONTENT_ADMIN: "CONTENT_ADMIN",
    DASHBOARD: "DASHBOARD",
    EXT_SRV_API: "EXTERNAL_SERVICE_API",
    PE_EXPORT: "EXPORT_PESQUISA_EXPERIENCIA",
    SCC_EXPORT: "EXPORT_SOLICITACAO_AGENDAMENTO_CONSULTA",
    SCC_NOTIFICATION: "NOTIFICATION_SOLICITACAO_REAGENDAMENTO_CONSULTA_CANCELADA"
}

const API_PORT = 8081;
const API_SERVICE = 'login-service';
const API_VERSION = 'v2';

class LoginClient {

    actuatorInfo(successCallback, errorCallback) {
        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, null, '/actuator/info'),
            successCallback,
            errorCallback
        );
    }

    buildInfo(successCallback, errorCallback) {
        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, null, '/build-info'),
            successCallback,
            errorCallback
        );
    }

    statistics(serviceToken, fingerprint, successCallback, errorCallback) {
        const queryParams = {
            jwtServiceToken: serviceToken,
            fingerprint: fingerprint
        }
        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/statistics'),
            successCallback,
            errorCallback,
            queryParams
        );
    } 

    getPermissoes(pacCodigo, successCallback, errorCallback) {
        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, `/permissoes/${pacCodigo}`),
            successCallback,
            errorCallback
        );
    }

    logout(token, successCallback, errorCallback, avoidForcedLogout) { 
        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, `/logout/${token}`),
            successCallback,
            errorCallback,
            null,
            avoidForcedLogout
        );
    }

    login(cpfOuEmail, senha, fingerprint, successCallback, errorCallback) {
        const userData = {
            user: cpfOuEmail,
            password: senha,                        
            fingerprint: fingerprint,
        };

        requestNoAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/login'),
            userData,
            successCallback,
            errorCallback
        );
    }

    loginLocalizador(localizador, answers, fingerprint, successCallback, errorCallback) {
        var userData = {
            localizadorHCPA: localizador,
            answers: answers,
            fingerprint: fingerprint
        };

        requestNoAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/login/localizador'),
            userData,
            successCallback,
            errorCallback
        );
    }

    loginToken(jwtToken, fingerprint, pacCodigo, successCallback, errorCallback) {
        var userData = {
            jwtToken: jwtToken,
            fingerprint: fingerprint,
            pacCodigo: pacCodigo
        };

        requestNoAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/login/token'),
            userData,
            successCallback,
            errorCallback
        );
    }

    localizadorhcpa(localizadorHCPA, successCallback, errorCallback) {
        var userData = {
            localizadorHCPA: localizadorHCPA
        };

        requestNoAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/localizadorhcpa'),
            userData,
            successCallback,
            errorCallback
        );
    }

    localizadorExame(soeSeq, successCallback, errorCallback) {
        var queryParams = {
            soeSeq: soeSeq
        };

        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/localizadorexame'),
            successCallback,
            errorCallback,
            queryParams
        );
    }

    refreshToken(pacCodigo, extendedLifetime, successCallback, errorCallback) {
        const queryParams = {
            pacCodigo, 
            extendedLifetime
        }
        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/token-refresh'),
            successCallback,
            errorCallback,
            queryParams
        );
    }
    
    serviceToken(service, fingerprint, username, password, successCallback, errorCallback) {
        var userData = {
            service: service,
            fingerprint: fingerprint,
            username: username,
            password: password
        };

        requestNoAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/service-token'),
            userData,
            successCallback,
            errorCallback
        );
    }

    validateLoginToken(token, fingerprint, successCallback, errorCallback) {
        var queryParams = {
            jwtToken: token,
            fingerprint: fingerprint
        };

        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/login/token/validate'),
            successCallback,
            errorCallback,
            queryParams
        );
    }

    validateServiceToken(token, fingerprint, service, successCallback, errorCallback) {
        var queryParams = {
            jwtServiceToken: token,
            fingerprint: fingerprint,
            service: service
        };

        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/service-token/validate'),
            successCallback,
            errorCallback,
            queryParams
        );
    }

} 

const loginClient = new LoginClient();
export default loginClient;
export {
    ADMIN_SERVICE_LIST,
    API_PORT,
    API_SERVICE,
    API_VERSION
};