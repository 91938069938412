import requestAuth from "../../core/requestAuth";
import websocketUtils from "../../core/websocketUtils";

import { apiUrl } from "../../core/apiUrl.js";
import { API_PORT, API_SERVICE, API_VERSION } from './consultasClient.js';


const TELEATENDIMENTO_TOPIC = '/user/ws-topic/teleatendimento';
const TELEATENDIMENTO_PUBLISH = '/app/ws-post/teleatendimento';

const MESSAGE_TYPE = {
    CHECKIN: 'CHECKIN',
    READY_TO_START: 'READY_TO_START',
    ECHO: 'ECHO'
}

const MESSAGE_TYPE_EXTENDED = {
    ...MESSAGE_TYPE,
    SUBSCRIPTION_CONFIRMATION: 'SUBSCRIPTION_CONFIRMATION'
}

class TeleatendimentoClient {

    infoConsulta(pacCodigo, numeroConsulta, successCallback, errorCallback) { 
        const queryParams = {
            pacCodigo: pacCodigo,
            numeroConsulta: numeroConsulta
        };
        
        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/teleatendimento/info-consulta'),
            successCallback,
            errorCallback,
            queryParams);
    }

    informarReadyToStart(params, successCallback, errorCallback) { 
        requestAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/teleatendimento/ready-to-start'),
            params,
            successCallback,
            errorCallback);
    }

    obterJitsiToken(pacCodigo, numeroConsulta, userDisplayName, successCallback, errorCallback) { 
        const queryParams = {
            pacCodigo,
            numeroConsulta,
            userDisplayName
        };
        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/teleatendimento/jwt'),
            successCallback,
            errorCallback,
            queryParams);
    }

    obterJitsiTokenExternal(queryParams, successCallback, errorCallback) { 
        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/teleatendimento/jwt'),
            successCallback,
            errorCallback,
            queryParams);
    }

    obterJitsiTokenExternalFromToken(jwtServiceToken, fingerprint, successCallback, errorCallback) { 
        const queryParams = {
            jwtServiceToken, 
            fingerprint            
        };
        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/teleatendimento/jwt-from-token'),
            successCallback,
            errorCallback,
            queryParams);
    }

    realizarCheckin(pacCodigo, numeroConsulta, successCallback, errorCallback) {
        requestAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/teleatendimento/checkin'),
            { pacCodigo, numeroConsulta },
            successCallback,
            errorCallback
        );
    }

    sairSalaEspera(pacCodigo, numeroConsulta, successCallback, errorCallback) {
        requestAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/teleatendimento/sair-sala'),
            { pacCodigo, numeroConsulta },
            successCallback,
            errorCallback
        );
    }

    videoChamadaFeedback(payload, successCallback, errorCallback) {
        requestAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/teleatendimento/video-feedback'),
            payload,
            successCallback,
            errorCallback
        );
    }

    wsPatientCheckin = (stompClient, pacCodigo, numeroConsulta) => {
        if(!stompClient) {
            return null;
        }
        this.wsTopicPublish(stompClient, { 
            type: MESSAGE_TYPE_EXTENDED.CHECKIN,
            pacCodigo,
            attributes: {
                numeroConsulta
            }
        });
    }

    wsDisconnect = (stompClient) => {
        websocketUtils.disconnect(stompClient);
    }

    /**
     * 
     * @param {*} events object with stomp client events.
     * 
     * Available events are: { onChangeState, onConnect, onDisconnect, onWebSockectClose, onWebSockectError, onStompError, onUnhandledFrame, onUnhandledMessage, onUnhandledReceipt, onMessageReceived, onSubscriptionConfirmed }
     * @returns Stomp Client object
     */
    wsTopicConnect = (pacCodigo, events) => { 
        let stompClient = null;
        const subscribeHeaders = {
            pacCodigo
        }
        const origOnConnect = events.onConnect;
        const origOnMessageReceived = events.onMessageReceived;
        const origOnSubscriptionConfirmed = events.onSubscriptionConfirmed;
        delete events.onMessageReceived;
        events.onConnect = frame => {
            if(origOnConnect) {
                origOnConnect(frame);
            }
            const messageHandler = frame => {
                try {
                    const jsonBody = JSON.parse(frame.body) || {};
                    if(jsonBody.type===MESSAGE_TYPE_EXTENDED.SUBSCRIPTION_CONFIRMATION) {
                        if(jsonBody.pacCodigo===pacCodigo && jsonBody.attributes && jsonBody.attributes.confirmed) {
                            if(origOnSubscriptionConfirmed) {
                                origOnSubscriptionConfirmed(stompClient, TELEATENDIMENTO_TOPIC);
                                return;
                            }
                        } else { // diconect confirmation failed
                            this.wsDisconnect(stompClient);
                        }
                    }
                } catch(e) {
                    console.error('Error parsing websocket message body');
                }
                if(origOnMessageReceived) {
                    origOnMessageReceived(frame);
                }
            }

            // Subscribe to topic
            websocketUtils.subscribe(stompClient, TELEATENDIMENTO_TOPIC, subscribeHeaders, messageHandler);

            // Send message requesting subscription confirmation
            this.wsTopicPublish(stompClient, { 
                type: MESSAGE_TYPE_EXTENDED.SUBSCRIPTION_CONFIRMATION,
                pacCodigo,
                attributes: {
                    topic: TELEATENDIMENTO_TOPIC
                }
            });
        }
            
        stompClient = websocketUtils.getStompClient(apiUrl(API_PORT, API_SERVICE, null, '/ws-entry'), {}, events);
        
        websocketUtils.connect(stompClient);
        return stompClient;
    }

    wsTopicPublish = (stompClient, body) => {
        websocketUtils.publish(stompClient, TELEATENDIMENTO_PUBLISH, {}, body);
    }
}

const teleatendimentoClient = new TeleatendimentoClient();
export default teleatendimentoClient;
export {
    MESSAGE_TYPE
}