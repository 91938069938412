/*
** @name: Meu Clínicas - faqEditGrid
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Fevereiro 2022
** @description: Módulo para gerar grid de edição das perguntas/respostas do FAQ
** 
*/

import React, { useState, useEffect } from 'react';

import utils from '../../core/utils.js';

import AppExternalServiceInfoMessage from '../../components/general/appExternalServiceInfoMessage/appExternalServiceInfoMessage.js';

import GridIcon from './gridIcon.js';


// Import module styles
import './scss/faqEditGrid.scss';


const FaqEditGrid = (props) => {
    const { listFaq, erroProcessamento, onItemEdit, onItemInsert, onItemPreview, onSave, onItemDelete } = props;
    const [content, setContent] = useState({ items: [] });
    const [originalContent, setOriginalContent] = useState({ items: [] });
    const [orderInputValue, setOrderInputValue] = useState("");
    const [orderEditingIndex, setOrderEditingIndex] = useState(null);

    const _buildComparingList = (items) => {
        const result = [];
        items.forEach(item => {
            if(item.id) {
                result[item.id] = { ...item };
                delete result[item.id].imagens; // remove images to avoid too many memory usage
            }
        });
        return result;
    }

    const _buildGridChangesToSave = (items) => { // Build a lista of all changed items indexed by its 'id' with grid editable fields
        const changes = [];
        if(utils.isArray(items)) {
            items.forEach(item => {
                if(_checkItemChanged(item)) {
                    changes.push({ id: item.id, ordem: item.ordem });
                }
            });
        }

        return changes;
    }

    const _checkItemChanged = (item) => {
        const original = originalContent.items[item.id];
        if(item.id && original) { // compare editable - only order can be changed in grid mode
            return item.ordem !== original.ordem;
        }
        return true;
    }

    const _checkItemsChanges = () => {
        return content.items.find(item => _checkItemChanged(item)) ? true : false;
    }

    const _editOrder = (index, objId) => {
        if(orderEditingIndex!==index) {
            setTimeout(() => {
                const obj = document.getElementById(objId);
                if(obj) {
                    obj.focus();
                }
            }, 10);
        }
        setOrderInputValue(index);
        setOrderEditingIndex(orderEditingIndex!==index ? index : null);
    }

    const _gridSetOrder = (index) => {
        if(/^[0-9]{1,3}$/.test(orderInputValue)) {
            let updItems = content.items;
            const currOrder = updItems[index].ordem;
            const newOrder = parseInt(orderInputValue);
            if(currOrder !== newOrder) {
                updItems[index].ordem = newOrder + (newOrder > currOrder ? 0.5 : -0.5);
                updItems = _sortByOrdem(updItems);
                updItems.forEach((item, indx) => { if(item.ordem !== indx) { item.ordem = indx; } });
                setContent({ items: updItems });
            }
            setOrderEditingIndex(null);
        }
    }

    const _gridSwapOrder = (item, increase) => {
        const currOrder = item.ordem;
        const newOrder = currOrder + (increase ? 1 : -1);
        if(newOrder >= 0 && newOrder < content.items.length) {
            const updItems = content.items;
            updItems[currOrder].ordem = newOrder;
            updItems[newOrder].ordem = currOrder;
            setContent({ items: _sortByOrdem(updItems) });
        }
    }

    const _inputChange = (value) => {
        const regEx = /^[0-9]{0,3}$/;
        if(!value || regEx.test(value)) {
            setOrderInputValue(value);
        }
    }

    const _sortByOrdem = (arrItems) => arrItems.sort((a, b) => a.ordem < b.ordem ? -1 : (a.ordem > b.ordem ? 1 : 0));

    useEffect(() => {
        const listItems = (!listFaq || listFaq.constructor!==Array) ? [] : listFaq;
        const sortedItems = _sortByOrdem(listItems);
        const originalItems = _buildComparingList(sortedItems);
        sortedItems.forEach((item, indx) => { item.ordem = indx; }); // Atualiza ordem efetiva
        setContent({ items: sortedItems });
        setOriginalContent({ items: originalItems });
        setOrderInputValue("");
        setOrderEditingIndex(null);
    }, [listFaq]);
    if(content.items.length<1) {
        return null;
    }

    const isGridChanged = _checkItemsChanges();
    return(
        <div id="faqEditGridWrapper" className="edit-grid-wrapper">
            { content.items.map((currItem, indx) => {
                const onOrderUp = indx > 0 ? () => _gridSwapOrder(currItem, false) : null;
                const onOrderDown = indx < (content.items.length-1) ? () => _gridSwapOrder(currItem, true) : null;
                const hasTags = currItem.tags.length ? true : false;
                const hasImages = currItem.imagens && currItem.imagens.length ? true : false;
                const tagList = !hasTags ? "" : currItem.tags.reduce((prev, curr) => prev = `${prev} | ${curr}`);
                const inputChangeOrderId = `changeOrder_${indx}`;
                const enableChangeConfirmation = /^[0-9]{1,3}$/.test(orderInputValue);
                const itemChanged = _checkItemChanged(currItem);
                return(
                    <div className={`faq-item-wrapper${itemChanged ? " changed" : ""}${currItem.hideFromList ? " hide-from-list" : ""}`} key={'pergunta-' + indx}>

                        <div className="inset-before" onClick={() => onItemInsert(indx, isGridChanged)}>+</div>

                        <div className="item-info-wrapper">
                            <div className="order-wrapper">
                                <div className="current-order-wrapper">
                                    <div className="order-value" onClick={() => _editOrder(indx, inputChangeOrderId)}>{indx}</div>
                                    <div className={`edit-order-wrapper${indx===orderEditingIndex ? " enabled" : ""}`}>
                                        <input id={inputChangeOrderId} name="changeOrder" className="change-order-input"
                                            type="text" placeholder="" autoComplete="off" onChange={(e) => { _inputChange(e.target.value) }}
                                            value={orderInputValue} />
                                        <div
                                            className={`change-order-ok${enableChangeConfirmation ? " enabled" : ""}`} 
                                            onClick={enableChangeConfirmation ? () => _gridSetOrder(indx) : null}>Ok</div>
                                    </div>
                                </div>
                                <div className="order-change-wrapper">
                                    <GridIcon visible={true} className={`order-up${onOrderUp ? "" : " disabled"}`} title="Diminuir ordem" imageId="icon_order-up" onClick={onOrderUp}/>
                                    <GridIcon visible={true} className={`order-down${onOrderDown ? "" : " disabled"}`} title="Aumentar ordem" imageId="icon_order-down" onClick={onOrderDown}/>
                                </div>
                            </div>
                            <div className="item-title">{currItem.titulo}</div>
                        </div>

                        <div className="item-buttons-wrapper">
                            <GridIcon visible={currItem.questionId} className="id-mark" title={`Possui ID: ${currItem.questionId}`} imageId="icon_mark-id" />
                            <GridIcon visible={currItem.hideFromList} className="hide-from-list-mark" title="Ocultar na listagem do FAQ" imageId="icon_mark-hide-from-list" />
                            <GridIcon visible={hasTags} className="tags-mark" title={`Assuntos/Tags: ${tagList}`} imageId="icon_mark-tags" />
                            <GridIcon visible={hasImages} className="image-mark" title="Item possui imagens associadas." imageId="icon_mark-image" />
                            <GridIcon visible={onItemPreview} className="item-preview" title="Visualizar" imageId="icon_item-preview" onClick={() => onItemPreview(currItem)} />
                            <GridIcon visible={onItemEdit} className="item-edit" title="Editar" imageId="icon_item-edit" onClick={() => onItemEdit(currItem, isGridChanged)} />
                            <GridIcon visible={onItemDelete} className="item-delete" title="Deletar" imageId="icon_item-delete" onClick={() => onItemDelete(currItem, isGridChanged)} />
                        </div>

                        { indx === (content.items.length-1) &&
                        <div className="inset-after" onClick={() => onItemInsert(indx+1, isGridChanged)}>+</div>
                        }
                    </div>
                );
            })}

            { erroProcessamento &&
            <AppExternalServiceInfoMessage id="msgErrorID" className="info-error">{erroProcessamento}</AppExternalServiceInfoMessage>
            }

            { onSave &&
            <div className="action-buttons">
                <button type="default" disabled={!isGridChanged} className="btn-save" onClick={() => onSave(_buildGridChangesToSave(content.items))}>Salvar Alterações</button>
            </div>
            }
        </div>
    );
}
export default FaqEditGrid;