/*
** @name: Meu Clínicas - servicesStatistics
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Fevereiro 2024
** @description: Módulo para renderizar seção de estatísticas de serviços no dashboard (Refatorado do módulo principal)
*/

import React from 'react';

import { Accordion } from 'semantic-ui-react';

import utils from '../../core/utils.js';
import { AccordionCollapsible } from '../../components/general/appNavigationControls/appNavigationControls.js';


const _buildAgendamentosMes = (dados) => {
    if(!utils.isArray(dados)) {
        return null;
    }

    let result = "";
    dados.forEach(item => {
        const paddedMonth = `0${item.month}`.substr(-2);
        const periodo = `${paddedMonth}/${item.year}`;
        result = `${result}${ result.length > 0 ? ' | ' : ''}${periodo}: ${item.countAgendamento}`;
    });

    return result.length > 0 ? `( ${result} )` : "";
}

const ServicesStatistics = (props) => {
    const { fetchingMessage, onToggle } = props;
    const services = props.data;
    if(!services) {
        return null;
    }

    const { consultas, exames, receitas, regmedicos } = services;
    return(
        <div className={`accordion-item${services.active ? " active" : ""} statistic-services`}>
            <Accordion.Title onClick={() => onToggle(services)}>
                <div className="title-info-wrapper">
                    <div className="information">Serviços</div>
                </div>
                <div className="collapsible-wrapper">
                    <AccordionCollapsible active={services.active} iconVariant={null} />
                </div>
            </Accordion.Title>
            <Accordion.Content>
                <div className="statistic-content-wrapper">

                    <div className="statistic-row">
                        <div className="sub-group">Exames</div>
                    </div>
                    { !exames.data ? fetchingMessage :
                    <>
                        <div className="statistic-row ident">
                            <span className="item-title">Total carregados:</span>
                            <span className="item-info">{exames.data.examesCarregados}</span>
                        </div>
                        <div className="statistic-row ident">
                            <div className="item-title">Total visualizados:</div>
                            <div className="item-info">{exames.data.examesVisualizados}</div>
                        </div>
                    </>
                    }

                    <div className="statistic-row">
                        <div className="sub-group">Consultas</div>
                    </div>
                    { !consultas.data ? fetchingMessage :
                    <>
                        <div className="statistic-row ident">
                            <span className="item-title">Total carregadas:</span>
                            <span className="item-info">{consultas.data.consultasCarregadas}</span>
                        </div>
                        <div className="statistic-row ident">
                            <span className="item-title">Consultas disponíveis para agendamento:</span>
                            <span className="item-info">{consultas.data.consultasDisponiveisAgendamento}</span>
                        </div>
                        <div className="statistic-row ident">
                            <span className="item-title">Agendamentos por mês:</span>
                            <span className="item-info">{_buildAgendamentosMes(consultas.data.agendamentosPorMes)}</span>
                        </div>
                        <div className="statistic-row ident">
                            <span className="item-title">Total consultas iniciou teleatendimento ("ReadyToStart"):</span>
                            <span className="item-info">{consultas.data.teleatendimentoReadyToStart}</span>
                        </div>
                    </>
                    }

                    <div className="statistic-row">
                        <div className="sub-group">Receitas</div>
                    </div>
                    { !receitas.data ? fetchingMessage :
                    <>
                        <div className="statistic-row ident">
                            <span className="item-title">Total carregadas:</span>
                            <span className="item-info">{receitas.data.receitasCarregadas}</span>
                        </div>
                    </>
                    }

                    <div className="statistic-row">
                        <div className="sub-group">Registros Médicos</div>
                    </div>
                    { !regmedicos.data ? fetchingMessage :
                    <>
                        <div className="statistic-row ident">
                            <span className="item-title">Total etiquetas de materiais ativas:</span>
                            <span className="item-info">{regmedicos.data.totalEtiquetasMateriaisAtivas}</span>
                        </div>
                        <div className="statistic-row ident">
                            <span className="item-title">Total etiquetas de materiais visualizadas:</span>
                            <span className="item-info">{regmedicos.data.totalEtiquetasMateriaisVisualizadas}</span>
                        </div>
                    </>
                    }

                </div>
            </Accordion.Content>
        </div>
    );
}

export default ServicesStatistics;