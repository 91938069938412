/*
** @name: Meu Clínicas - teleatendimento
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Setembro 2022
** @description: Módulo de teleatendimento
*/

import React, { Component } from 'react';

import utils from '../../core/utils.js';
import sessionStorageManager from '../../core/sessionStorageManager.js';
import { useAuthContext } from '../../core/authContext.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';
import { useAppThemeContext } from '../../core/appThemeContext.js';
import { getAppServiceConfigByName } from '../../core/appThemeConfig.js';
import { ThemeMessage, isServiceEnable } from '../../core/appThemeUtils.js';
import { APP_SERVICE_LIST } from '../../core/appServiceList.js';

import AppCardModuleBasicWrapper from '../../components/general/appCardModuleBasicWrapper/appCardModuleBasicWrapper.js';
import AppConfirmationDialog from '../../components/general/appConfirmationDialog/appConfirmationDialog.js';

import teleatendimentoClient, { MESSAGE_TYPE } from '../../apiClients/consultas/teleatendimentoClient.js';

import DeviceInfo from './deviceInfo.js';
import Instrucoes from './instrucoes.js';
import AnexarArquivos from './anexarArquivos.js';
import SalaEspera from './salaEspera.js';
import VideoChamada from './videoChamada.js';
import AnexosModal from './anexosModal.js';

import { addModuleTimeout, clearModuleTimeout, calculateTeleAdjustedTimes, 
        faqItemLoad, getMediaDevicesPermissions, newsLoad } from './tools.js';

// Import module styles
import './scss/teleatendimento.scss'; 


let componentUnmounting;
let moduleTimeouts;
let stompClient;

const NEWS_LOCATION = {
    INFORMACOES_SALA: "TELEATENDIMENTO_SALA_ESPERA"
}
const ID_FAQ_ITEMS = {
    INSTRUCOES: "TELEATENDIMENTO_INSTRUCOES",
    INSTRUCOES_UPLOAD: "TELEATENDIMENTO_UPLOAD"
}
const STEPS = {
    DEVICE_INFO: 'device_info',
    INSTRUCOES: 'instrucoes',
    ANEXAR_ARQUIVOS: 'anexar_arquivos',
    SALA_ESPERA: 'sala_espera',
    SALA_VIDEO_CHAMADA: 'sala_video_chamada'
}
const WS_AUTO_RECONECT_TIME_MS = 15000;
const USAGE_UPDATE_INTERVAL_MS = 60000;
const CONFERENCE_RECONECT_TOKEN_EXPIRATION_MARGIN_MS = 30000;
const CHECKIN_UNEXPECTED_ERROR = "Ocorreu um erro inesperado ao informar a chegada";
const ROOM_CONFIG_UNEXPECTED_ERROR = "Ocorreu um erro inesperado para obter informações da videochamada";
const KEEP_AWAKE_TAG = "Teleatendimento";

const Teleatendimento = (props) => {
    const authContext = useAuthContext();
    const appControllerContext = useAppControllerContext();
    const themeConfig = useAppThemeContext().getConfig();
    return(
        <TeleatendimentoImplem
            authContext={authContext}
            appControllerContext={appControllerContext}
            themeConfig={themeConfig}
            {...props}
        />
    )
}

class TeleatendimentoImplem extends Component {
    constructor(props) {
        super(props);

        componentUnmounting = false;
        moduleTimeouts = { };
        stompClient = null;
        this.state = this._buildInitialState();
    }

    _buildInitialState = (keepData) => {
        const { mediaDevicesReady, infoConsulta, instrucoesTeleatendimento, instrucoesUpload } = keepData || {};
        const { numeroConsulta } = sessionStorageManager.navigation.getCurrentCardModuleParameters() || {};
        return {
            currentStep: STEPS.DEVICE_INFO,
            numeroConsulta: numeroConsulta,
            isJitsiApiLoaded: utils.isClass(window.JitsiMeetExternalAPI),
            mediaDevicesReady: mediaDevicesReady ? mediaDevicesReady : false,
            mediaDevicesErrorMessage: null,
            infoConsulta: infoConsulta ? infoConsulta : null,
            infoConsultaErrorMessage: null,
            instrucoesTeleatendimento: instrucoesTeleatendimento ? instrucoesTeleatendimento : null,
            instrucoesUpload: instrucoesUpload ? instrucoesUpload: null,
            informacoesSalaEspera: null,
            tooEarlyToCheckin: false,
            checkedIn: false,
            checkinErrorMessage: null,
            processingCheckin: false,
            readyToStart: false,
            shouldReconnect: false,
            roomConfiguration: null,
            roomConfigurationErrorMessage: null,
            conferenceActive: false,
            closedConference: false,
            confirmationDialog: null,
            modalAnexos: {}
        };
    }

    _buildNextStep = () => {
        const { currentStep } = this.state;
        const isUploadEnabled = this._isUploadEnabled();
        if(currentStep===STEPS.INSTRUCOES && isUploadEnabled) {
            return STEPS.ANEXAR_ARQUIVOS;
        }
        return STEPS.SALA_ESPERA;
    }

    _carregarInformacoesConsulta = () => {
        this._setLoading(true);
        const serviceConfig = getAppServiceConfigByName(this.props.themeConfig, APP_SERVICE_LIST.TELEATENDIMENTO) || {};
        const pacCodigo = this.props.authContext.properties.user.pacCodigo;
        const numeroConsulta = this.state.numeroConsulta;
        teleatendimentoClient.infoConsulta(
            pacCodigo, numeroConsulta,
            res => {
                this._setLoading(false);
                const info = res.data;
                const allowToCheckin = info.checkinAllowed || serviceConfig.allowCheckinAnyTime;
                let errorMessage = null;
                if(info && allowToCheckin) {
                    const { msToBegin, msToCheckin } = info;
                    info.msToBegin = msToBegin ? msToBegin : Number.MAX_SAFE_INTEGER;
                    info.msToCheckin = msToCheckin ? msToCheckin : Number.MAX_SAFE_INTEGER;
                    info.adjustedTimes = calculateTeleAdjustedTimes(this.props.themeConfig, info);
                } else {
                    errorMessage = info.errorMessage ? info.errorMessage : "No momento há uma condição na consulta que não permite essa modalidade";
                }
                this._updateCheckinState(info.checkedIn, info.readyToStart);
                this.setState({
                    infoConsulta: (info && (allowToCheckin || info.readyToStart)) ? info : null,
                    infoConsultaErrorMessage: errorMessage
                });
            },
            err => {
                this._setLoading(false);
                this.setState({ 
                    infoConsulta: null,
                    infoConsultaErrorMessage: "Ocorreu um erro obtendo informações da consulta"
                });
            }
        );
    }

    _carregarInformacoesSalaEspera = () => {
        newsLoad(NEWS_LOCATION.INFORMACOES_SALA)
            .then(res => {
                this.setState({ informacoesSalaEspera: res });
            })
            .catch(err => {
                this.setState({ informacoesSalaEspera: null });
            });
    }

    _carregarInstructionsGerais = () => {
        if(this.state.instrucoesTeleatendimento) {
            return;
        }

        this._setLoading(true);
        faqItemLoad(ID_FAQ_ITEMS.INSTRUCOES)
            .then(res => {
                this._setLoading(false);
                this.setState({ 
                    currentStep: res ? STEPS.INSTRUCOES : this._buildNextStep(),
                    instrucoesTeleatendimento: res
                });
            })
            .catch(err => {
                this._setLoading(false);
                this.setState({ currentStep: this._buildNextStep() });
            });
    }

    _carregarInstructionsUpload = () => {
        if(this.state.instrucoesUpload) {
            return;
        }

        this._setLoading(true);
        faqItemLoad(ID_FAQ_ITEMS.INSTRUCOES_UPLOAD)
            .then(res => {
                this._setLoading(false);
                this.setState({ 
                    currentStep: res ? STEPS.ANEXAR_ARQUIVOS : this._buildNextStep(),
                    instrucoesUpload: res
                });
            })
            .catch(err => {
                this._setLoading(false);
                this.setState({ currentStep: STEPS.SALA_ESPERA });
            });
    }

    _consultarJitsiMeetConfiguration = () => {
        const { numeroConsulta, roomConfiguration } = this.state;
        if(roomConfiguration===null) {
            const { pacCodigo, nome } = this.props.authContext.properties.user;
            this._setLoading(true);
            this._setRoomConfiguration(null);
            teleatendimentoClient.obterJitsiToken(
                pacCodigo, numeroConsulta, (nome || "Paciente"),
                res => {
                    this._setLoading(false);
                    const info = res.data;
                    info.numeroConsulta = numeroConsulta;
                    if(info.room && info.jwtToken) {
                        this._setRoomConfiguration(info);
                    } else {
                        this.setState({
                            roomConfigurationErrorMessage: ROOM_CONFIG_UNEXPECTED_ERROR
                        });
                    }
                }, 
                err => {
                    this._setLoading(false);
                    this.setState({
                        roomConfigurationErrorMessage: ROOM_CONFIG_UNEXPECTED_ERROR
                    });
            }
            );
        }
    }

    _efetuarCheckin = () => {
        const { numeroConsulta, processingCheckin, tooEarlyToCheckin, checkinErrorMessage } = this.state;
        if(!tooEarlyToCheckin && !checkinErrorMessage && !processingCheckin) {
            const pacCodigo = this.props.authContext.properties.user.pacCodigo;
            this._setProcessingCheckin();
            this._setLoading(true);
            teleatendimentoClient.realizarCheckin(
                pacCodigo, numeroConsulta, 
                res => {
                    this._setLoading(false);
                    const result = res.data;
                    const errorMessage = result.confirmed ? null : (result.errorMessage || CHECKIN_UNEXPECTED_ERROR);
                    this._updateCheckinState(result.confirmed, result.readyToStart, errorMessage);
                }, 
                err => {
                    this._setLoading(false);
                    this._updateCheckinState(false, false, CHECKIN_UNEXPECTED_ERROR);
                }
            );
        }
    }

    _getDevicesPermissions = () => {
        const serviceConfig = getAppServiceConfigByName(this.props.themeConfig, APP_SERVICE_LIST.TELEATENDIMENTO) || {};
        if(serviceConfig.disableMediaDeviceCheckOnStart || this.state.mediaDevicesReady) {
            this.setState({
                mediaDevicesReady: true,
                mediaDevicesErrorMessage: null
            });
            return;
        }

        getMediaDevicesPermissions(serviceConfig.allowNonHttpsProtocol, () => this._setLoading(true), () => this._setLoading(false))
            .then(res => {
                const { mediaDevicesReady, mediaDevicesErrorMessage } = res;
                this.setState({
                    mediaDevicesReady: mediaDevicesReady,
                    mediaDevicesErrorMessage: mediaDevicesErrorMessage
                });
            })
    }

    _handleAvancar =() => this.setState({ currentStep: this._buildNextStep() });

    _handleBeforeCardClose = () => {
        const { conferenceActive } = this.state;
        if(conferenceActive) {
            this._handleConferenceHangup();
            return false;
        }
        return true;
    }

    _handleConferenceHangup = () => {
        const { conferenceActive } = this.state;
        if(conferenceActive) {
            const closeConfirmation = () => this.setState({ confirmationDialog: null });
            this.setState({
                confirmationDialog: {
                    onConfirm: () => { 
                        closeConfirmation();
                        this.setState({
                            conferenceActive: false,
                            closedConference: true
                        });
                    },
                    onCancel: closeConfirmation
                }
            });
        }
    }

    _handleConferenceReconnect = () => {
        const { expireAt } = this.state.roomConfiguration || {};
        if(expireAt && (expireAt-new Date().getTime()-CONFERENCE_RECONECT_TOKEN_EXPIRATION_MARGIN_MS>0)) {
            this.setState({ closedConference: false });
        } else {
            this._restart();
        }
    }

    _handleClose = () => this.props.appControllerContext.methods.doCardFadeOut();

    _handleOnLoad = () => this.setState({ isJitsiApiLoaded: utils.isClass(window.JitsiMeetExternalAPI) });

    _initiateConnectionTeleatendimentoWebSockect = () => {
        const pacCodigo = this.props.authContext.properties.user.pacCodigo;
        this.setState({ shouldReconnect: false });
        teleatendimentoClient.wsTopicConnect(pacCodigo, {
            onSubscriptionConfirmed: this._onWSSubscriptionConfirmed,
            onWebSockectClose: this._onWSClose,
            onMessageReceived: this._onWSMessage,
            onConnect: (frame) => {},
            onDisconnect: (frame) => {},
        });
    }

    _isUploadEnabled = () => {
        const { infoConsulta } = this.state;
        return infoConsulta && infoConsulta.uploadSettings && infoConsulta.uploadSettings.uploadEnabled;
    }

    _keepAwake = activate => {
        const rnIntegration = window.rnIntegration;
        if(rnIntegration) {
            if(activate) {
                rnIntegration.keepAwakeActivate(KEEP_AWAKE_TAG);
            } else {
                rnIntegration.keepAwakeDeactivate(KEEP_AWAKE_TAG);
            }
        }
    }

    _onWSSubscriptionConfirmed = (client, topic) => {
        stompClient = client;
        this.setState({ shouldReconnect: false });
        this._postReadyToStartCheckMessage(); // Check if ready to start due to reconect events
    }

    _onWSClose = (event) => {
        if(!componentUnmounting) {
            const closedByRequest = event && event.code===1000;
            stompClient = null;
            this.setState({
                shouldReconnect: !closedByRequest
            });
        }
    }

    _onWSMessage = (frame) => {
        const parseMessage = body => { try {  return JSON.parse(body); } catch(e) { return null; } }
        if(frame.command==='MESSAGE' && frame.body) {
            const { numeroConsulta } = this.state;
            const pacCodigo = this.props.authContext.properties.user.pacCodigo;
            const jsonBody = parseMessage(frame.body);
            switch(jsonBody.type) {
                case MESSAGE_TYPE.CHECKIN:
                    const confirmed = jsonBody.pacCodigo===pacCodigo && jsonBody.attributes && 
                            jsonBody.attributes.numeroConsulta===numeroConsulta && jsonBody.attributes.confirmed;
                    const errorMessage = confirmed ? null : 
                            ((jsonBody.attributes && jsonBody.attributes.errorMessage) || CHECKIN_UNEXPECTED_ERROR);
                    this._updateCheckinState(confirmed, confirmed && jsonBody.attributes.readyToStart, errorMessage);
                break;
                case MESSAGE_TYPE.READY_TO_START:
                    const ready = jsonBody.pacCodigo===pacCodigo && jsonBody.attributes && 
                            jsonBody.attributes.numeroConsulta===numeroConsulta && jsonBody.attributes.readyToStart;
                    this.setState({
                        readyToStart: ready ? true : false
                    });
                break;
                default:
                    console.warn("Unexpected message", frame);
            }
        }
    }

    _postReadyToStartCheckMessage = () => {
        const pacCodigo = this.props.authContext.properties.user.pacCodigo;
        const numeroConsulta = this.state.numeroConsulta;
        teleatendimentoClient.wsTopicPublish(stompClient, {
            type: MESSAGE_TYPE.READY_TO_START,
            pacCodigo,
            attributes: {
                numeroConsulta
            }
        });
    }

    _restart = () => {
        const { mediaDevicesReady }  = this.state;
        const keepData = { mediaDevicesReady };
        const beginState = this._buildInitialState(keepData);
        this.setState(beginState, this._getDevicesPermissions());
    }

    _sairSalaEspera = () => {
        const { checkedIn, numeroConsulta } = this.state;
        if(checkedIn) { // dispara chamada para indicar saida da sala de espera (ignora resultado)
            const pacCodigo = this.props.authContext.properties.user.pacCodigo;
            teleatendimentoClient.sairSalaEspera(pacCodigo, numeroConsulta, () => { }, () => { });
        }
        this._wsDiconnect();
        if(!componentUnmounting) {
            const { mediaDevicesReady, infoConsulta, instrucoesTeleatendimento, instrucoesUpload } = this.state;
            const keepData = {
                mediaDevicesReady,
                infoConsulta,
                instrucoesTeleatendimento,
                instrucoesUpload
            };
            const newState = this._buildInitialState(keepData);
            this.setState(newState);
        }
    }

    _setConferenceActive = (active) => {
        const { conferenceStatedAt } = this.state;
        this.setState({ 
            conferenceActive: active,
            conferenceStatedAt: conferenceStatedAt ? conferenceStatedAt : new Date().getTime()
        });
    }

    _setLoading = visible => utils.setLoadingVisibility(this.props.appControllerContext, visible);

    _setProcessingCheckin = () => this.setState({ processingCheckin: true });     

    _setRoomConfiguration = config => this.setState({ roomConfiguration: config });

    _setVisibilityAnexosModal = (visible, onDismiss) => {
        const currModalState = this.state.modalAnexos || {};
        const newVisibility = visible && this._isUploadEnabled();
        const runAfter = (currModalState.visible && !newVisibility && utils.isFunction(currModalState.onDismiss)) ? currModalState.onDismiss : () => {};
        this.setState({
            modalAnexos: {
                visible: newVisibility,
                onDismiss
            }
        }, runAfter());
    }

    _triggerScheduledUsageUpdate = () => {
        this.props.appControllerContext.methods.doUsageUpdate();
        addModuleTimeout(moduleTimeouts, "usageUpdate", () => this._triggerScheduledUsageUpdate(), USAGE_UPDATE_INTERVAL_MS);
    }

    _updateCheckinState = (confirmed, ready, errorMessage) => {
        this.setState({
            processingCheckin: false,
            checkedIn: confirmed ? true : false,
            checkinErrorMessage: errorMessage,
            readyToStart: ready ? true : false
        });
    }

    _verificaHorarioCheckin = () => {
        const { infoConsulta } = this.state;
        const { checkinStartAt } = infoConsulta.adjustedTimes || {};
        const now = new Date().getTime();
        const readyToCheckin = now >= checkinStartAt;
        if(!readyToCheckin) {
            this.setState({ tooEarlyToCheckin: true });
            const remainingTime = checkinStartAt - now;
            addModuleTimeout(moduleTimeouts, "checkinTimeout", () => this._verificaHorarioCheckin(), remainingTime);
        } else {
            this.setState({ tooEarlyToCheckin: false });
            this._initiateConnectionTeleatendimentoWebSockect();
        }
    }

    _wsDiconnect = () => {
        if(stompClient) {
            teleatendimentoClient.wsDisconnect(stompClient);
        }
    }

    componentDidMount() {
        if(!isServiceEnable(this.props.themeConfig, APP_SERVICE_LIST.TELEATENDIMENTO)) {
            console.error("Module disabled");
            this._handleClose();
        }

        if(!this.state.numeroConsulta) {
            console.error("Consulta não informada");
            this._handleClose();
        } else {
            this._getDevicesPermissions();
        }
        window.addEventListener('load', this._handleOnLoad, true);
        this.props.appControllerContext.methods.doSetEventOnBeforeCloseModule(() => this._handleBeforeCardClose());
        this._triggerScheduledUsageUpdate();
        this._keepAwake(true);
    }

    componentDidUpdate(prevProps, prevState) {
        const { currentStep, mediaDevicesReady, infoConsulta, instrucoesTeleatendimento, instrucoesUpload,
                shouldReconnect, tooEarlyToCheckin, checkedIn, processingCheckin, readyToStart } = this.state;
        if(currentStep!==STEPS.SALA_VIDEO_CHAMADA && mediaDevicesReady && infoConsulta && readyToStart) {
            this.setState({ 
                currentStep: STEPS.SALA_VIDEO_CHAMADA,
                conferenceActive: false,
                closedConference: false
            }, () => {
                this._wsDiconnect();
                this._consultarJitsiMeetConfiguration();
            });
            return;
        }

        if(currentStep===STEPS.DEVICE_INFO) {
            if(mediaDevicesReady) {
                this.setState({ currentStep: STEPS.INSTRUCOES }, () => this._carregarInformacoesConsulta());
                return;
            }
        }

        if(currentStep===STEPS.INSTRUCOES) {
            if(prevState.infoConsulta!==infoConsulta && infoConsulta && !instrucoesTeleatendimento && !readyToStart) {
                this._carregarInstructionsGerais();
            }
        }

        if(currentStep===STEPS.ANEXAR_ARQUIVOS) {
            if(prevState.currentStep!==currentStep && !instrucoesUpload && !readyToStart) {
                this._carregarInstructionsUpload();
            }
        }

        if(currentStep===STEPS.SALA_ESPERA) {
            if(infoConsulta) {
                if(prevState.currentStep!==currentStep) {
                    this._carregarInformacoesSalaEspera();
                    this._verificaHorarioCheckin();
                } else {
                    if(!tooEarlyToCheckin && !checkedIn && !processingCheckin) {
                        this._efetuarCheckin();
                    }
                }
                if(!stompClient) {
                    if(shouldReconnect && !prevState.shouldReconnect) {
                        addModuleTimeout(moduleTimeouts, "wsReconnect", () => this._initiateConnectionTeleatendimentoWebSockect(), WS_AUTO_RECONECT_TIME_MS);
                    }
                }
            } else {
                this._restart();
            }
        }
    }

    componentWillUnmount() {
        componentUnmounting = true;
        clearModuleTimeout(moduleTimeouts);
        this._sairSalaEspera();
        window.removeEventListener("load", this._handleOnLoad, true);
        this._keepAwake(false);
    }

    render() {
        const { currentStep, mediaDevicesErrorMessage, infoConsulta, instrucoesTeleatendimento, instrucoesUpload,
            infoConsultaErrorMessage, informacoesSalaEspera, confirmationDialog, closedConference,
            tooEarlyToCheckin, checkinErrorMessage, checkedIn, roomConfiguration, roomConfigurationErrorMessage,
            isJitsiApiLoaded, conferenceStatedAt, modalAnexos } = this.state;
        const serviceConfig = getAppServiceConfigByName(this.props.themeConfig, APP_SERVICE_LIST.TELEATENDIMENTO) || {};

        return(
            <AppCardModuleBasicWrapper moduleName="teleatendimento">

                { confirmationDialog &&
                <AppConfirmationDialog
                    title={"ATENÇÃO"}
                    message={<ThemeMessage messageId="teleatendimento_confirmar-hangup" />}
                    onConfirm={confirmationDialog.onConfirm}
                    onCancel={confirmationDialog.onCancel} 
                    hideCancelButton={false} />
                }

                { (modalAnexos && modalAnexos.visible) && 
                <AnexosModal
                    consulta={infoConsulta.consulta}
                    settings={infoConsulta.uploadSettings}
                    hideMessage={serviceConfig.hideMessageOnUploadModal}
                    onClose={() => this._setVisibilityAnexosModal(false)}
                    onSetLoading={this._setLoading}
                />
                }

                { currentStep===STEPS.DEVICE_INFO &&
                <DeviceInfo
                    errorMessage={mediaDevicesErrorMessage}
                    onClose={this._handleClose}
                />
                }

                { currentStep===STEPS.INSTRUCOES &&
                <Instrucoes
                    infoConsulta={infoConsulta}
                    instrucoes={instrucoesTeleatendimento}
                    errorMessage={infoConsultaErrorMessage}
                    onClose={this._handleClose}
                    onAvancar={this._handleAvancar}
                />
                }

                { currentStep===STEPS.ANEXAR_ARQUIVOS &&
                <AnexarArquivos
                    instrucoes={instrucoesUpload}
                    onAvancar={this._handleAvancar}
                    onAnexar={this._isUploadEnabled() ? () => this._setVisibilityAnexosModal(true) : null}
                />
                }

                { currentStep===STEPS.SALA_ESPERA &&
                <SalaEspera
                    connectionInticator={serviceConfig.disableConnectionIndicator===true ? null : (stompClient ? true : false)}
                    nomePaciente={this.props.authContext.properties.user.nome}
                    tooEarly={tooEarlyToCheckin}
                    checkedIn={checkedIn}
                    checkinError={checkinErrorMessage}
                    informacoes={informacoesSalaEspera}
                    onSair={this._sairSalaEspera}
                    onAnexar={this._isUploadEnabled() ? () => this._setVisibilityAnexosModal(true) : null}
                />
                }

                { currentStep===STEPS.SALA_VIDEO_CHAMADA &&
                <>
                { (roomConfiguration || roomConfigurationErrorMessage) &&
                    <VideoChamada 
                        startedAt={conferenceStatedAt}
                        closedConference={closedConference}
                        configuration={roomConfiguration}
                        configurationErrorMessage={roomConfigurationErrorMessage}
                        jitsiApiLoaded={isJitsiApiLoaded}
                        onReconnect={this._handleConferenceReconnect}
                        onConferenteActiveChange={active => this._setConferenceActive(active)}
                        onBeforeHangup={this._handleConferenceHangup}
                        onSair={this._handleClose}
                        onAnexar={this._isUploadEnabled() ? (onDismiss) => this._setVisibilityAnexosModal(true, onDismiss) : null}
                    />
                }
                </>
                }

            </AppCardModuleBasicWrapper>
        );
    }
}

export default Teleatendimento;
