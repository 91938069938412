/*
** @name: Meu Clínicas - descriptionModal
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Dezembro 2022
** @description: Componente para renderizar modal de descricao do arquivo na lista e arquivos de upload
*/
import React, { useState, useEffect } from "react";

import { ThemeImage } from "../../../core/appThemeUtils";

import AppModal from '../../../components/general/appModal/appModal.js';
import { InputField } from '../../../components/fields/formsBuilderCustoms/';

import { formatFileSize } from './tools.js';


const DescriptionModal = (props) => {
    const { minFileDescriptionSize, fileData, onCancel, onConfirm } = props;
    const { name, description, size } = fileData || {};
    const [ fileDescription, setFileDescription ] = useState(description);
    const isDescriptionOk = (fileDescription || "").length >= minFileDescriptionSize; 

    const _handleChangeDescription = (e, { name, value }) => {
        if(!value || /^[0-9a-zà-ýA-ZÀ-Ý_.-]+\s?([0-9a-zà-ýA-ZÀ-Ý_.-]\s?)*$/.test(value)) {
            setFileDescription(value);
        }
    }

    useEffect(() => setFileDescription(description), [description]);

    return(
        <div id="fileConfirmationModalId">
            <AppModal
                closeOnEsc={true}
                closeOnClickOutside={false}
                onClose={() => onCancel()}
                headerContent={
                    <div className="modal-header-wrapper">
                        Confirmar arquivo
                        <div className="close-button" onClick={() => onCancel()}>
                            <ThemeImage module="appCard" imageId="normal-icon_close" />
                        </div>
                    </div>
                }
            >

                <form action="#" name="frmAddFile" className="ui form file-confirmation-form" onSubmit={e => e.preventDefault()}>
                    <div className="file-information"><label>Nome:</label>{name}</div>
                    <div className="file-information"><label>Tamanho:</label>{formatFileSize(size)}</div>
                    <div className="file-add-wrapper float-label-field">
                        <div className="file-description">
                            <InputField
                                id="fileDescriptionId"
                                name="fileDescription"
                                placeHolder="Descrição"
                                floatingPlaceHolder={true}
                                maxSize={50}
                                autoComplete="off"
                                value={fileDescription || ""}
                                onChange={_handleChangeDescription} />
                        </div>
                        <div className={`description-ok ${isDescriptionOk ? "enabled" : "disabled"}`}>
                            <button 
                                id="button-confirmation-ok"
                                className="app-form-button confirmation-ok"
                                disabled={!isDescriptionOk}
                                onClick={() => onConfirm(fileDescription)}>OK</button>
                        </div>
                    </div>
                </form>

            </AppModal>
        </div>
    )
}

export default DescriptionModal;
