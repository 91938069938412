import requestAuth from "../../core/requestAuth";
import requestNoAuth from "../../core/requestNoAuth";
import moment from "moment";

import { apiUrl } from "../../core/apiUrl.js";


const API_PORT = 8086;
const API_SERVICE = 'formularios-service';
const API_VERSION = 'v2';

class FormulariosClient {

    actuatorInfo(successCallback, errorCallback) {
        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, null, '/actuator/info'),
            successCallback,
            errorCallback
        );
    }

    buildInfo(successCallback, errorCallback) {
        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, null, '/build-info'),
            successCallback,
            errorCallback
        );
    }

    statistics(serviceToken, fingerprint, successCallback, errorCallback) {
        const queryParams = {
            jwtServiceToken: serviceToken,
            fingerprint: fingerprint
        }
        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/statistics'),
            successCallback,
            errorCallback,
            queryParams
        );
    } 

    getPermissoes(pacCodigo, successCallback, errorCallback) {
        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, `/permissoes/${pacCodigo}`),
            successCallback,
            errorCallback
        );
    }

    contadorSolicitacoesEspecialidade(pacCodigo, successCallback, errorCallback) {
        requestAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, `/obter-contador-solicitacoes-agendamento/${pacCodigo}`),
            successCallback,
            errorCallback
        );
    }

    exportarPesquisaExperiencia(jwtServiceToken, fingerprint, tipoRegistro, tipoExportacao, versaoFormulario, filtro, successCallback, errorCallback) {
        var queryParams = {
            jwtServiceToken: jwtServiceToken, 
            fingerprint: fingerprint,
            tipoRegistro,
            tipoExportacao,
            versaoFormulario
        };

        if (filtro.dataInicial) {            
            queryParams.dataInicial = moment(filtro.dataInicial, 'DD/MM/YYYY').toISOString(true);
        }
        
        if (filtro.dataFinal) {
            queryParams.dataFinal = moment(filtro.dataFinal, 'DD/MM/YYYY').toISOString(true);
        }   

        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/exportar-pesquisa-experiencia'),
            successCallback,
            errorCallback,
            queryParams
        );
    }

    exportarSolicitacaoConsulta(jwtServiceToken, fingerprint, tipoExportacao, filtro, successCallback, errorCallback) {
        var queryParams = {
            jwtServiceToken: jwtServiceToken, 
            fingerprint: fingerprint,
            tipoExportacao: tipoExportacao
        };

        if (filtro.dataInicial) {            
            queryParams.dataInicial = moment(filtro.dataInicial, 'DD/MM/YYYY').toISOString(true);
        }
        
        if (filtro.dataFinal) {
            queryParams.dataFinal = moment(filtro.dataFinal, 'DD/MM/YYYY').toISOString(true);
        }   

        requestNoAuth.get(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/exportar-solicitacao-consulta'),
            successCallback,
            errorCallback,
            queryParams
        );
    }

    notificarSolicitacaoConsulta(jwtServiceToken, fingerprint, fields, successCallback, errorCallback) {
        const request = {
            jwtServiceToken: jwtServiceToken,
            fingerprint: fingerprint
        }
        const dateFields = ["dataInicial", "dataFinal"];
        Object.keys(fields).forEach((key) => {
            let value = fields[key];
            if(value && dateFields.includes(key)) {
                value = moment(value, 'DD/MM/YYYY').toISOString(true);
            }
            request[key] = value ? value : null;
        });
        
        requestNoAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/public/notificar-solicitacao-consulta'),
            request,
            successCallback,
            errorCallback
        );
    }

    pesquisaExperiencia(request, successCallback, errorCallback) {
        requestAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/pesquisa-experiencia'),
            request,
            successCallback,
            errorCallback
        );
    }

    requerimentoDocumentosProntuario(request, successCallback, errorCallback) {
        requestAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/requerimento-documentos-prontuario'),
            request,
            successCallback,
            errorCallback
        );
    }

    solicitacaoReagendConsultaCanceladaCovid(request, successCallback, errorCallback) {
        requestAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/solicitacao-reagendamento-consulta-cancelada'),
            request,
            successCallback,
            errorCallback
        );
    }

    solicitacaoAgendamentoConsulta(request, successCallback, errorCallback) {
        requestAuth.post(
            apiUrl(API_PORT, API_SERVICE, API_VERSION, '/solicitacao-agendamento-consulta'),
            request,
            successCallback,
            errorCallback
        );
    }

}

const formulariosClient = new FormulariosClient();
export default formulariosClient;
export {
    API_PORT,
    API_SERVICE,
    API_VERSION
};