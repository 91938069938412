import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './modules/app/app.js';

import utils from './core/utils.js';
import { getApplicationConfiguration } from './core/appConfiguration.js';
import { getAppGeneralConfigPropertyByName } from './core/appThemeConfig.js';


/* --------------------------------------------------------------------------
 * Corrigindo o problema do 100vh em celulares: 
 * (https://css-tricks.com/the-trick-to-viewport-units-on-mobile/)
 */
// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;

// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

function handleResize() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

window.addEventListener('resize', () => handleResize());
window.addEventListener('orientationchange', () => handleResize());
window.addEventListener('load', () => { setTimeout(handleResize, 100) });


/* --------------------------------------------------------------------------
 * Rotinas para integração React Native - WebViewer
 * --------------------------------------------------------------------------
 */
const enableRnIntegration = getAppGeneralConfigPropertyByName(getApplicationConfiguration(), "enableReactNativeIntegration");

var rnIntegration = !enableRnIntegration ? null : {
    appVersionInfo: null,
    credentials: false,
    devicePermissions: null,
    videoConferenceEnabled: false,

    backAction: {
        backActionList: [],

        clear: function() {
            this.backActionList = [];
        },
    
        execute: function() { // Called by App to excute a 'Back Action'
            const action = this.pop();
            if(action) {
                action();
            } else {
                window.rnIntegration.triggerAppExit();
            }
        },

        keepFirst: function() {
            this.backActionList.splice(1, this.backActionList.length);
        },
    
        pop: function() {
            if(this.backActionList.constructor===Array) {
                const action = this.backActionList.pop();
                if(action) {
                    return action;
                }
            }
            return null;
        },
    
        push: function(fn) {
            this.backActionList.push(fn);
        },
    
        set: function(fn) {
            this.clear();
            this.push(fn);
        }
    },

    isAppRunning: function() {
        return (window.ReactNativeWebView ? true : false);
    },

    getCredentials() {
        return this.credentials;
    },

    setCredentials(token, fingerprint, pacCodigo) {
        if(!token || !fingerprint || !pacCodigo) {
            this.credentials = null;
        } else {
            const credentials = { };
            credentials.token = token;
            credentials.fingerprint = fingerprint;
            credentials.pacCodigo = pacCodigo;
            this.credentials = credentials;    
        }
    },

    getDevicePermissions() {
        return this.devicePermissions;
    },

    setDevicePermissions(permissions) {
        this.devicePermissions = permissions;
    },

    getAppVersionInfo() {
        return this.appVersionInfo;
    },

    setAppVersionInfo(versionInfo) {
        this.appVersionInfo = JSON.parse(versionInfo);
    },

    getVideoConferenceEnabled() {
        return this.videoConferenceEnabled;
    },

    setVideoConferenceEnabled(enabled) {
        this.videoConferenceEnabled = enabled;
    },

    // Messages to Native App
    keepAwakeActivate: function(tag) {
        if(this.isAppRunning()) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ 
                type: "KEEP_AWAKE_ACTIVATE",
                tag
            }));
        }
    },

    keepAwakeDeactivate: function(tag) {
        if(this.isAppRunning()) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ 
                type: "KEEP_AWAKE_DEACTIVATE",
                tag
            }));
        }
    },

    removeAppLogin: function() {
        if(this.isAppRunning()) {
            this.setCredentials(null, null, null);
            window.ReactNativeWebView.postMessage(JSON.stringify({ 
                type: "APP_LOGOUT"
            }));
        }
    },

    saveAppLogin: function(token, fingerprint, pacCodigo) {
        if(this.isAppRunning()) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ 
                type: "APP_LOGIN",
                token: token,
                fingerprint: fingerprint,
                pacCodigo: pacCodigo
            }));
        }
    },

    switchAppEnvironment: function(newEnv) {
        if(this.isAppRunning()) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ 
                type: "ENV_SWITCH"
            }));
        }
    },

    triggerAppConnectionError: function(data) {
        if(this.isAppRunning()) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ 
                type: "CONN_ERROR"
            }));
        }
    },

    triggerAppExit: function() {
        if(this.isAppRunning()) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ 
                type: "APP_EXIT"
            }));
        }
    },

    triggerAppOpenURL: function(url) {
        if(this.isAppRunning()) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ 
                type: "URL_OPEN",
                url: url 
            }));
            return true;
        }
        return false;
    },

    triggerAppPDFViewer: function(base64) {
        if(this.isAppRunning()) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ 
                type: "PDF_VIEWER",
                pdfData: base64
            }));
            return true;
        }
        return false;
    },

    triggerAppCalendarAdd: function(calData, icsData, fileName) {
        if(this.isAppRunning()) {
            if(icsData || utils.isArray(calData)) {
                window.ReactNativeWebView.postMessage(JSON.stringify({ 
                    type: "CALENDAR_ADD",
                    icsBase64: icsData ? utils.base64Encode(icsData) : null,
                    calDataBase64: calData ? utils.base64Encode(JSON.stringify(calData)) : null,
                    name: fileName
                }));
            }
            return true;
        }
        return false;
    },

    triggerAppUpdateRequired: function() {
        if(this.isAppRunning()) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ 
                type: "UPDATE_REQUIRED"
            }));
        }
    }

}

window.rnIntegration = rnIntegration;


/* --------------------------------------------------------------------------
 * Render Application Component
 * --------------------------------------------------------------------------
 */
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
