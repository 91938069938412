/*
** @name: Meu Clínicas - appMessageBox
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2021
** @description: Componente para render das caixas de mensagem da aplicação (erro, informação)
*/

import React from 'react';

import utils from '../../../core/utils.js';


const AppMessageBox = (props) => {
    const { id, className, messageData } = props;
    if(utils.isObject(messageData) && (messageData.header || messageData.message)) {
        return(
            <div id={id} className={`app-message-area ${className}`}>
                { messageData.header &&
                <div className="caption">
                    { messageData.header }
                </div>
                }
                { messageData.message &&
                <div className="message">
                    { messageData.message }
                </div>
                }
            </div>
        );
    }
    return null;
}

export default AppMessageBox;