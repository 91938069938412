/*
** @name: Meu Clínicas - invalidPage
** @author: 
** @date:
** @description: Módulo render para página inválida
*/

import React from 'react';

import utils from '../../core/utils.js';
import { ThemeImage } from '../../core/appThemeUtils.js';

import AppExtraDocumentHead from '../../components/general/appExtraDocumentHead/appExtraDocumentHead.js';


// Import module styles
import './invalidPage.scss';


const InvalidPage = () => {
    return (
        <div className="page-not-found-wrapper">
            <AppExtraDocumentHead subTitle="Página não encontrada" robots="noindex,nofollow" />

            <div className="page-content">
                <div className="image-wrapper">
                    <ThemeImage 
                        module="invalidPage"
                        imageId="imagem_fundo"
                        className="img-pagina-invalida" />
                </div>
                <div className="link-wrapper">
                    <a href={utils.getPublicPath()} id="link-pagina-inicial">Ir para a página inicial</a>
                </div>
            </div>
        </div>
    );
}

export default InvalidPage;